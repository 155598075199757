import { DocumentNode } from "graphql";

export class GraphQL {
    public static getSamples: DocumentNode = require("@graphql/documents/query/getSamples.graphql");
    public static getSampleGalleryInfos: DocumentNode = require("@graphql/documents/query/getSampleGalleryInfos.graphql");
    public static getSamplingList: DocumentNode = require("@graphql/documents/query/getSamplingList.graphql");
    public static getAppliances: DocumentNode = require("@graphql/documents/query/getAppliances.graphql");
    public static getTransports: DocumentNode = require("@graphql/documents/query/getTransports.graphql");
    public static getTransportsNumbers: DocumentNode = require("@graphql/documents/query/getTransportNumbers.graphql");
    public static getShipmentNumbers: DocumentNode = require("@graphql/documents/query/getShipmentNumbers.graphql");
    public static getShipments: DocumentNode = require("@graphql/documents/query/getShipments.graphql");
    public static getOperators: DocumentNode = require("@graphql/documents/query/getOperators.graphql");
    public static getAppliance: DocumentNode = require("@graphql/documents/query/getAppliance.graphql");
    public static getApplianceList: DocumentNode = require("@graphql/documents/query/getApplianceList.graphql");
    public static getApplianceLists: DocumentNode = require("@graphql/documents/query/getApplianceLists.graphql");
    public static getApplianceCategories: DocumentNode = require("@graphql/documents/query/getApplianceCategories.graphql");
    public static getPeriodAppliances: DocumentNode = require("@graphql/documents/query/getPeriodAppliances.graphql");
    public static getPercentageEnteredManually: DocumentNode = require("@graphql/documents/query/getPercentageEnteredManually.graphql");
    public static getPercentageApproved: DocumentNode = require("@graphql/documents/query/getPercentageApproved.graphql");
    public static getBrands: DocumentNode = require("@graphql/documents/query/getBrands.graphql");
    public static getProfile: DocumentNode = require("@graphql/documents/query/getProfile.graphql");

    public static deleteSamplings: DocumentNode = require("@graphql/documents/mutation/deleteSamplings.graphql");
    public static updateSamplings: DocumentNode = require("@graphql/documents/mutation/updateSamplings.graphql");
    public static updateOperator: DocumentNode = require("@graphql/documents/mutation/updateOperator.graphql");
    public static createOperator: DocumentNode = require("@graphql/documents/mutation/createOperator.graphql");
    public static deleteOperator: DocumentNode = require("@graphql/documents/mutation/deleteOperator.graphql");
    public static updateShipmentStatus: DocumentNode = require("@graphql/documents/mutation/updateShipmentStatus.graphql");
    public static updateAppliance: DocumentNode = require("@graphql/documents/mutation/updateAppliance.graphql");
    public static createAppliance: DocumentNode = require("@graphql/documents/mutation/createAppliance.graphql");
    public static createApplianceList: DocumentNode = require("@graphql/documents/mutation/createApplianceList.graphql");
    public static updateApplianceList: DocumentNode = require("@graphql/documents/mutation/updateApplianceList.graphql");
    public static duplicateApplianceList: DocumentNode = require("@graphql/documents/mutation/duplicateApplianceList.graphql");
    public static deleteApplianceList: DocumentNode = require("@graphql/documents/mutation/deleteApplianceList.graphql");

    public static createApplianceCategory: DocumentNode = require("@graphql/documents/mutation/createApplianceCategory.graphql");
    public static updateApplianceCategory: DocumentNode = require("@graphql/documents/mutation/updateApplianceCategory.graphql");
    public static deleteApplianceCategory: DocumentNode = require("@graphql/documents/mutation/deleteApplianceCategory.graphql");

    public static createPeriodAppliance: DocumentNode = require("@graphql/documents/mutation/createPeriodAppliance.graphql");
    public static updatePeriodAppliance: DocumentNode = require("@graphql/documents/mutation/updatePeriodAppliance.graphql");

    public static getIrConfigurations: DocumentNode = require("@graphql/documents/query/getIrConfigurations.graphql");
    public static createIrConfiguration: DocumentNode = require("@graphql/documents/mutation/createIrConfiguration.graphql");
    public static updateIrConfiguration: DocumentNode = require("@graphql/documents/mutation/updateIrConfiguration.graphql");
    public static deleteIrConfiguration: DocumentNode = require("@graphql/documents/mutation/deleteIrConfiguration.graphql");
}
