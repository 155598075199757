import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

import { GetBrandsQuery, GetBrandsQueryVariables } from "@models/graphql";
import { GraphQL } from "@graphql/graphQL";

export const useGetBrands = (opts?: QueryHookOptions<GetBrandsQueryVariables>) => {
    const { data, loading, error } = useQuery<GetBrandsQuery, GetBrandsQueryVariables>(GraphQL.getBrands, opts);

    return {
        brands: data && data.brands || [],
        brandsLoading: loading,
        brandsError: error
    };
};
