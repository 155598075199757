import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";
import styled, { StyledComponent } from "styled-components";

export const SelectedDevicesStyle = styled.div`
    background-color: #ECF6FD;
    border: #ADD8F7 1px solid;
    padding: 12px 16px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 120px;
    margin-bottom: 20px;
    border-radius: 4px;

    span:first-child {
        min-width: 210px;
        margin-right: 10px;
    }
`;

export const RightAligned = styled.div`
    display: flex;
    align-items: center;
`;

export const ButtonDelete: StyledComponent<any, {}, ButtonProps> = styled(Button)`
    background-color: #f5222d !important;
    border-color: #f5222d !important;
    color: white !important;
    margin-left: 20px;
`;

export const ChangeDevice = styled.div`
    margin-right: 20px;
    display: flex;
    gap: 10px;
`;
