import * as React from "react";
import { FunctionComponent } from "react";
import { SamplingsGridContainer } from "@components/samplings/samplingsGrid/samplingsGridContainer";
import { ViewMode } from "@pages/samplings/samplings";
import { useSamplingsTableFilter } from "../samplingsTable/useSamplingsTableFilter";
import { SamplingsTableContainer } from "../samplingsTable/samplingsTableContainer";
export interface SamplingsDataWrapperProps {
    viewMode: ViewMode;
}

export const SamplingsDataWrapper: FunctionComponent<SamplingsDataWrapperProps> = (props: SamplingsDataWrapperProps) => {
    const filter = useSamplingsTableFilter();
    const { viewMode } = props;

    return (
        <div>
            {
                viewMode === ViewMode.List
                    ? <SamplingsTableContainer filter={filter} />
                    : <SamplingsGridContainer filter={filter} />
            }
        </div>
    );

};
