import { ParsedURLSearchParams } from "./parseURLSearchParams";

export type NonNullableURLSearchParams<SearchParams extends {}> = {
	set: <Name extends keyof SearchParams>(name: Name, value: SearchParams[Name]) => void;
	get: <Name extends keyof SearchParams>(name: Name) => SearchParams[Name];
	delete: <Name extends keyof SearchParams>(name: Name) => void;
}

export const nonNullableURLSearchParams = <SearchParams extends {}>(
	searchParams: Pick<ParsedURLSearchParams<SearchParams>, "get" | "set" | "delete">,
	defaultSearchParams: SearchParams,
): NonNullableURLSearchParams<SearchParams> => {
	const get: NonNullableURLSearchParams<SearchParams>["get"] = (name) => (
		searchParams.get(name) || defaultSearchParams[name]
	);

	return {
		get,
		set: searchParams.set,
		delete: searchParams.delete,
	}
};
