import { useGetApplianceCategories } from "@graphql/hocs/hooks/useGetApplianceCategories";
import { GetApplianceCategoriesQuery } from "@models/graphql";
import { Select, Spin } from "antd";
import Maybe from "graphql/tsutils/Maybe";
import * as React from "react";
import { FunctionComponent } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "redux";
import { useDebounce } from "use-debounce";

export interface ApplianceCategorySelectProps {
    value?: Maybe<GetApplianceCategoriesQuery["applianceCategories"]["items"][0]>;
    applianceListId?: number;
    style?: React.CSSProperties;
    isControlled?: boolean;
    onChange?(val: GetApplianceCategoriesQuery["applianceCategories"]["items"][0]): void;
}

export const ApplianceCategorySelectComponent: FunctionComponent<ApplianceCategorySelectProps> = (props: ApplianceCategorySelectProps & InjectedIntlProps) => {
    const { value, onChange: onChangeCallback, applianceListId, style, isControlled, intl } = props;

    const [searchValue, setSearchValue] = React.useState("");
    const [selectValue, setSelectValue] = React.useState<number | undefined>(undefined);
    const [debounceSearchValue] = useDebounce(searchValue, 400);

    if (value && value.id !== selectValue) {
        setSelectValue(value.id);
    }

    if (isControlled && !value && selectValue) {
        setSelectValue(undefined);
    }

    const { applianceCategories, applianceCategoriesLoading } = useGetApplianceCategories({
        variables: {
            paging: {
                limit: 10,
                offset: 0
            },
            search: debounceSearchValue,
            filter: {
                applianceListId
            }
        }
    });

    const onChange = (val: number) => {
        const match = applianceCategories.find(app => app.id === val);

        if (match && onChangeCallback) {
            onChangeCallback(match);
        }

        setSelectValue(val);
    };

    return (
        <Select
            loading={applianceCategoriesLoading}
            showSearch
            filterOption={false}
            onSearch={setSearchValue}
            onChange={onChange}
            value={selectValue}
            placeholder={intl.formatMessage({ id: "applianceCategorySelect.placeholder" })}
            optionLabelProp="children"
            allowClear={false}
            style={style}
        >
            {applianceCategories && applianceCategories
                .filter(app => !value || app.id !== value.id)
                .map(app =>
                    <Select.Option key={app.id} value={app.id}>{app.name}</Select.Option>
                )}
            {value && <Select.Option value={value.id}>{value.name}</Select.Option>}
            {applianceCategoriesLoading && <Select.Option value=""><Spin /></Select.Option>}
        </Select>
    );
};

export const ApplianceCategorySelect = compose<React.FunctionComponent<ApplianceCategorySelectProps>>(
    injectIntl
)(ApplianceCategorySelectComponent);
