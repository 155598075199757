import { GraphQL } from "@graphql/graphQL";
import { CreateApplianceCategoryMutation, CreateApplianceCategoryMutationVariables } from "@models/graphql";
import { ApolloError } from "apollo-client";
import { useState } from "react";
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";

const defaultCreateApplianceCategoryOptions: MutationHookOptions<CreateApplianceCategoryMutation, CreateApplianceCategoryMutationVariables> = {

};

export const useCreateApplianceCategory = (options?: MutationHookOptions<CreateApplianceCategoryMutation, CreateApplianceCategoryMutationVariables>) => {
    const [hookState, setHookState] = useState<{
        isLoading: boolean;
        data?: CreateApplianceCategoryMutation;
        error?: ApolloError;
    }>({
        isLoading: false
    });

    const [mutation] = useMutation<CreateApplianceCategoryMutation, CreateApplianceCategoryMutationVariables>(GraphQL.createApplianceCategory);

    const createApplianceCategory = async (variables: CreateApplianceCategoryMutationVariables) => {
        setHookState({
            isLoading: true
        });

        try {
            const response = await mutation({
                ...defaultCreateApplianceCategoryOptions,
                ...options,
                variables
            });

            setHookState({
                data: response.data,
                isLoading: false
            });

            return {
                data: response.data,
                error: response.errors
            };
        } catch (error) {
            setHookState({
                error,
                isLoading: false
            });

            return {
                error
            };
        }
    };

    const clearErrors = () => {
        setHookState({
            ...hookState,
            error: undefined
        });
    };

    return {
        isLoading: hookState.isLoading,
        error: hookState.error,
        data: hookState.data,
        createApplianceCategory,
        clearErrors
    };
};
