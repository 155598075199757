import { GraphQL } from "@graphql/graphQL";
import { Mutation, Operator, UpdateOperatorMutationVariables } from "@models/graphql";
import { graphql, OperationOption } from "@apollo/react-hoc";

export interface UpdateOperatorMutationResult {
    // TODO: Add correct response return type
    updateOperator(variables: UpdateOperatorMutationVariables): Promise<Operator>;
}

export const createUpdateOperatorMutation = <TProps = {}>(options?: OperationOption<TProps, Mutation, UpdateOperatorMutationVariables, UpdateOperatorMutationResult>) =>
    graphql<TProps, Mutation, UpdateOperatorMutationVariables, UpdateOperatorMutationResult>(GraphQL.updateOperator, {
        props({ mutate }): UpdateOperatorMutationResult {
            if (!mutate) {
                throw new Error("No mutate found");
            }

            return {
                // TODO: Add correct response return type
                async updateOperator(variables: UpdateOperatorMutationVariables): Promise<Operator> {
                    try {
                        const result = await mutate({
                            variables
                        });

                        if (!result || !result.data) {
                            throw new Error("Result was empty");
                        }

                        // TODO: Add correct response
                        return result.data.updateOperator;
                    } catch (error) {
                        throw error;
                    }
                }
            };
        },
        ...options
    });
