import { GraphQL } from "@graphql/graphQL";
import { GetApplianceQuery, GetApplianceQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetAppliance = (options?: QueryHookOptions<GetApplianceQuery, GetApplianceQueryVariables>) => {
    const { data, error, loading } = useQuery<GetApplianceQuery, GetApplianceQueryVariables>(GraphQL.getAppliance, options);

    return {
        appliance: data && data.appliance ? data.appliance : null,
        applianceLoading: loading,
        applianceError: error
    };
};
