import { GraphQL } from "@graphql/graphQL";
import { GetShipmentNumbersQuery, GetShipmentNumbersQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const getShipmentNumbers = (opts?: QueryHookOptions<GetShipmentNumbersQuery, GetShipmentNumbersQueryVariables>) => {
    const { data, loading, error } = useQuery<GetShipmentNumbersQuery, GetShipmentNumbersQueryVariables>(GraphQL.getShipmentNumbers, opts);

    return {
        shipments: data && data.shipments ? data.shipments.items : [],
        shipmentsLoading: loading,
        shipmentsError: error
    };
};
