
import { ThumbnailLightbox } from "@components/thumbnailLightbox/thumbnailLightbox";
import { Image } from "@models/graphql";
import * as React from "react";
import { compose } from "redux";

export interface ThumbnailLightboxContainerProps {
    images: Image[];
    openCloseCallback?(isOpen: boolean): void;
}

export const ThumbnailLightboxContainer = compose<React.ComponentClass<ThumbnailLightboxContainerProps>>(

)(ThumbnailLightbox);

export type ThumbnailLightboxProps =
    ThumbnailLightboxContainerProps;
