import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect, Dispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "redux";

import { StoreState } from "@redux/reducers/root";
import { setSelectedSamplingIds, SetSelectedSamplingIdsActionOptions } from "@redux/actions/setSelectedSamplingIds";
import { setSamplingSort, SetSamplingSortActionOptions } from "@redux/actions/setSamplingSort";
import { setPreviousPath, SetPreviousPathActionOptions } from "@redux/actions/setPreviousPath";
import { withOidcUser } from "@axa-fr/react-oidc-context";
import { User } from "oidc-client";
import { withPermissions, PermissionContext } from "@components/permission/permissionProvider";
import { SamplingsTableFilter } from "./useSamplingsTableFilter";
import { SamplingsTable } from "./samplingsTable";
import { NonNullableURLSearchParams } from "@utils/nonNullableURLSearchParams";

export interface SamplingsTableContainerProps {
    filter: NonNullableURLSearchParams<SamplingsTableFilter>;
}

export const mapStateToProps = (state: StoreState) => ({
    selectedSamplingIds: state.main.selectedSamplingIds
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<{ type: string }>) => ({
    setSelectedSamplingIds(data: SetSelectedSamplingIdsActionOptions) { dispatch(setSelectedSamplingIds(data)); },
    setSamplingSort(data: SetSamplingSortActionOptions) { dispatch(setSamplingSort(data)); },
    setPreviousPath(data: SetPreviousPathActionOptions) { dispatch(setPreviousPath(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const SamplingsTableContainer = compose<React.ComponentClass<SamplingsTableContainerProps>>(
    withRedux,
    withRouter,
    injectIntl,
    withOidcUser,
    withPermissions
)(SamplingsTable);

export type SamplingsTableProps =
    InjectedIntlProps &
    SamplingsTableContainerProps &
    WithReduxStateProps &
    WithReduxDispatchProps &
    PermissionContext &
    { oidcUser: User } &
    RouteComponentProps;
