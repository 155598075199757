import * as Sentry from "@sentry/browser";

import { SENTRY_DSN, BUILD_NUMBER } from "./environment";

export const initErrorTracking = () => {

    Sentry.init({
        dsn: SENTRY_DSN,
        release: BUILD_NUMBER,
        // blacklistUrls: [/localhost/],
        enabled: true
    });

};
