import { Action } from "@redux/actions/action";
import { ActionTypes } from "@redux/actions/types";

export interface SetSelectedSamplingIdsActionOptions {
    ids: number[];
}

export const setSelectedSamplingIds = (data: SetSelectedSamplingIdsActionOptions): SetSelectedSamplingIdsAction => {
    return {
        type: ActionTypes.SetSelectedSamplingIds,
        ...data
    };
};

export type SetSelectedSamplingIdsAction = Action & SetSelectedSamplingIdsActionOptions;
export const isSetSelectedSamplingIdsAction = (action: Action): action is SetSelectedSamplingIdsAction => action.type === ActionTypes.SetSelectedSamplingIds;
