import { GraphQL } from "@graphql/graphQL";
import { CreateOperatorMutationVariables, Mutation, Operator } from "@models/graphql";
import { graphql, OperationOption } from "@apollo/react-hoc";

export interface CreateOperatorMutationResult {
    createOperator(variables: CreateOperatorMutationVariables): Promise<Operator>;
}

export const createCreateOperatorMutation = <TProps = {}>(options?: OperationOption<TProps, Mutation, CreateOperatorMutationVariables, CreateOperatorMutationResult>) =>
    graphql<TProps, Mutation, CreateOperatorMutationVariables, CreateOperatorMutationResult>(GraphQL.createOperator, {
        props({ mutate }): CreateOperatorMutationResult {
            if (!mutate) {
                throw new Error("No mutate found");
            }

            return {
                // TODO: Add correct response return type
                async createOperator(variables: CreateOperatorMutationVariables): Promise<Operator> {
                    try {
                        const result = await mutate({
                            variables
                        });

                        if (!result || !result.data) {
                            throw new Error("Result was empty");
                        }

                        return result.data.createOperator;
                    } catch (error) {
                        throw error;
                    }
                }
            };
        },
        ...options
    });
