import { Tabs } from "antd";
import * as React from "react";
import { FunctionComponent } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router";
import { compose } from "redux";
import { ApplianceForm } from "../applianceForm/applianceForm";
import { ApplianceListDetailPage } from "../applianceListDetailPage/applianceListDetailPage";
import { ApplianceListTable } from "../applianceListTable/applianceListTable";
import { ApplianceSettings } from "../appliancesSettings/applianceSettings";
import { AppliancesTable } from "../appliancesTable/appliancesTable";
import { AppliancesPageStyle } from "./appliancesPageStyle";

export interface AppliancesPageProps {}

export const AppliancesPageComponent: FunctionComponent<AppliancesPageProps> = (
    props: AppliancesPageProps & InjectedIntlProps & RouteComponentProps
) => {
    const { intl, history } = props;

    const onTabChange = (activeKey: string) => {
        if (activeKey === "validity" || activeKey === "settings") {
            history.replace(`/appliances/${activeKey}`);
        } else {
            history.replace("/appliances");
        }
    };

    return (
        <AppliancesPageStyle>
            <Switch>
                <Route path="/appliances/create" component={ApplianceForm} />
                <Route
                    path="/appliances/update/:id"
                    component={ApplianceForm}
                />
                <Route
                    path="/appliances/validity/create"
                    component={ApplianceListDetailPage}
                />
                <Route
                    path="/appliances/validity/update/:id/:tab?"
                    component={ApplianceListDetailPage}
                />
                <Route
                    path="/appliances/:activeKey?"
                    render={a => {
                        const key = a.match.params.activeKey || "appliances";

                        return (
                            <Tabs
                                defaultActiveKey={key}
                                animated={false}
                                onChange={onTabChange}
                            >
                                <Tabs.TabPane
                                    tab={intl.formatMessage({
                                        id: "appliances"
                                    })}
                                    key="appliances"
                                >
                                    <AppliancesTable />
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    tab={intl.formatMessage({
                                        id: "appliances.validityPeriods"
                                    })}
                                    key="validity"
                                >
                                    <ApplianceListTable />
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    tab={intl.formatMessage({
                                        id: "appliances.settings"
                                    })}
                                    key="settings"
                                >
                                    <ApplianceSettings />
                                </Tabs.TabPane>
                            </Tabs>
                        );
                    }}
                />
            </Switch>
        </AppliancesPageStyle>
    );
};

export const AppliancesPage = compose(
    injectIntl,
    withRouter
)(AppliancesPageComponent);
