import { GraphQL } from "@graphql/graphQL";
import { GetAppliancesQuery, GetAppliancesQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const getAppliances = (opts?: QueryHookOptions<GetAppliancesQuery, GetAppliancesQueryVariables>) => {
    const { data, loading, error, refetch } = useQuery<GetAppliancesQuery, GetAppliancesQueryVariables>(GraphQL.getAppliances, opts);

    return {
        appliances: data && data.appliances ? data.appliances.items : [],
        appliancesCount: data && data.appliances ? data.appliances.count : 0,
        appliancesLoading: loading,
        appliancesError: error,
        refetch
    };
};
