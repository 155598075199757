import { SamplingsGrid } from "@components/samplings/samplingsGrid/samplingsGrid";
import {
    createDeleteSamplingsMutation,
    DeleteSamplingsMutationResult
} from "@graphql/hocs/deleteSamplings";
import {
    createGetSamplingListQuery,
    GetSamplingListQueryResult
} from "@graphql/hocs/getSamplingList";
import {
    createUpdateSamplingsMutation,
    UpdateSamplingsMutationResult
} from "@graphql/hocs/updateSamplings";
import {
    setSelectedSamplingIds,
    SetSelectedSamplingIdsActionOptions
} from "@redux/actions/setSelectedSamplingIds";
import { StoreState } from "@redux/reducers/root";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect, Dispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "redux";
import {
    SetPreviousPathActionOptions,
    setPreviousPath
} from "@redux/actions/setPreviousPath";
import { User } from "oidc-client";
import { withOidcUser } from "@axa-fr/react-oidc-context";
import {
    PermissionContext,
    withPermissions
} from "@components/permission/permissionProvider";
import {
    calculatedStateFilter,
    SamplingsTableFilter
} from "../samplingsTable/useSamplingsTableFilter";
import { NonNullableURLSearchParams } from "@utils/nonNullableURLSearchParams";

export interface SamplingsGridContainerProps {
    filter: NonNullableURLSearchParams<SamplingsTableFilter>;
}

export const mapStateToProps = (
    state: StoreState,
    ownProps: SamplingsGridContainerProps
) => ({
    selectedSamplingIds: state.main.selectedSamplingIds
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<{ type: string }>) => ({
    setSelectedSamplingIds(data: SetSelectedSamplingIdsActionOptions) {
        dispatch(setSelectedSamplingIds(data));
    },
    setPreviousPath(data: SetPreviousPathActionOptions) {
        dispatch(setPreviousPath(data));
    }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(
    mapStateToProps,
    mapDispatchToProps
);

const withSamples = createGetSamplingListQuery<
    SamplingsGridContainerProps & WithReduxStateProps
>({
    options(ownProps) {
        const toDate = ownProps.filter.get("toTimeSampled");

        const minimumMatchingPercentage = ownProps.filter.get(
            "minimumMatchingPercentage"
        );
        const maximumMatchingPercentage = ownProps.filter.get(
            "maximumMatchingPercentage"
        );

        return {
            variables: {
                paging: {
                    limit: ownProps.filter.get("pageSize"),
                    offset:
                        (ownProps.filter.get("page") - 1) *
                        ownProps.filter.get("pageSize")
                },
                filter: {
                    fromTimeSampled: ownProps.filter
                        .get("fromTimeSampled")
                        .toISOString(),
                    toTimeSampled:
                        toDate !== undefined ? toDate.toISOString() : null,
                    applianceIds: ownProps.filter.get("applianceIds"),
                    shipmentIds: ownProps.filter.get("shipmentId")
                        ? [ownProps.filter.get("shipmentId") as number]
                        : null,
                    transportIds: ownProps.filter.get("transportId")
                        ? [ownProps.filter.get("transportId") as number]
                        : null,
                    manualEntry:
                        ownProps.filter.get("assignedBy") === "system"
                            ? false
                            : ownProps.filter.get("assignedBy") === "both"
                            ? null
                            : true,
                    minimumMatchingPercentage: minimumMatchingPercentage
                        ? minimumMatchingPercentage / 100
                        : null,
                    maximumMatchingPercentage: maximumMatchingPercentage
                        ? maximumMatchingPercentage / 100
                        : null,
                    minimumWeight: ownProps.filter.get("minimumWeight")
                        ? ownProps.filter.get("minimumWeight")
                        : null,
                    maximumWeight: ownProps.filter.get("maximumWeight")
                        ? ownProps.filter.get("maximumWeight")
                        : null,
                    sizes: ownProps.filter.get("size")
                        ? [ownProps.filter.get("size") as any]
                        : null,
                    brandIds: ownProps.filter.get("brandIds")
                        ? [...(ownProps.filter.get("brandIds") as any)]
                        : null,
                    ...calculatedStateFilter(ownProps.filter.get("approved"))
                },
                sort: [
                    {
                        field: ownProps.filter.get("sortField"),
                        order: ownProps.filter.get("sortOrder")
                    }
                ]
            }
        };
    }
});

const withUpdateSample = createUpdateSamplingsMutation({
    options: {
        refetchQueries: ["getSamples", "getSamplingList"]
    }
});
const withDeleteSample = createDeleteSamplingsMutation({
    options: {
        refetchQueries: ["getSamples", "getSamplingList"]
    }
});

export const SamplingsGridContainer = compose<
    React.ComponentClass<SamplingsGridContainerProps>
>(
    withRouter,
    withUpdateSample,
    withDeleteSample,
    injectIntl,
    withRedux,
    withSamples,
    withOidcUser,
    withPermissions
)(SamplingsGrid);

export type SamplingsGridProps = DeleteSamplingsMutationResult &
    UpdateSamplingsMutationResult &
    SamplingsGridContainerProps &
    GetSamplingListQueryResult &
    WithReduxStateProps &
    WithReduxDispatchProps &
    InjectedIntlProps &
    PermissionContext & { oidcUser: User } & RouteComponentProps;
