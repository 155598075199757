import { SortOrder } from "@models/graphql";
import { ViewMode } from "@pages/samplings/samplings";
import { nonNullableURLSearchParams } from "@utils/nonNullableURLSearchParams";
import { parseURLSearchParams } from "@utils/parseURLSearchParams";
import { usePersistedURLSearchParams } from "@utils/usePersistedURLSearchParams";
import * as moment from "moment";

export type StateType =
    | "approved"
    | "notApproved"
    | "corrected"
    | "automaticApproved";

export interface SamplingsTableFilter {
    fromTimeSampled: Date;
    toTimeSampled?: Date;
    page: number;
    pageSize: number;
    applianceIds?: number[];
    applianceCategoryIds?: number[];
    size?: "SMALL" | "BIG";
    approved?: StateType;
    assignedBy: "both" | "manual" | "system";
    transportId?: number;
    shipmentId?: number;
    minimumMatchingPercentage?: number;
    maximumMatchingPercentage?: number;
    sortOrder: SortOrder;
    sortField: string;
    viewMode: ViewMode;
    minimumWeight?: number;
    maximumWeight?: number;
    brandIds?: number[];
}

const firstDayOfTheYear = moment()
    .startOf("year")
    .toDate();

export const calculatedStateFilter = (
    state: StateType | undefined
):
    | {
          manualEntry: boolean;
          approved: boolean;
          systemApproved?: boolean;
      }
    | undefined => {
    switch (state) {
        case "corrected":
            return {
                manualEntry: true,
                approved: true
            };
        case "automaticApproved":
            return {
                manualEntry: false,
                approved: true,
                systemApproved: true
            };
        case "approved":
            return {
                approved: true,
                manualEntry: false
            };
        case "notApproved":
            return {
                approved: false,
                manualEntry: false,
                systemApproved: false
            };
        default:
            null;
    }
};

export const useSamplingsTableFilter = (fixedRoute?: string) =>
    nonNullableURLSearchParams<SamplingsTableFilter>(
        parseURLSearchParams<SamplingsTableFilter>(
            usePersistedURLSearchParams(["/samplings"], fixedRoute)
        ),
        {
            fromTimeSampled: firstDayOfTheYear,
            page: 1,
            pageSize: 20,
            sortField: "timeSampled",
            sortOrder: SortOrder.Desc,
            assignedBy: "both",
            viewMode: ViewMode.List
        }
    );
