import { addLocaleData, IntlProvider } from "react-intl";
import * as fr from "react-intl/locale-data/fr";
import * as nl from "react-intl/locale-data/nl";
import { connect } from "react-redux";

import { StoreState } from "@redux/reducers/root";

addLocaleData([...nl, ...fr]);

const nlMsg = require("../../translations/nl.json");
const frMsg = require("../../translations/fr.json");

const messages = {
    nl: nlMsg,
    fr: frMsg
};

export const mapStateToProps = (state: StoreState) => {

    const locale = state.main.selectedLanguage || "nl";

    return {
        locale,
        messages: messages[locale]
    };
};

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const ConnectedIntlProvider = connect<any, {}, IntlProvider.Props>(mapStateToProps)(IntlProvider);
