/* eslint-disable camelcase */
export const oidcConfiguration = {
    client_id: "956609ed-35ae-4d5e-97a7-3e380bf384d7",
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ""
        }/authentication/callback`,
    response_type: "id_token token",
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`,
    scope: "api://956609ed-35ae-4d5e-97a7-3e380bf384d7/management.access openid profile",
    authority: "/auth",
    silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ""
        }/authentication/silent_callback`,
    automaticSilentRenew: true,
    loadUserInfo: false,
    metadata: {
        auth: "/auth",
        authorization_endpoint: "https://login.microsoftonline.com/eda83595-40b6-4af3-9f1c-a330ff87b0b4/oauth2/v2.0/authorize",
        userinfo_endpoint: "https://graph.microsoft.com/oidc/userinfo",
        end_session_endpoint: "https://login.microsoftonline.com/eda83595-40b6-4af3-9f1c-a330ff87b0b4/oauth2/v2.0/logout",
        jwks_uri: "/auth/discovery/v2.0/keys",
        issuer: "https://login.microsoftonline.com/eda83595-40b6-4af3-9f1c-a330ff87b0b4/v2.0"
    },
    triggerAuthFlow: true
};
