import { Icon, Spin } from "antd";
import * as React from "react";

import { SpinnerStyle } from "@components/spinner/spinnerStyle";
import { FormattedMessage } from "react-intl";

export interface SpinnerProps {
    long?: boolean;
}

export const Spinner: React.StatelessComponent<SpinnerProps> = (props: SpinnerProps) => {
    const { long } = props;

    return (
        <SpinnerStyle>
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
            {long ? <FormattedMessage id="loading" /> : null}
        </SpinnerStyle>
    );
};
