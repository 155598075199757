import { OperatorFormProps } from "@components/operators/operatorForm/operatorFormContainer";
import { OperatorFormStyle } from "@components/operators/operatorForm/operatorFormStyle";
import { parseError } from "@utils/parseError";
import { Button, Form, Input, message, Select } from "antd";
import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface OperatorFormState {
    submitting: boolean;
    sites: {
        siteName: string;
        siteLocation: string;
        siteStations: string[];
    }[];
}

@autobind
export class OperatorForm extends React.Component<OperatorFormProps, OperatorFormState> {
    public state: OperatorFormState = {
        submitting: false,
        sites: [
            {
                siteName: "Hautes Ardennes",
                siteLocation: "Vielsalm",
                siteStations: ["Hautes Ardennes"]
            },
            {
                siteName: "Mirto",
                siteLocation: "Eeklo",
                siteStations: ["Revam binnen"]
            }
        ]
    };

    // tslint:disable-next-line: max-func-body-length
    public render() {

        const { form, intl, operator } = this.props;
        const { submitting } = this.state;
        const { getFieldDecorator, getFieldValue } = form;

        const selectedSite = this.state.sites.find(s => s.siteName === getFieldValue("site"));

        return (
            <OperatorFormStyle>
                <Form layout="horizontal" onSubmit={this.handleSubmit} >
                    <Form.Item>

                        {
                            getFieldDecorator("firstName", {
                                initialValue: operator && operator.firstName,
                                rules: [
                                    { required: true, message: intl.formatMessage({ id: "form.isrequired" }) }
                                ]
                            })(
                                <Input placeholder={intl.formatMessage({ id: "operators.form.firstname" })} />
                            )
                        }
                    </Form.Item>
                    <Form.Item>

                        {
                            getFieldDecorator("lastName", {
                                initialValue: operator && operator.lastName,
                                rules: [
                                    { required: true, message: intl.formatMessage({ id: "form.isrequired" }) }
                                ]
                            })(
                                <Input placeholder={intl.formatMessage({ id: "operators.form.lastname" })} />
                            )
                        }
                    </Form.Item>
                    <Form.Item>

                        {
                            getFieldDecorator("site", {
                                initialValue: operator && operator.site,
                                rules: [
                                    { required: true, message: intl.formatMessage({ id: "form.isrequired" }) }
                                ]
                            })(
                                <Select placeholder={intl.formatMessage({ id: "operators.form.site" })}>
                                    {
                                        this.state.sites.map((site) => (
                                            <Select.Option key={site.siteName} value={site.siteName}>{site.siteName}</Select.Option>
                                        ))
                                    }
                                </Select>
                            )
                        }
                    </Form.Item>
                    <Form.Item>

                        {
                            getFieldDecorator("stationName", {
                                initialValue: operator && operator.stationName,
                                rules: [
                                    { required: true, message: intl.formatMessage({ id: "form.isrequired" }) }
                                ]
                            })(
                                <Select placeholder={intl.formatMessage({ id: "operators.form.stationName" })}>
                                    {
                                        getFieldValue("site") && selectedSite && selectedSite.siteStations.map((station) => (
                                            <Select.Option key={station} value={station}>{station}</Select.Option>
                                        ))
                                    }
                                </Select>
                            )
                        }
                    </Form.Item>
                    <Form.Item>
                        {
                            getFieldDecorator("language", {
                                initialValue: operator && operator.language || "nl",
                                rules: [
                                    { required: true, message: intl.formatMessage({ id: "form.isrequired" }) }
                                ]
                            })(
                                <Select style={{ width: 75 }}>
                                    <Select.Option value="nl">NL</Select.Option>
                                    <Select.Option value="fr">FR</Select.Option>
                                    <Select.Option value="en">EN</Select.Option>
                                </Select>
                            )
                        }
                    </Form.Item>
                    <Button loading={submitting} htmlType="submit" type="primary">
                        {
                            this.props.mode === "create"
                                ? <FormattedMessage id="operators.add" />
                                : <FormattedMessage id="operators.modify" />
                        }

                    </Button>
                </Form>

            </OperatorFormStyle>

        );
    }

    private async handleSubmit(e: React.FormEvent<any>) {
        e.preventDefault();
        const { form, createOperator, updateOperator, changeMode, intl } = this.props;
        const { validateFields } = form;

        validateFields(async (errors, values) => {
            if (errors) {
                return;
            }

            this.setState({ submitting: true });

            try {
                const site = this.state.sites.find(s => s.siteName === values["site"]);

                if (this.props.mode === "create") {

                    await createOperator({
                        input: {
                            site: values["site"],
                            firstName: values["firstName"],
                            lastName: values["lastName"],
                            language: values["language"],
                            stationName: values["stationName"],
                            stationLocation: site ? site.siteLocation : values["site"]
                        }
                    });
                    message.info(intl.formatMessage({ id: "createOperator.succesfullyAdded" }));
                } else if (this.props.operator) {
                    changeMode("create");
                    await updateOperator({
                        id: this.props.operator.id,
                        input: {
                            site: values["site"],
                            firstName: values["firstName"],
                            lastName: values["lastName"],
                            language: values["language"],
                            stationName: values["stationName"],
                            stationLocation: site ? site.siteLocation : values["site"]
                        }
                    });
                    form.resetFields();
                    message.info(intl.formatMessage({ id: "createOperator.succesfullyUpdated" }));
                }
            } catch (err) {
                console.log(err);
                message.error(parseError(err, intl));
            }

            this.setState({ submitting: false });
        });

    }
}
