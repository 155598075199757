import { getUserManager } from "@axa-fr/react-oidc-context";
import { Menu, Select } from "antd";
import { autobind } from "core-decorators";
import { UserManager } from "oidc-client";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { HeaderStyle, LeftAligned, Logo, NavMenu, RightAligned } from "@components/header/headerStyle";
import { HeaderProps } from "@components/header/headerContainer";
import { getHrefWithFilters } from "@utils/routerHook";

export interface HeaderState {

}

@autobind
export class Header extends React.Component<HeaderProps, HeaderState> {

    public render() {
        const { currentPath, selectedLanguage, oidcUser } = this.props;
        const match = currentPath.match(/^\/\w*/);

        let selectedKeys: string[] = [];

        if (match) {
            selectedKeys = [match[0]];
        }

        return (
            <HeaderStyle>
                <LeftAligned>
                    <Logo src={require("@assets/images/RECUPEL-logo.jpg")} />
                    <NavMenu
                        selectedKeys={selectedKeys}
                        mode="horizontal"
                    >
                        <Menu.Item key="/transports">
                            <Link to={getHrefWithFilters("/transports")}>
                                <FormattedMessage id="nav.transports" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/samplings">
                            <Link to={getHrefWithFilters("/samplings")}>
                                <FormattedMessage id="nav.samplings" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/operators">
                            <Link to="/operators">
                                <FormattedMessage id="nav.operators" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/appliances">
                            <Link to="/appliances">
                                <FormattedMessage id="nav.devices" />
                            </Link>
                        </Menu.Item>
                    </NavMenu>
                </LeftAligned>
                <RightAligned>
                    <Select value={selectedLanguage} onChange={this.languageChange}>
                        <Select.Option value="nl">NL</Select.Option>
                        <Select.Option value="fr">FR</Select.Option>
                    </Select>

                    <FormattedMessage id="nav.welcome" values={{ name: oidcUser ? oidcUser.profile.name || oidcUser.profile.preffered_username : "" }} />
                    <span className="logout" onClick={this.onLogout}><FormattedMessage id="nav.logout" /></span>
                </RightAligned>
            </HeaderStyle>
        );
    }

    private languageChange(language: string) {
        this.props.setLanguage({ language });
    }

    private onLogout() {
        const userManager: UserManager = getUserManager();
        userManager.signoutRedirect();

    }
}
