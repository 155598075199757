import { TransportTableStyle } from "@components/transports/transportTable/transportTableStyle";
import { SortOrder, TransportFragment, TransportStatus } from "@models/graphql";
import { useFormatMessage } from "@utils/intlHook";
import { Alert, Table } from "antd";
import { ColumnProps, PaginationConfig, SorterResult } from "antd/lib/table";
import * as moment from "moment";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedNumber, InjectedIntl } from "react-intl";
import { ShipmentTable } from "./shipmentTable/shipmentTable";
import { useGetTransports } from "@graphql/hocs/hooks/getTransports";
import { NonNullableURLSearchParams } from "@utils/nonNullableURLSearchParams";
import { TransportsTableFilter } from "./useTransportsTableFilter";

interface Props {
    filter: NonNullableURLSearchParams<TransportsTableFilter>;
}

export interface TransportTableItem {
    key: number;
    transportNumber: string;
    estimatedArrivalDate: string;
    fraction: string;
    manual: number;
    approvedPercentage: number;
    state: TransportStatus;
    site: string;
}

interface StatusColumnProps {
    status: TransportStatus;
}

const StatusColumn: FunctionComponent<StatusColumnProps> = ({ status }) => {
    const formatMessage = useFormatMessage();

    return (
        <div className={`transportStatus ${status}`}>
            {formatMessage({ id: `transports.status.${status.toLowerCase()}` })}
        </div>
    );
};

interface SiteColumnProps {
    site: string;
}

const SiteColumn: FunctionComponent<SiteColumnProps> = ({ site }) => {
    return <div className={"transportStatus"}>{site}</div>;
};

const getColumns = (
    formatMessage: InjectedIntl["formatMessage"]
): ColumnProps<TransportTableItem>[] => [
    {
        title: formatMessage({ id: "transports.table.transportNumber" }),
        dataIndex: "transportNumber"
    },
    {
        title: formatMessage({ id: "transports.table.fraction" }),
        dataIndex: "processingGroup",
        render: (text, record) => record.fraction,
        sorter: true
    },
    {
        title: formatMessage({ id: "transports.table.estimatedArrivalDate" }),
        dataIndex: "estimatedArrivalDate",
        sorter: true,
        defaultSortOrder: "ascend",
        render: (text: string) => moment(text).format("DD-MM-YYYY")
    },
    {
        title: formatMessage({ id: "transports.table.manual" }),
        dataIndex: "manual",
        render: text => (
            <div>
                <FormattedNumber maximumFractionDigits={2} value={text * 100} />
                %
            </div>
        )
    },
    {
        title: formatMessage({ id: "transports.table.approved" }),
        dataIndex: "approvedPercentage",
        render: text => (
            <div>
                <FormattedNumber maximumFractionDigits={2} value={text * 100} />
                %
            </div>
        )
    },
    {
        title: formatMessage({ id: "transports.table.state" }),
        dataIndex: "status",
        sorter: true,
        render: (text, record) => <StatusColumn status={record.state} />
    },
    {
        title: formatMessage({ id: "transports.table.site" }),
        dataIndex: "site",
        sorter: true,
        render: (_, record) => <SiteColumn site={record.site} />
    }
];

const toTransportItem = (transport: TransportFragment): TransportTableItem => ({
    key: transport.id,
    transportNumber: transport.transportNumber,
    fraction: transport.processingFraction,
    manual: transport.percentageEnteredManually,
    approvedPercentage: transport.percentageApproved,
    estimatedArrivalDate: transport.estimatedArrivalDate,
    state: transport.status,
    site: transport.site
});

export const TransportTable: FunctionComponent<Props> = ({ filter }) => {
    const formatMessage = useFormatMessage();
    const {
        transports,
        transportsError,
        transportsLoading,
        transportsCount
    } = useGetTransports({
        variables: {
            filter: {
                transportNumber:
                    (filter.get("transportNumber") &&
                        filter.get("transportNumber")!.toString()) ||
                    undefined,
                processingGroup: filter.get("processingGroup"),
                fromEstimatedArrivalDate:
                    filter.get("fromEstimatedArrivalDate") !== undefined
                        ? moment(
                              filter.get("fromEstimatedArrivalDate")
                          ).toISOString()
                        : undefined,
                toEstimatedArrivalDate:
                    filter.get("toEstimatedArrivalDate") !== undefined
                        ? moment(
                              filter.get("toEstimatedArrivalDate")
                          ).toISOString()
                        : undefined,
                site: filter.get("site"),
                statusses: filter.get("statusses")
            },
            paging: {
                limit: filter.get("pageSize"),
                offset: (filter.get("page") - 1) * filter.get("pageSize")
            },
            sort: [
                {
                    field: filter.get("sortField"),
                    order: filter.get("sortOrder")
                }
            ]
        }
    });

    const onChange = (
        pagination: PaginationConfig,
        filters: Record<keyof TransportTableItem, string[]>,
        sorter: SorterResult<TransportTableItem>
    ) => {
        if (sorter) {
            if (!sorter.columnKey) {
                filter.delete("sortOrder");
                filter.delete("sortField");
            } else {
                filter.set(
                    "sortOrder",
                    sorter.order === "ascend" ? SortOrder.Desc : SortOrder.Asc
                );
                filter.set("sortField", sorter.columnKey);
            }
        }

        if (pagination.current) filter.set("page", pagination.current);
        if (pagination.pageSize) filter.set("pageSize", pagination.pageSize);
    };

    return (
        <TransportTableStyle>
            {transportsError && (
                <Alert
                    style={{ marginTop: 15, marginBottom: 15 }}
                    type="error"
                    icon="cross"
                    message="Er ging iets mis tijdens het ophalen van de data"
                />
            )}
            <Table
                loading={transportsLoading}
                columns={getColumns(formatMessage)}
                expandedRowRender={(record: TransportTableItem) => (
                    <ShipmentTable transportId={record.key} />
                )}
                dataSource={(transports || []).map(toTransportItem)}
                pagination={{
                    pageSize: filter.get("pageSize"),
                    pageSizeOptions: ["20", "50", "100"],
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: transportsCount,
                    hideOnSinglePage: false,
                    current: filter.get("page")
                }}
                onChange={onChange}
            />
        </TransportTableStyle>
    );
};
