import { GraphQL } from "@graphql/graphQL";
import { GetTransportsNumbersQuery, GetTransportsNumbersQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const getTransportNumbers = (opts?: QueryHookOptions<GetTransportsNumbersQuery, GetTransportsNumbersQueryVariables>) => {
    const { data, loading, error } = useQuery<GetTransportsNumbersQuery, GetTransportsNumbersQueryVariables>(GraphQL.getTransportsNumbers, opts);

    return {
        transports: data && data.transports ? data.transports.items : [],
        transportsLoading: loading,
        transportsError: error
    };
};
