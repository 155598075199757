import { ApplianceSelect } from "@components/applianceSelect/applianceSelect";
import { SelectedDevicesProps } from "@components/samplings/selectedDevices/selectedDevicesContainer";
import { ButtonDelete, ChangeDevice, RightAligned, SelectedDevicesStyle } from "@components/samplings/selectedDevices/selectedDevicesStyle";
import { parseError } from "@utils/parseError";
import { Button, message } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import {captureException} from "@sentry/browser";
import { Permission } from "@components/permission/permission";
import { Size } from "@models/graphql";
import { SizeSelect } from "@components/sizeSelect/sizeSelect";
import { useUpdateSamplings } from "@graphql/hocs/hooks/useUpdateSampling";
import { useDeleteSamplings } from "@graphql/hocs/hooks/useDeleteSampling";

export interface SelectedDevicesState {

}

export const SelectedDevices = (props: SelectedDevicesProps) => {
    const { updateSamplings, isLoading: isUpdating } = useUpdateSamplings({ refetchQueries: ["getSamples", "getSamplingList"] });
    const { deleteSamplings, isLoading: isDeleting } = useDeleteSamplings({ refetchQueries: ["getSamples", "getSamplingList"] });  

    const { selectedSamplings, intl, setSelectedSamplingIds } = props;

    const [selectedApplianceId, setSelectedApplianceId] = React.useState<number | undefined>();
    const [selectedSize, setSelectedSize] = React.useState<Size | undefined>();

    const deleteSelected = React.useCallback(
        async () => {
            try {
                await deleteSamplings({
                    ids: selectedSamplings
                });
                setSelectedSamplingIds({ ids: [] });
                message.info(intl.formatMessage({ id: "deleteSamplings.succes" }));
            } catch (err) {
                console.log(err);

                message.error(parseError(err, intl));
            }
        },
        [selectedSamplings]
    );

    const changeSelected = React.useCallback(
        async () => {
            try {
                await updateSamplings({
                    ids: selectedSamplings,
                    input: {
                        ...selectedApplianceId ? { applianceId: selectedApplianceId } : {},
                        ...selectedSize ? { size: selectedSize } : {},
                    }
                });

                setSelectedSamplingIds({ ids: [] });
                message.info(intl.formatMessage({ id: "updateSamplings.succes" }));
            } catch (err) {
                captureException(err);
                message.error(parseError(err, intl));
            }
        },
        [selectedSamplings, selectedApplianceId, selectedSize]
    );

    if (!selectedSamplings.length) {
        return null;
    }

    const onApplianceSelectChange = (val: number[] | number | null | undefined) => {
        if (!val) {
            setSelectedApplianceId(undefined);
        } else if (typeof val === "number") {
            setSelectedApplianceId(val);
        }
    };

    return (
        <SelectedDevicesStyle>
            <FormattedMessage id="sampling.selected" values={{ amount: selectedSamplings.length }} />
            <RightAligned>
                <ChangeDevice>
                    <ApplianceSelect
                        style={{ width: 280 }}
                        onChange={onApplianceSelectChange}
                        value={selectedApplianceId}
                    />
                    <SizeSelect
                        style={{ width: 280 }}
                        onChange={setSelectedSize}
                        value={selectedSize}
                    />
                    <Button
                        disabled={!selectedApplianceId && !selectedSize}
                        loading={isUpdating}
                        onClick={changeSelected}
                        type={"primary"}
                    >
                        <FormattedMessage id="sampling.selected.modify" />
                    </Button>
                </ChangeDevice>
                <Permission>
                    |
                    <ButtonDelete loading={isDeleting} onClick={deleteSelected} type={"danger"}><FormattedMessage id="sampling.selected.delete" /></ButtonDelete>
                </Permission>
            </RightAligned>
        </SelectedDevicesStyle>
    );
};
