import { GraphQL } from "@graphql/graphQL";
import { GetApplianceCategoriesQuery, GetApplianceCategoriesQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetApplianceCategories = (options?: QueryHookOptions<GetApplianceCategoriesQuery, GetApplianceCategoriesQueryVariables>) => {
    const { data, error, loading, refetch } = useQuery<GetApplianceCategoriesQuery, GetApplianceCategoriesQueryVariables>(GraphQL.getApplianceCategories, options);

    return {
        applianceCategories: data && data.applianceCategories ? data.applianceCategories.items : [],
        applianceCategoriesCount: data && data.applianceCategories ? data.applianceCategories.count : 0,
        applianceCategoriesLoading: loading,
        applianceCategoriesError: error,
        refetch
    };
};
