import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { from } from "apollo-link";
import { BatchHttpLink } from "apollo-link-batch-http";
import { setContext } from "apollo-link-context";
import { createPersistedQueryLink } from "apollo-link-persisted-queries";
import { SchemaLink } from "apollo-link-schema";
import { User, UserManager } from "oidc-client";
import DebounceLink from "apollo-link-debounce";

import { getUserManager } from "@axa-fr/react-oidc-context";

const authLink = setContext(async (_, { headers }) => {
    const newHeaders = { ...headers };

    const userManager: UserManager = getUserManager();

    let user: User | null = await userManager.getUser();

    if (user) {
        if (user.expired) {
            try {
                user = await userManager.signinSilent();

                if (!user) {
                    await userManager.signinRedirect();
                }
            } catch (err) {
                await userManager.signinRedirect();
            }
        }

        newHeaders.Authorization = `Bearer ${user.access_token}`;
        newHeaders.selectedCustomer = localStorage.getItem("selectedCustomer");
    }

    return { headers: newHeaders };
});

const link = from([
    authLink,
    createPersistedQueryLink(),
    new DebounceLink(400),
    new BatchHttpLink({ uri: "/graphql" })
]);

// Send mutations containing an Upload scalar to the upload link. Send the rest to the batchlink.

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: require("@graphql/fragmentTypes.json")
});

export const client = new ApolloClient({
    link,
    cache: new InMemoryCache({
        fragmentMatcher
    })
});
