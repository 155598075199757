import { GraphQL } from "@graphql/graphQL";
import { CreateApplianceListMutation, CreateApplianceListMutationVariables } from "@models/graphql";
import { ApolloError } from "apollo-client";
import { useState } from "react";
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";

const defaultCreateApplianceListOptions: MutationHookOptions<CreateApplianceListMutation, CreateApplianceListMutationVariables> = {

};

export const useCreateApplianceList = (options?: MutationHookOptions<CreateApplianceListMutation, CreateApplianceListMutationVariables>) => {
    const [hookState, setHookState] = useState<{
        isLoading: boolean;
        data?: CreateApplianceListMutation;
        error?: ApolloError;
    }>({
        isLoading: false
    });

    const [mutation] = useMutation<CreateApplianceListMutation, CreateApplianceListMutationVariables>(GraphQL.createApplianceList);

    const createApplianceList = async (variables: CreateApplianceListMutationVariables) => {
        setHookState({
            isLoading: true
        });

        try {
            const response = await mutation({
                ...defaultCreateApplianceListOptions,
                ...options,
                variables
            });

            setHookState({
                data: response.data,
                isLoading: false
            });

            return {
                data: response.data,
                error: response.errors
            };
        } catch (error) {
            setHookState({
                error,
                isLoading: false
            });

            return {
                error
            };
        }
    };

    return {
        isLoading: hookState.isLoading,
        error: hookState.error,
        data: hookState.data,
        createApplianceList
    };
};
