import { Layout } from "antd";
import styled from "styled-components";

export const MainLayout = styled(Layout)`
    min-height: 100vh !important;
    background:transparent;
    > .ant-layout{
        min-height: 100%;
    }
`;

export const MainLayoutContent = styled.div`
    flex-grow:1;
    display: flex;
    position: relative;
    > div{
        flex: 1;
    }
`;

export const MainHeader = styled(Layout.Header)`
    width: 100%;
`;
