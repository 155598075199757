import { useDeleteApplianceList } from "@graphql/hocs/hooks/useDeleteApplianceList";
import { useGetApplianceLists } from "@graphql/hocs/hooks/useGetApplianceLists";
import { GetApplianceListsQuery, SortOrder } from "@models/graphql";
import { parseError } from "@utils/parseError";
import { Alert, Button, Icon, message, Popconfirm } from "antd";
import Table, { ColumnProps, PaginationConfig } from "antd/lib/table";
import { NormalizedCache } from "apollo-cache-inmemory";
import * as moment from "moment";
import * as React from "react";
import { FunctionComponent } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "redux";
import { DuplicateApplianceListModal } from "../applianceListDetailPage/duplicateApplianceListModal/duplicateApplianceListModal";
import { Buttons } from "../appliancesTable/appliancesTableStyle";
import { ApplianceListTableStyle } from "./applianceListTableStyle";

export interface ApplianceListTableProps {

}

export const ApplianceListTableComponent: FunctionComponent<ApplianceListTableProps> = (props: ApplianceListTableProps & RouteComponentProps & InjectedIntlProps) => {
    const { intl, history } = props;

    const [pageSize, setPageSize] = React.useState(10);
    const [paging, setPaging] = React.useState({ limit: 10, offset: 0 });
    const [duplicateListId, setDuplicateListId] = React.useState<number | null>(null);
    const client = useApolloClient();

    const { deleteApplianceList: deleteApplianceListMutation } = useDeleteApplianceList({
        async update(cache) {
            const data: NormalizedCache = (cache as any).data;

            Object.keys((data as any).data).forEach(key => key.match(/^ApplianceList/) && data.delete(key));
            Object.keys((data as any).data).forEach(key => key.match(/^\$ROOT_QUERY\.applianceLists/) && data.delete(key));

            client.reFetchObservableQueries();
        }
    });

    const { applianceLists, applianceListsCount, applianceListsError, applianceListsLoading } = useGetApplianceLists({
        variables: {
            paging,
            sort: [
                {
                    field: "translation.name",
                    order: SortOrder.Desc
                }
            ]
        }
    });

    const createApplianceList = () => {
        history.push("/appliances/validity/create");
    };

    const onTableChange = (pagination: PaginationConfig) => {
        if (pagination.current && pagination.pageSize) {
            setPaging({
                limit: pagination.pageSize,
                offset: (pagination.current - 1) * pagination.pageSize
            });

            setPageSize(pagination.pageSize);
        }
    };

    const duplicateCallback = () => setDuplicateListId(null);

    const deleteApplianceList = async (id: number) => {

        try {
            await deleteApplianceListMutation({
                id
            });

            message.info(intl.formatMessage({ id: "applianceList.deleted" }));
        } catch (err) {
            console.log(err);

            message.error(parseError(err, intl));
        }
    };

    const columns: ColumnProps<GetApplianceListsQuery["applianceLists"]["items"][0]>[] = [
        {
            key: "name",
            title: intl.formatMessage({ id: "applianceLists.name" }),
            dataIndex: "name"
        },
        {
            key: "activeFrom",
            title: intl.formatMessage({ id: "applianceLists.activeFrom" }),
            dataIndex: "activeFrom",
            width: 180,
            render(date: string) { return moment(date).format("DD/MM/YYYY"); }
        },
        {
            key: "activeTo",
            title: intl.formatMessage({ id: "applianceLists.activeTo" }),
            dataIndex: "activeTo",
            width: 180,
            render(date: string) { return moment(date).format("DD/MM/YYYY"); }
        },
        {
            key: "editdelete",
            width: 80,
            render(_, record) {

                return <Buttons>
                    <Button onClick={() => setDuplicateListId(record.id)} shape="circle"><Icon type="copy" /></Button>
                    <Button onClick={() => history.push(`/appliances/validity/update/${record.id}`)} shape="circle"><Icon type="edit" /></Button>
                    <Popconfirm onConfirm={() => deleteApplianceList(record.id)} title={intl.formatMessage({ id: "applianceList.deleteConfirm" })}>
                        <Button shape="circle"><Icon type="delete" /></Button>
                    </Popconfirm>
                </Buttons>;
            }
        }
    ];

    return (
        <ApplianceListTableStyle>
            <div className="bar">
                <span></span>
                <Button onClick={createApplianceList} type="primary">
                    + &nbsp; <FormattedMessage id="applianceLists.create" />
                </Button>
            </div>

            <DuplicateApplianceListModal onDone={duplicateCallback} applianceListId={duplicateListId} />

            {applianceListsError && <Alert style={{ marginBottom: 15, marginTop: 15 }} type="error" message={parseError(applianceListsError, intl)} />}
            <Table
                columns={columns}
                dataSource={applianceLists}
                loading={applianceListsLoading}
                rowKey="id"
                pagination={{
                    pageSize,
                    total: applianceListsCount,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    current: paging.offset / pageSize + 1
                }}
                onChange={onTableChange}
            />
        </ApplianceListTableStyle>
    );
};

export const ApplianceListTable = compose(
    injectIntl,
    withRouter
)(ApplianceListTableComponent);
