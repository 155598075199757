import { SortOrder, TransportStatus } from "@models/graphql";
import { nonNullableURLSearchParams } from "@utils/nonNullableURLSearchParams";
import { parseURLSearchParams } from "@utils/parseURLSearchParams";
import { usePersistedURLSearchParams } from "@utils/usePersistedURLSearchParams";

export interface TransportsTableFilter {
    transportNumber?: string;
    processingGroup?: string;
    page: number;
    pageSize: number;
    sortOrder: SortOrder;
    sortField: string;
    fromEstimatedArrivalDate?: Date;
    toEstimatedArrivalDate?: Date;
    site?: string;
    statusses?: TransportStatus[];
}

export const useTransportsTableFilter = () =>
    nonNullableURLSearchParams<TransportsTableFilter>(
        parseURLSearchParams<TransportsTableFilter>(
            usePersistedURLSearchParams(["/transports"])
        ),
        {
            page: 1,
            pageSize: 20,
            sortOrder: SortOrder.Desc,
            sortField: "estimatedArrivalDate"
        }
    );
