import { Buttons } from "@components/appliances/appliancesTable/appliancesTableStyle";
import { useDeleteApplianceCategory } from "@graphql/hocs/hooks/useDeleteApplianceCategory";
import { useGetApplianceCategories } from "@graphql/hocs/hooks/useGetApplianceCategories";
import { GetApplianceCategoriesQuery } from "@models/graphql";
import { parseError } from "@utils/parseError";
import { Alert, Button, Icon, message, Popconfirm } from "antd";
import Table, { ColumnProps, PaginationConfig } from "antd/lib/table";
import { NormalizedCache } from "apollo-cache-inmemory";
import * as React from "react";
import { FunctionComponent } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "redux";
import { ApplianceCategoryForm } from "./applianceCategoryForm/applianceCategoryForm";
import { ApplianceCategoryTableStyle } from "./applianceCategoryTableStyle";

export interface ApplianceCategoryTableProps {
    applianceListId?: number;
}

export const ApplianceCategoryTableComponent: FunctionComponent<ApplianceCategoryTableProps> = (props: ApplianceCategoryTableProps & RouteComponentProps & InjectedIntlProps) => {
    const { intl, history, applianceListId } = props;

    const [pageSize, setPageSize] = React.useState(10);
    const [paging, setPaging] = React.useState({ limit: 10, offset: 0 });

    const [selectedAppliaceCategory, setSelectedApplianceCategory] = React.useState<GetApplianceCategoriesQuery["applianceCategories"]["items"][0] | null>(null);

    const { applianceCategories, applianceCategoriesError, applianceCategoriesLoading, applianceCategoriesCount, refetch } = useGetApplianceCategories({
        variables: {
            filter: {
                applianceListId
            },
            paging
        },
        skip: !applianceListId
    });

    const { isLoading, deleteApplianceCategory } = useDeleteApplianceCategory({
        update(cache) {
            const data: NormalizedCache = (cache as any).data;

            Object.keys((data as any).data).forEach(key => key.match(/^ApplianceCategory/) && data.delete(key));
            Object.keys((data as any).data).forEach(key => key.match(/^\$ROOT_QUERY\.applianceCategories/) && data.delete(key));

            refetch();
        }
    });

    const onTableChange = (pagination: PaginationConfig) => {
        if (pagination.current && pagination.pageSize) {
            setPaging({
                limit: pagination.pageSize,
                offset: (pagination.current - 1) * pagination.pageSize
            });

            setPageSize(pagination.pageSize);
        }
    };

    const onDelete = async (id: number) => {
        const { data, error } = await deleteApplianceCategory({
            id
        });

        if (error) {
            message.error(parseError(error, intl));

            return;
        }

        if (data && data.deleteApplianceCategory.success) {
            message.success(intl.formatMessage({ id: "applianceCategory.deleted" }));
        }
    };

    const columns: ColumnProps<GetApplianceCategoriesQuery["applianceCategories"]["items"][0]>[] = [

        {
            key: "name",
            title: intl.formatMessage({ id: "applianceCategories.name" }),
            dataIndex: "name",
            render(text: string) { return text.toUpperCase(); }
        },
        {
            key: "editdelete",
            width: 80,
            render(_, record) {
                return <Buttons>
                    <Button onClick={() => setSelectedApplianceCategory(record)} shape="circle"><Icon type="edit" /></Button>
                    <Popconfirm onConfirm={() => onDelete(record.id)} placement="left" title={intl.formatMessage({ id: "applianceCategory.deleteConfirm" })}>
                        <Button shape="circle"><Icon type="delete" /></Button>
                    </Popconfirm>
                </Buttons>;
            }
        }
    ];

    const saveApplianceCategory = () => {
        setSelectedApplianceCategory(null);
    };

    return (
        <ApplianceCategoryTableStyle>

            <div className="bar">
                <ApplianceCategoryForm createSaveCallback={refetch} applianceListId={applianceListId} editSaveCallback={saveApplianceCategory} applianceCategory={selectedAppliaceCategory} />
            </div>

            {applianceCategoriesError && <Alert style={{ marginBottom: 15 }} type="error" message={parseError(applianceCategoriesError, intl)} />}
            <Table
                columns={columns}
                dataSource={applianceCategories}
                loading={applianceCategoriesLoading}
                rowKey="id"
                pagination={{
                    pageSize,
                    total: applianceCategoriesCount,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    current: paging.offset / pageSize + 1
                }}
                style={{ marginBottom: 25 }}
                onChange={onTableChange}
            />
        </ApplianceCategoryTableStyle>
    );
};

export const ApplianceCategoryTable = compose<React.FunctionComponent<ApplianceCategoryTableProps>>(
    injectIntl,
    withRouter
)(ApplianceCategoryTableComponent);
