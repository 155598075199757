import { GraphQL } from "@graphql/graphQL";
import { DeleteSamplingsMutationVariables, Mutation, MutationResult } from "@models/graphql";
import { graphql, OperationOption } from "@apollo/react-hoc";

export interface DeleteSamplingsMutationResult {
    deleteSamplings(variables: DeleteSamplingsMutationVariables): Promise<MutationResult>;
}

export const createDeleteSamplingsMutation = <TProps = {}>(options?: OperationOption<TProps, Mutation, DeleteSamplingsMutationVariables, DeleteSamplingsMutationResult>) =>
    graphql<TProps, Mutation, DeleteSamplingsMutationVariables, DeleteSamplingsMutationResult>(GraphQL.deleteSamplings, {
        props({ mutate }): DeleteSamplingsMutationResult {

            if (!mutate) {
                throw new Error("No mutate found");
            }

            return {
                async deleteSamplings(variables: DeleteSamplingsMutationVariables): Promise<MutationResult> {
                    try {
                        const result = await mutate({
                            variables
                        });

                        if (!result || !result.data) {
                            throw new Error("Result was empty");
                        }

                        return result.data.deleteSamplings;
                    } catch (error) {
                        throw error;
                    }
                }
            };
        },
        ...options
    });
