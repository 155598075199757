import styled from "styled-components";
import { theme } from "@style/theme";

export const OperatorsStyle = styled.div`
    margin: 45px 128px 0px;
`;

export const OperatorHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;


`;

export const OperatorTitle = styled.h1`
    margin: 0px;
    color: ${theme.primary};
`;
