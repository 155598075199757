import styled from "styled-components";
import { theme } from "@style/theme";

export const OperatorsTableStyle = styled.div`
    margin-top: 36px;

    tr > td:last-of-type{
        width:100px;
    }
`;

export const Actions = styled.div`
    i{
        margin-right: 10px;
        color:${theme.text_grey};
        :hover{
            cursor: pointer;
        }
    }
`;
