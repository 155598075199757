import styled from "styled-components";
import { theme } from "@style/theme";

export const TransportsStyle = styled.div`
    display: flex;

    margin: 45px 128px;

    aside {
        width: 200px;
    }

    main {
        flex: 1;
    }
`;

export const TransportHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
`;

export const TransportCorrectionPercentage = styled.div`
    display: flex;
`;

export const Percentage = styled.div`
    font-weight: 500;
    margin-left: 10px;
`;

export const TransportTitle = styled.h1`
    margin: 0px;
    color: ${theme.primary};
`;
