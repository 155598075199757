import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

import { GetSamplesQuery, GetSamplesQueryVariables } from "@models/graphql";
import { GraphQL } from "@graphql/graphQL";

export const useGetSamplings = (opts?: QueryHookOptions<GetSamplesQuery, GetSamplesQueryVariables>) => {
    const { data, loading, error } = useQuery<GetSamplesQuery, GetSamplesQueryVariables>(GraphQL.getSamples, opts);

    return {
        samplings: data && data.samplings ? data.samplings.items : [],
        samplingsLoading: loading,
        samplingsError: error
    };
};
