import { Size } from "@models/graphql";
import { Select } from "antd";
import { FunctionComponent } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import * as React from "react";
import { StoreState } from "@redux/reducers/root";
import { connect } from "react-redux";
import { compose } from "redux";

export interface SizeSelectProps {
	value?: Size;
	loading?: boolean;
	style?: React.CSSProperties;
	disabled?: boolean;
	ref?: any;
	onChange?(value?: Size): void;
}

export const SizeSelectComponent: FunctionComponent<SizeSelectProps> = (props: SizeSelectProps & InjectedIntlProps & WithReduxStateProps) => {
	const { value, loading, disabled, intl, onChange, style } = props;

	return (
		<Select
			style={style}
			loading={loading}
			disabled={disabled}
			value={value}
			onChange={onChange}
			placeholder={intl.formatMessage({ id: "transport.details.size" })}
		>
			<Select.Option value={Size.Small}>{intl.formatMessage({ id: "transport.details.small" })}</Select.Option>
			<Select.Option value={Size.Big}>{intl.formatMessage({ id: "transport.details.big" })}</Select.Option>
		</Select>
	);
};

export const mapStateToProps = (state: StoreState) => ({
	language: state.main.selectedLanguage
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

const withRedux = connect(mapStateToProps);

export const SizeSelect = compose<FunctionComponent<SizeSelectProps>>(
	injectIntl,
	withRedux
)(SizeSelectComponent);
