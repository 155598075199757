
import { TableRow } from "@components/transports/transportTable/tableRow/tableRow";
import { ShipmentFragment } from "@models/graphql";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "redux";

export interface TableRowContainerProps {
    record: ShipmentFragment;
    transportId: number;
}

export const TableRowContainer = compose<React.ComponentClass<TableRowContainerProps>>(
    injectIntl,
    withRouter
)(TableRow);

export type TableRowProps =
    InjectedIntlProps &
    TableRowContainerProps &
    RouteComponentProps;
