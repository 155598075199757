import styled from "styled-components";
import { theme } from "@style/theme";
import { Checkbox } from "antd";

export const SamplingFilterStyle = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 40px 0 120px;

    .ant-select{
        margin-bottom: 16px;
    }

    .ant-slider-handle {
        border-color: ${theme.primary};
    }

    .ant-slider-track {
        background-color: ${theme.primary};
    }

    .ant-calendar-picker {
        margin-bottom: 12px;
    }

`;

export const FilterTitle = styled.h1`
    color: ${theme.primary};
`;

export const FilterSubTitle = styled.h2`
    font-size: 16px;
    margin:0;
`;

export const SamplingCheckbox = styled(Checkbox)`
    margin-bottom:30px !important;
`;
