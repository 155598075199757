import { theme } from "@style/theme";
import { Col, Icon } from "antd";
import styled from "styled-components";

export const TransportTableSubRow = styled.div`
    height: 48px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${theme.grey_light};
    cursor: ${(props: { hasSamplings: boolean }) => props.hasSamplings ? "pointer" : "auto"};

    &:last-of-type{
        border: none;
    }

    i{
        color:${theme.secondary};
    }
`;

export const TransportTableSubRowComplete = styled(TransportTableSubRow)`

    color: ${theme.text_grey};
    background-color: rgba(255,255,255, 0.25) !important;

    i{
        color:${theme.text_grey};
    }

    i:hover{
        color:${theme.secondary};
        cursor: pointer;
    }
`;

export const StatusProcessing = styled(Col)`

    color:#9DC647 !important;
`;

export const UnlockIcon = styled(Icon)`

    color:${theme.secondary};
`;
