import { Checkbox, Icon, Select } from "antd";
import styled from "styled-components";
import { ManualStyle } from "../samplingsTable/samplingsTableStyle";

export const SamplingsGridStyle = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 120px;
    margin-top: 16px;
    align-items: flex-end;
`;

export const GridWrapper = styled.div`
    display:flex;
    flex-wrap:wrap;
    flex-direction: row;


`;

export const SampleCheckBox = styled(Checkbox)`
    position: absolute;
    right: 20px;
    top: 20px;
    display: none !important;

`;

export const DeleteIcon = styled(Icon)`
    :hover{
        cursor: pointer;
    }
`;

export const Matching = styled.div`
    margin-bottom: 14px;
    color: #20C672;
`;

export const SampleCard = styled.div`
    display:flex;
    flex-direction: column;
    padding: 10px;
    border: solid 1px #E9E6E6;
    margin: 10px 15px 0 0;
    position: relative;
    width: calc(25% - 12px);

    &:nth-of-type(4n) {
        margin-right: 0;
    }

    :hover{
        cursor: pointer;
        background-color: rgba(0,0,0,0.05);

        ${SampleCheckBox}{
            display: inline-block !important;
        }
    }

    ${ManualStyle}, ${Matching} {
        margin: 12px 0;
    }
`;

export const SampleCardSelected = styled(SampleCard)`

    background-color: rgba(0,0,0,0.05);
    ${SampleCheckBox}{
        display: inline-block !important;
    }

`;

export const SampleImage = styled.img`
    margin-bottom: 12px;
    width: 100%;
    height: auto;
`;

export const DeviceSelect = styled(Select)`
    margin-bottom: 10px !important;
`;

export const IdAndAction = styled.div`
    display:flex;
    justify-content: space-between;
`;

export const Transport = styled.div`

`;
