import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQL } from "@graphql/graphQL";
import {
    UpdateIrConfigurationMutation,
    UpdateIrConfigurationMutationVariables
} from "@models/graphql";
import { ApolloError } from "apollo-client";
import { useState } from "react";

export const useUpdateIrConfiguration = (
    options?: MutationHookOptions<
        UpdateIrConfigurationMutation,
        UpdateIrConfigurationMutationVariables
    >
) => {
    const [hookState, setHookState] = useState<{
        isLoading: boolean;
        data?: UpdateIrConfigurationMutation;
        error?: ApolloError;
    }>({ isLoading: false });

    const [mutation] = useMutation<
        UpdateIrConfigurationMutation,
        UpdateIrConfigurationMutationVariables
    >(GraphQL.updateIrConfiguration, options);

    const updateIrConfiguration = async (
        variables: UpdateIrConfigurationMutationVariables
    ) => {
        setHookState({ isLoading: false });

        try {
            const response = await mutation({
                ...options,
                variables
            });

            setHookState({
                isLoading: false,
                data: response.data
            });

            return { data: response.data, error: response.errors };
        } catch (error) {
            setHookState({
                error,
                isLoading: false
            });
            return { error };
        }
    };

    return {
        isLoading: hookState.isLoading,
        error: hookState.error,
        data: hookState.data,
        updateIrConfiguration
    };
};
