
import { Action } from "../action";
import { ActionTypes } from "../types";

export const logout = (): LogoutAction => {
    return {
        type: ActionTypes.Logout
    };
};

export interface LogoutAction extends Action {

}

export const isLogoutAction = (action: Action): action is LogoutAction => action.type === ActionTypes.Logout;
