const actionPrefix = "FNS/";

export const ActionTypes = {
    OpenModal: `${actionPrefix}MAIN/OPEN_MODAL`,
    CloseModal: `${actionPrefix}MAIN/CLOSE_MODAL`,
    Login: `${actionPrefix}AUTH/LOGIN`,
    Logout: `${actionPrefix}AUTH/LOGOUT`,
    SetSamplingFilter: `${actionPrefix}/SET_SAMPLING_FILTER`,
    SetSamplingSort: `${actionPrefix}/SET_SAMPLING_SORT`,
    SetSelectedSamplingIds: `${actionPrefix}/SET_SELECTED_SAMPLING_IDS`,
    SetLanguage: `${actionPrefix}/SET_LANGUAGE`,
    SetPreviousPath: `${actionPrefix}/SET_PREVIOUS_PATH`
};
