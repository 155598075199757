import { getShipments } from "@graphql/hocs/hooks/getShipments";
import { Spin } from "antd";
import * as React from "react";
import { TableRowContainer } from "../tableRow/tableRowContainer";

interface ShipmentTableProps {
    transportId: number;
}

export const ShipmentTable: React.StatelessComponent<ShipmentTableProps> = (props: ShipmentTableProps) => {
    const { shipments, shipmentsError, shipmentsLoading } = getShipments({
        variables: {
            filter: {
                transportId: props.transportId
            }
        }
    });

    if (shipmentsLoading) {
        return <Spin />;
    }

    return <>
        {shipments.map(shipment => <TableRowContainer key={shipment.id} transportId={props.transportId} record={shipment} />)}
    </>;
};
