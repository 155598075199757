import styled from "styled-components";

export const ThumbnailLightboxStyle = styled.div`
    display: flex;
    flex-wrap: wrap;

    .media {
        padding: 0px 5px 10px 5px;
        cursor: pointer;
        transition: transform 0.2s;
        width: 33%;

        img {
            border-radius: 8px;
            overflow: hidden;
            width: 100%;
            display: block;
        }

        &:hover {
            transform: scale(1.01);
        }
    }
`;
