import { useGetApplianceList } from "@graphql/hocs/hooks/useGetApplianceList";
import { Icon, Spin, Tabs } from "antd";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { ApplianceCategoryTable } from "./applianceCategoryTable/applianceCategoryTable";
import { ApplianceListDetailPageStyle, SaveFirst } from "./applianceListDetailPageStyle";
import { ApplianceListForm } from "./applianceListForm/applianceListForm";
import { PeriodApplianceTable } from "./periodApplianceTable/periodApplianceTable";

export interface ApplianceListDetailPageProps {

}

export const ApplianceListDetailPageComponent: FunctionComponent<ApplianceListDetailPageProps> = (props: ApplianceListDetailPageProps & RouteComponentProps<{ id?: string; tab?: string }>) => {
    const { match, history } = props;

    let editId: number | undefined;

    if (match.params.id) {
        editId = parseInt(match.params.id, 10);
    }

    const { applianceList, applianceListLoading, applianceListError } = useGetApplianceList({
        variables: {
            // tslint:disable-next-line: no-non-null-assertion
            id: editId!
        },
        skip: !editId
    });

    const key = match.params.tab === "appliances" ? "appliances" : "categories";

    const onTabChange = (activeKey: string) => {
        const path = editId ? `/appliances/validity/update/${editId}` : `/appliances/validity/create`;

        if (activeKey === "categories") {

            history.replace(`${path}/categories`);
        } else {
            history.replace(`${path}/appliances`);
        }
    };

    return (
        <ApplianceListDetailPageStyle>
            <div className="bar">
                <Link to="/appliances/validity"> <Icon type="arrow-left" /> <FormattedMessage id="back" /></Link>
            </div>

            <ApplianceListForm applianceList={applianceList} />

            <Spin indicator={!editId ? <SaveFirst><FormattedMessage id="applianceList.saveFirst" /></SaveFirst> : undefined} spinning={!editId || applianceListLoading}>
                <Tabs onChange={onTabChange} animated={false} defaultActiveKey={key}>
                    <Tabs.TabPane key="categories" tab={<FormattedMessage id="applianceCategories" />}>
                        <ApplianceCategoryTable applianceListId={editId} />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="appliances" tab={<FormattedMessage id="assignAppliances" />}>
                        <PeriodApplianceTable applianceListId={editId} />
                    </Tabs.TabPane>
                </Tabs>
            </Spin>
        </ApplianceListDetailPageStyle>
    );
};

export const ApplianceListDetailPage = compose(
    withRouter
)(ApplianceListDetailPageComponent);
