import styled from "styled-components";
import { ApplianceFormStyle } from "../applianceForm/applianceFormStyle";

export const ApplianceListDetailPageStyle = styled(ApplianceFormStyle)`
    .bar {
        margin-bottom: 25px;
    }
`;

export const SaveFirst = styled.div`
    width: 300px;
    transform: translateX(-50%);
`;
