import { GraphQL } from "@graphql/graphQL";
import { Mutation, Sampling, UpdateSamplingsMutationVariables } from "@models/graphql";
import { graphql, OperationOption } from "@apollo/react-hoc";

export interface UpdateSamplingsMutationResult {
    updateSamplings(variables: UpdateSamplingsMutationVariables): Promise<Sampling[]>;
}

export const createUpdateSamplingsMutation = <TProps = {}>(options?: OperationOption<TProps, Mutation, UpdateSamplingsMutationVariables, UpdateSamplingsMutationResult>) =>
    graphql<TProps, Mutation, UpdateSamplingsMutationVariables, UpdateSamplingsMutationResult>(GraphQL.updateSamplings, {
        props({ mutate }): UpdateSamplingsMutationResult {
            if (!mutate) {
                throw new Error("No mutate found");
            }

            return {
                async updateSamplings(variables: UpdateSamplingsMutationVariables): Promise<Sampling[]> {
                    try {
                        const result = await mutate({
                            variables
                        });

                        if (!result || !result.data) {
                            throw new Error("Result was empty");
                        }

                        return result.data.updateSamplings;
                    } catch (error) {
                        throw error;
                    }
                }
            };
        },
        ...options
    });
