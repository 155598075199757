import styled from "styled-components";

export const AppliancesTableStyle = styled.div`
    padding: 25px 0;

    .ant-input-search {
        margin-bottom: 30px;
    }

    .bar {
        display: flex;

        .ant-btn {
            margin-left: 15px;
        }
    }

`;

export const Buttons = styled.span`
    justify-content: flex-end;
    width: 100%;
    display: inline-flex;

    .ant-btn:not(:first-of-type) {
        margin-left: 15px;
    }
`;
