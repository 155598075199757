import { SortOrder } from "@models/graphql";
import { Action } from "@redux/actions/action";
import { ActionTypes } from "@redux/actions/types";

export interface SetSamplingSortActionOptions {
    samplingSort: {
        column: string;
        direction: SortOrder;
    } | null;
}

export const setSamplingSort = (data: SetSamplingSortActionOptions): SetSamplingSortAction => {
    return {
        type: ActionTypes.SetSamplingSort,
        ...data
    };
};

export type SetSamplingSortAction = Action & SetSamplingSortActionOptions;
export const isSetSamplingSortAction = (action: Action): action is SetSamplingSortAction => action.type === ActionTypes.SetSamplingSort;
