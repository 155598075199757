import * as React from "react";
import { FunctionComponent } from "react";
import { ErrorStyle } from "./errorStyle";
import { ApolloError } from "apollo-client";
import { Icon } from "antd";

export interface ErrorProps {
    errorMessage: string;
    originalError?: ApolloError;
}

export const Error: FunctionComponent<ErrorProps> = ({ errorMessage, originalError }) => {

    console.warn(originalError);

    return (
        <ErrorStyle>
            <Icon type="exclamation-circle" />
            {errorMessage}

            {originalError && originalError.message ? <pre>{originalError.message}</pre> : undefined}

        </ErrorStyle>
    );
};
