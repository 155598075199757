import { theme } from "@style/theme";
import { Icon } from "antd";
import styled from "styled-components";

export const SamplingsTableStyle = styled.div`
    margin-right: 120px;
`;

export const SampleImage = styled.img`
    max-height: 50px;
    vertical-align: middle;
    margin-right: 10px;
    cursor: pointer;
`;

export const Transport = styled.div`
    /* display: flex;
    align-items: center; */
`;

export const DeleteIcon = styled(Icon)`
    :hover{
        cursor: pointer;
    }
`;

export const Action = styled.div`
    display: flex;
`;

export const ManualStyle = styled.span`
    color: ${theme.grey_light};
`;
