
import { Operators } from "@pages/operators/operators";
import * as React from "react";
import { compose } from "redux";
import { withRouter, RouteComponentProps } from "react-router";

export interface OperatorsContainerProps {

}

export const OperatorsContainer = compose<React.ComponentClass<OperatorsContainerProps>>(
    withRouter
)(Operators);

export type OperatorsProps =
    OperatorsContainerProps &
    RouteComponentProps<{
        page: string;
    }>;
