import { useEffect } from "react";
import { useLocation, useNavigate, useParams, useRoute } from "./routerHook";

type Route = string;

export const usePersistedURLSearchParams = <SearchParams extends {}>(
    routes: Route[],
    fixedRoute?: string
): Pick<URLSearchParams, "get" | "set" | "delete"> => {
    const location = useLocation();
    const route = fixedRoute ? fixedRoute : useRoute<SearchParams>();
    const navigate = useNavigate();

    const onStorageChange = (event: StorageEvent): void => {
        if (event.key && routes.includes(event.key) && routes.includes(route)) {
            navigate(location.pathname);
        }
    };

    const set: URLSearchParams["set"] = (name, value) => {
        const persistedSearchParams =
            localStorage.getItem(routes[0]) || undefined;
        const searchParams = new URLSearchParams(persistedSearchParams);

        if (value !== "" && value !== undefined) {
            searchParams.set(name, value);
        } else {
            searchParams.delete(name);
        }

        localStorage.setItem(route, `?${searchParams.toString()}`);

        navigate(location.pathname);
    };

    const get: URLSearchParams["get"] = name => {
        const persistedSearchParams =
            localStorage.getItem(routes[0]) || undefined;
        const searchParams = new URLSearchParams(persistedSearchParams);

        return searchParams.get(name);
    };

    const _delete: URLSearchParams["delete"] = name => {
        const persistedSearchParams =
            localStorage.getItem(routes[0]) || undefined;
        const searchParams = new URLSearchParams(persistedSearchParams);
        searchParams.delete(name);

        routes.forEach(route => {
            localStorage.setItem(route, `?${searchParams.toString()}`);
        });

        navigate(location.pathname);
    };

    useEffect(() => {
        if (routes.includes(route)) {
            const searchParams = new URLSearchParams(location.search);
            searchParams.forEach((value, key) => {
                if (!value) {
                    searchParams.delete(key);
                }
            });
            const searchString =
                searchParams.toString() !== ""
                    ? `?${searchParams.toString()}`
                    : "";
            localStorage.setItem(route, searchString);
        }
    }, [location]);

    useEffect(() => {
        window.addEventListener("storage", onStorageChange);

        return () => {
            window.removeEventListener("storage", onStorageChange);
        };
    }, [route]);

    return {
        get,
        set,
        delete: _delete
    };
};
