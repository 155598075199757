import { Action } from "@redux/actions/action";
import { ActionTypes } from "@redux/actions/types";

export interface SetLanguageActionOptions {
    language: string;
}

export const setLanguage = (data: SetLanguageActionOptions): SetLanguageAction => {
    return {
        type: ActionTypes.SetLanguage,
        ...data
    };
};

export type SetLanguageAction = Action & SetLanguageActionOptions;
export const isSetLanguageAction = (action: Action): action is SetLanguageAction => action.type === ActionTypes.SetLanguage;
