import { ApplianceCategorySelect } from "@components/applianceCategorySelect/applianceCategorySelect";
import { ApplianceSelect } from "@components/applianceSelect/applianceSelect";
import { useCreatePeriodAppliance } from "@graphql/hocs/hooks/useCreatePeriodAppliance";
import { parseError } from "@utils/parseError";
import { Button, Form, message } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { NormalizedCache } from "apollo-cache-inmemory";
import * as React from "react";
import { FunctionComponent } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "redux";
import { PeriodApplianceFormStyle } from "./periodApplianceFormStyle";

export interface PeriodApplianceFormProps {
    applianceListId?: number;
}

export const PeriodApplianceFormComponent: FunctionComponent<PeriodApplianceFormProps> = (props: PeriodApplianceFormProps & FormComponentProps & InjectedIntlProps) => {
    const { form, intl, applianceListId } = props;
    const client = useApolloClient();

    const { createPeriodAppliance, isLoading } = useCreatePeriodAppliance({
        async update(cache) {
            const data: NormalizedCache = (cache as any).data;
            Object.keys((data as any).data).forEach(key => key.match(/^Appliance/) && data.delete(key));
            Object.keys((data as any).data).forEach(key => key.match(/^PeriodAppliance/) && data.delete(key));
            Object.keys((data as any).data).forEach(key => key.match(/^\$ROOT_QUERY\.periodAppliances/) && data.delete(key));
            Object.keys((data as any).data).forEach(key => key.match(/^\$ROOT_QUERY\.appliances/) && data.delete(key));

            await client.reFetchObservableQueries();
        }
    });

    const submit = () => {
        if (!applianceListId) {
            return;
        }

        form.validateFields(async (errors, values) => {
            if (!errors) {
                const { data, error } = await createPeriodAppliance({
                    input: {
                        applianceCategoryId: values.category.id,
                        applianceId: values.appliance,
                        applianceListId: applianceListId
                    }
                });

                if (data && data.createPeriodAppliance) {
                    form.resetFields();
                    message.success(intl.formatMessage({ id: "periodApplianceForm.created" }));
                }

                if (error) {
                    message.error(parseError(error, intl));
                }
            }
        });
    };

    return (
        <PeriodApplianceFormStyle >

            <Form>
                <Form.Item>
                    {form.getFieldDecorator("appliance", {
                        rules: [{ required: true, message: intl.formatMessage({ id: "requiredField" }) }]
                    })(
                        <ApplianceSelect notInApplianceListId={applianceListId} />
                    )}
                </Form.Item>

                <Form.Item>
                    {form.getFieldDecorator("category", {
                        rules: [{ required: true, message: intl.formatMessage({ id: "requiredField" }) }]
                    })(
                        <ApplianceCategorySelect isControlled applianceListId={applianceListId} />
                    )}
                </Form.Item>

                <Button loading={isLoading} onClick={submit} type="primary">
                    Product toevoegen
                </Button>
            </Form>
        </PeriodApplianceFormStyle>
    );
};

export const PeriodApplianceForm = compose<React.FunctionComponent<PeriodApplianceFormProps>>(
    Form.create(),
    injectIntl
)(PeriodApplianceFormComponent);
