import { useCreateApplianceCategory } from "@graphql/hocs/hooks/useCreateApplianceCategory";
import { useUpdateApplianceCategory } from "@graphql/hocs/hooks/useUpdateApplianceCategory";
import { GetApplianceCategoriesQuery } from "@models/graphql";
import { parseError } from "@utils/parseError";
import { Alert, Button, Form, Input, message } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { NormalizedCache } from "apollo-cache-inmemory";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "redux";
import { ApplianceCategoryFormStyle } from "./applianceCategoryFormStyle";

export interface ApplianceCategoryFormProps {
    applianceCategory: GetApplianceCategoriesQuery["applianceCategories"]["items"][0] | null;
    applianceListId?: number;
    editSaveCallback(): void;
    createSaveCallback(): void;
}

export const ApplianceCategoryFormComponent: FunctionComponent<ApplianceCategoryFormProps> = (props: ApplianceCategoryFormProps & FormComponentProps & InjectedIntlProps) => {
    const { form, intl, applianceCategory, editSaveCallback, applianceListId, createSaveCallback } = props;

    const { createApplianceCategory, isLoading: isCreateLoading, error: createError, clearErrors: clearCreateErrors } = useCreateApplianceCategory({
        update(cache) {
            const data: NormalizedCache = (cache as any).data;

            Object.keys((data as any).data).forEach(key => key.match(/^ApplianceCategory/) && data.delete(key));
            Object.keys((data as any).data).forEach(key => key.match(/^\$ROOT_QUERY\.applianceCategories/) && data.delete(key));

            createSaveCallback();
        }
    });
    const { updateApplianceCategory, isLoading: isUpdateLoading, error: updateError, clearErrors: clearUpdateErrors } = useUpdateApplianceCategory();

    const submit = () => {
        if (!applianceListId) {
            message.error("Er ging iets mis, ververs de pagina en probeer het opnieuw.");

            return;
        }

        form.validateFields(async (errors, values) => {
            if (!errors) {

                if (applianceCategory) {
                    const { data } = await updateApplianceCategory({
                        input: {
                            name: values.name,
                            applianceListId
                        },
                        id: applianceCategory.id
                    });

                    if (data && data.updateApplianceCategory) {
                        message.info(intl.formatMessage({ id: "applianceCategoryForm.updated" }));
                        form.resetFields();
                        editSaveCallback();
                    }
                } else {
                    const { data } = await createApplianceCategory({
                        input: {
                            name: values.name,
                            applianceListId
                        }
                    });

                    if (data && data.createApplianceCategory) {
                        message.info(intl.formatMessage({ id: "applianceCategoryForm.created" }));
                        form.resetFields();
                        editSaveCallback();
                    }
                }

            }
        });
    };

    const cancel = () => {
        editSaveCallback();
        clearCreateErrors();
        clearUpdateErrors();
    };

    return (
        <ApplianceCategoryFormStyle>
            {updateError && <Alert style={{ marginTop: 15 }} type="error" message={parseError(updateError, intl)} />}
            {createError && <Alert style={{ marginTop: 15 }} type="error" message={parseError(createError, intl)} />}
            <Form>
                <Form.Item>
                    {form.getFieldDecorator("name", {
                        rules: [{ required: true, message: intl.formatMessage({ id: "requiredField" }) }],
                        initialValue: applianceCategory && applianceCategory.name
                    })(
                        <Input placeholder={intl.formatMessage({ id: "applianceCategoryForm.name" })} style={{ width: 300 }} />
                    )}
                </Form.Item>
                <Button loading={isCreateLoading || isUpdateLoading} onClick={submit} type="primary" >
                    + &nbsp; <FormattedMessage id={applianceCategory ? "applianceCategoryForm.update" : "applianceCategoryForm.create"} />
                </Button>
                {applianceCategory && <Button onClick={cancel} style={{ marginLeft: 10 }}>
                    <FormattedMessage id="cancel" />
                </Button>}
            </Form>
        </ApplianceCategoryFormStyle>
    );
};

export const ApplianceCategoryForm = compose<React.FunctionComponent<ApplianceCategoryFormProps>>(
    Form.create(),
    injectIntl
)(ApplianceCategoryFormComponent);
