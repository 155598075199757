import { ApolloProvider } from "@apollo/react-hoc";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import { AuthenticationProvider, OidcSecure } from "@axa-fr/react-oidc-context";
import * as OfflinePluginRuntime from "offline-plugin/runtime";
import * as React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { oidcConfiguration } from "@utils/oidcConfig";
import { setupStore } from "@redux/setupStore";
import { client } from "@graphql/client";
import { LoadingPage } from "@components/loadingPage/loadingPage";
import { ConnectedIntlProvider } from "@components/connectedIntlProvider/connectedIntlProvider";

import { App } from "./app";
import { InjectIntlContext } from "./utils/intlHook";
import { PermissionProvider } from "@components/permission/permissionProvider";
import { initErrorTracking } from "@utils/errorTracking";

OfflinePluginRuntime.install({
    onUpdateReady: () => {
        OfflinePluginRuntime.applyUpdate();
    },
    onUpdated: () => {
        window.location.reload();
    }
});

initErrorTracking();

const store = setupStore({});

const reactContainer = document.getElementById("app-container");

render(
    <AuthenticationProvider
        notAuthenticated={props => JSON.stringify(props)}
        notAuthorized={props => JSON.stringify(props)}
        callbackComponentOverride={LoadingPage}
        authenticating={LoadingPage}
        configuration={oidcConfiguration}
    >

        <Provider store={store}>
            <ConnectedIntlProvider>
                <InjectIntlContext>
                    <ApolloProvider client={client}>
                        <ApolloHooksProvider client={client}>
                            <BrowserRouter>
                                <PermissionProvider>
                                    <OidcSecure>
                                        <App />
                                    </OidcSecure>
                                </PermissionProvider>
                            </BrowserRouter>
                        </ApolloHooksProvider>
                    </ApolloProvider>
                </InjectIntlContext>
            </ConnectedIntlProvider>
        </Provider>
    </AuthenticationProvider>,
    reactContainer
);
