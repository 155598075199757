import { GraphQL } from "@graphql/graphQL";
import { GetPeriodAppliancesQuery, GetPeriodAppliancesQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetPeriodAppliances = (opts?: QueryHookOptions<GetPeriodAppliancesQuery, GetPeriodAppliancesQueryVariables>) => {
    const { data, loading, error, refetch } = useQuery<GetPeriodAppliancesQuery, GetPeriodAppliancesQueryVariables>(GraphQL.getPeriodAppliances, opts);

    return {
        periodAppliances: data && data.periodAppliances ? data.periodAppliances.items : [],
        periodAppliancesCount: data && data.periodAppliances ? data.periodAppliances.count : 0,
        periodAppliancesLoading: loading,
        periodAppliancesError: error,
        refetch
    };
};
