import * as React from "react";
import {
    ApplianceSettingsStyle,
    SettingsHeader
} from "@components/appliances/appliancesSettings/applianceSettingsStyle";
import { Alert, Button, Form, InputNumber, message } from "antd";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "redux";
import { withRouter } from "react-router";
import { FormComponentProps } from "antd/lib/form";
import { useGetIrConfigurations } from "@graphql/hocs/hooks/useGetIrConfigurations";
import { useCreateIrConfiguration } from "@graphql/hocs/hooks/useCreateIrConfiguration";
import { useUpdateIrConfiguration } from "@graphql/hocs/hooks/useUpdateIrConfiguration";
import { parseError } from "@utils/parseError";
import { Spinner } from "@components/spinner/spinner";
import { useDeleteIrConfiguration } from "@graphql/hocs/hooks/useDeleteIrConfiguration";

export interface ApplianceSettingsProps {}

export const ApplianceSettingsComponent: React.FunctionComponent<
    ApplianceSettingsProps
> = (
    props: ApplianceSettingsProps & InjectedIntlProps & FormComponentProps
) => {
    const { form, intl } = props;

    const {
        irConfigurations,
        irConfigurationsLoading,
        irConfigurationsError
    } = useGetIrConfigurations();

    const {
        createIrConfiguration,
        isLoading: isCreateLoading,
        error: createError
    } = useCreateIrConfiguration();
    const {
        updateIrConfiguration,
        isLoading: isUpdateLoading,
        error: updateError
    } = useUpdateIrConfiguration();
    const {
        deleteIrConfiguration,
        isLoading: isDeleteLoading,
        error: deleteError
    } = useDeleteIrConfiguration({ refetchQueries: ["getIrConfigurations"] });

    const submit = async () => {
        form.validateFields(async (errors, values) => {
            if (!errors) {
                const config =
                    irConfigurations &&
                    irConfigurations.items.length > 0 &&
                    irConfigurations.items[0];
                if (config) {
                    const minProbabilityForApproval =
                        values.minProbabilityForApproval / 100;
                    const { data } = await updateIrConfiguration({
                        id: config.id,
                        input: {
                            minProbabilityForApproval: minProbabilityForApproval
                        }
                    });
                    if (data && data.updateIrConfiguration) {
                        message.info(
                            intl.formatMessage({
                                id: "appliances.successMessage"
                            })
                        );
                    }
                } else {
                    const minProbabilityForApproval =
                        values.minProbabilityForApproval / 100;
                    const { data } = await createIrConfiguration({
                        input: {
                            minProbabilityForApproval: minProbabilityForApproval
                        }
                    });

                    if (data && data.createIrConfiguration) {
                        message.info(
                            intl.formatMessage({
                                id: "appliances.successMessage"
                            })
                        );
                    }
                }
            }
        });
    };

    const onDelete = async (configurationIr: any) => {
        if (configurationIr && configurationIr.items.length !== 0) {
            const { data } = await deleteIrConfiguration({
                id: configurationIr.items[0].id
            });
            if (data && data.deleteIrConfiguration) {
                message.info(
                    intl.formatMessage({
                        id: "appliances.successDeleteMessage"
                    })
                );
            }
        }
    };

    return (
        <ApplianceSettingsStyle>
            <SettingsHeader>
                <FormattedMessage id="appliances.settings" />
            </SettingsHeader>
            {irConfigurationsError && (
                <Alert
                    style={{ marginTop: 15 }}
                    type="error"
                    message={parseError(irConfigurationsError, intl)}
                />
            )}
            {createError && (
                <Alert
                    style={{ marginTop: 15 }}
                    type="error"
                    message={parseError(createError, intl)}
                />
            )}
            {updateError && (
                <Alert
                    style={{ marginTop: 15 }}
                    type="error"
                    message={parseError(updateError, intl)}
                />
            )}
            {deleteError && (
                <Alert
                    style={{ marginTop: 15 }}
                    type="error"
                    message={parseError(deleteError, intl)}
                />
            )}
            {irConfigurationsLoading && <Spinner long />}
            {!irConfigurationsLoading && (
                <Form>
                    <Form.Item
                        label={intl.formatMessage({
                            id: "appliances.percentageFieldLabel"
                        })}
                    >
                        {form.getFieldDecorator("minProbabilityForApproval", {
                            rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: "requiredField"
                                    })
                                },
                                {
                                    pattern: /^[0-9][0-9]?$|^100$/,
                                    message: intl.formatMessage({
                                        id: "appliances.minMaxError"
                                    })
                                }
                            ],
                            initialValue:
                                irConfigurations &&
                                irConfigurations.items.length > 0
                                    ? irConfigurations.items[0]
                                          .minProbabilityForApproval * 100
                                    : undefined
                        })(<InputNumber style={{ width: "250px" }} />)}
                    </Form.Item>
                    <Button
                        loading={isCreateLoading || isUpdateLoading}
                        onClick={submit}
                        type="primary"
                    >
                        <FormattedMessage id="save" />
                    </Button>
                    <Button
                        style={{ marginLeft: "20px" }}
                        loading={isDeleteLoading}
                        onClick={() => onDelete(irConfigurations)}
                    >
                        <FormattedMessage id="appliances.deleteButton" />
                    </Button>
                </Form>
            )}
        </ApplianceSettingsStyle>
    );
};

export const ApplianceSettings = compose(
    injectIntl,
    withRouter,
    Form.create()
)(ApplianceSettingsComponent);
