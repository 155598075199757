import { theme } from "@style/theme";
import { Menu } from "antd";
import styled from "styled-components";

export const HeaderStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 1px 2px  rgba(0,0,0,0.25);
    height: 90px;
`;

export const Logo = styled.img`
    max-height: 60px;
    margin: 0 90px 0 110px;
`;

export const NavMenu = styled(Menu)`
    height: 90px;

    .ant-menu-item{
        height: 90px;
        line-height: 90px;
    }
`;

export const RightAligned = styled.div`
    margin-right: 120px;

    .ant-select {
        margin-right: 15px;
    }

   & > span {
        margin-right: 20px;
    }

    & > span.logout {
        color:${theme.text_grey};
        cursor: pointer;
        transition: .2s;

        &:hover {
            color: ${theme.text};
        }
    }
`;

export const LeftAligned = styled.div`
    display: flex;
    align-items: center;
`;
