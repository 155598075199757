import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { GraphQL } from "@graphql/graphQL";
import {
    CreateIrConfigurationMutation,
    CreateIrConfigurationMutationVariables
} from "@models/graphql";
import { ApolloError } from "apollo-client";
import { useState } from "react";

const defaultCreateIrConfiguration: MutationHookOptions<
    CreateIrConfigurationMutation,
    CreateIrConfigurationMutationVariables
> = {};

export const useCreateIrConfiguration = (
    options?: MutationHookOptions<
        CreateIrConfigurationMutation,
        CreateIrConfigurationMutationVariables
    >
) => {
    const [hookState, setHookState] = useState<{
        isLoading: boolean;
        data?: CreateIrConfigurationMutation;
        error?: ApolloError;
    }>({ isLoading: false });

    const [mutation] = useMutation<
        CreateIrConfigurationMutation,
        CreateIrConfigurationMutationVariables
    >(GraphQL.createIrConfiguration, options);

    const createIrConfiguration = async (
        variables: CreateIrConfigurationMutationVariables
    ) => {
        setHookState({ isLoading: true });

        try {
            const response = await mutation({
                ...defaultCreateIrConfiguration,
                ...options,
                variables
            });
            setHookState({
                data: response.data,
                isLoading: false
            });
            return { data: response.data, error: response.errors };
        } catch (error) {
            setHookState({
                error,
                isLoading: false
            });
            return { error };
        }
    };

    return {
        isLoading: hookState.isLoading,
        error: hookState.error,
        data: hookState.data,
        createIrConfiguration
    };
};
