import { useCreateApplianceList } from "@graphql/hocs/hooks/useCreateApplianceList";
import { useUpdateApplianceList } from "@graphql/hocs/hooks/useUpdateApplianceList";
import { GetApplianceListQuery } from "@models/graphql";
import { parseError } from "@utils/parseError";
import { Alert, Button, DatePicker, Form, Input, message } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { NormalizedCache } from "apollo-cache-inmemory";
import Maybe from "graphql/tsutils/Maybe";
import * as moment from "moment";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "redux";
import { ApplianceListFormStyle } from "./applianceListFormStyle";

export interface ApplianceListFormProps {
    applianceList: Maybe<GetApplianceListQuery["applianceList"]>;
}

export const ApplianceListFormComponent: FunctionComponent<ApplianceListFormProps> = (props: ApplianceListFormProps & FormComponentProps & InjectedIntlProps & RouteComponentProps) => {
    const { form, intl, applianceList, history } = props;

    const { createApplianceList, isLoading: isCreateLoading, error: createError } = useCreateApplianceList({
        update(cache) {
            const data: NormalizedCache = (cache as any).data;

            Object.keys((data as any).data).forEach(key => key.match(/^ApplianceList/) && data.delete(key));
            Object.keys((data as any).data).forEach(key => key.match(/^\$ROOT_QUERY\.applianceLists/) && data.delete(key));
        }
    });
    const { updateApplianceList, isLoading: isUpdateLoading, error: updateError } = useUpdateApplianceList();

    const saveApplianceList = () => {
        form.validateFields(async (errors, values) => {
            if (!errors) {
                if (!applianceList) {
                    const { data } = await createApplianceList({
                        input: {
                            activeFrom: values.activeFrom.toISOString(),
                            activeTo: values.activeTo.toISOString(),
                            title: values.name
                        }
                    });

                    if (data && data.createApplianceList) {
                        history.replace(`/appliances/validity/update/${data.createApplianceList.id}`);
                        message.info(intl.formatMessage({ id: "applianceListForm.created" }));
                    }
                } else {
                    const { data } = await updateApplianceList({
                        input: {
                            activeFrom: values.activeFrom.toISOString(),
                            activeTo: values.activeTo.toISOString(),
                            title: values.name
                        },
                        id: applianceList.id
                    });

                    if (data && data.updateApplianceList) {
                        message.info(intl.formatMessage({ id: "applianceListForm.updated" }));
                    }
                }
            }
        });
    };

    const activeToValidator = (_, value: moment.Moment | undefined, callback: (error?: string) => void) => {
        const fromValue: moment.Moment | undefined = form.getFieldValue("activeFrom");

        if (fromValue && value && fromValue.isSameOrAfter(value, "d")) {
            callback(intl.formatMessage({ id: "applianceListForm.error.beforeFrom" }));
        } else {
            callback();
        }
    };

    return (
        <ApplianceListFormStyle>
            {updateError && <Alert style={{ marginTop: 15 }} type="error" message={parseError(updateError, intl)} />}
            {createError && <Alert style={{ marginTop: 15 }} type="error" message={parseError(createError, intl)} />}
            <Form>
                <Form.Item label={intl.formatMessage({ id: "applianceListForm.name" })}>
                    {form.getFieldDecorator("name", {
                        rules: [{ required: true, message: intl.formatMessage({ id: "requiredField" }) }],
                        initialValue: applianceList && applianceList.name
                    })(
                        <Input placeholder={intl.formatMessage({ id: "applianceListForm.name" })} style={{ width: 300 }} />
                    )}
                </Form.Item>
                <Form.Item label={intl.formatMessage({ id: "applianceListForm.activeFrom" })}>
                    {form.getFieldDecorator("activeFrom", {
                        rules: [{ required: true, message: intl.formatMessage({ id: "requiredField" }) }],
                        initialValue: applianceList && moment(applianceList.activeFrom)
                    })(
                        <DatePicker format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                    )}
                </Form.Item>
                <Form.Item label={intl.formatMessage({ id: "applianceListForm.activeTo" })}>
                    {form.getFieldDecorator("activeTo", {
                        rules: [
                            { required: true, message: intl.formatMessage({ id: "requiredField" }) },
                            { validator: activeToValidator }
                        ],
                        initialValue: applianceList && moment(applianceList.activeTo)
                    })(
                        <DatePicker format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                    )}
                </Form.Item>
                <Button loading={isCreateLoading || isUpdateLoading} onClick={saveApplianceList} type="primary">
                    <FormattedMessage id="save" />
                </Button>
            </Form>
        </ApplianceListFormStyle>
    );
};

export const ApplianceListForm = compose<React.FunctionComponent<ApplianceListFormProps>>(
    Form.create(),
    injectIntl,
    withRouter
)(ApplianceListFormComponent);
