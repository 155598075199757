import { theme } from "@style/theme";
import styled from "styled-components";

export const SamplingDetailStyle = styled.div`
    margin: 40px 128px;

    .ant-checkbox-group{
        display:flex;
        flex-direction: column;
    }

    .ant-checkbox-group-item{
        margin-bottom: 10px;
    }

    .ant-radio-group{
        margin-bottom: 16px;
    }

    .ant-checkbox-wrapper {
        display: inline-flex;
    }


    .ant-select {
        width: 100%;
    }

    main {
        display: flex;
    }

    .history {
        h1 {
            font-size: 18px;
            margin-top: 15px;
            margin-bottom: 15px;
            font-weight: 500;
        }
        .timestamp {
            font-weight: 500;
            font-size: 12px;
            margin-right: 10px;
        }

        b {
            font-weight: 500;
        }

        em {
            font-weight: 500;
        }
    }
`;

export const MissingSampling = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;

    img {
        margin-top: 200px;
        width: 100px;
        opacity: 0.1;
        margin-bottom: 50px;
    }
`;

export const Nav = styled.div`
    display:flex;
    align-items: center;
    color:${theme.secondary};
    width: 100px;

    :hover{
        cursor: pointer;
    }

    i {
        margin-right: 16px;
    }
`;

export const Weight = styled.div`

`;

export const Title = styled.h1`
    margin: 0;
    color:${theme.primary};
    font-size: 1em;
`;

export const Header = styled.div`
    display:flex;
    align-items: center;
    justify-content: space-between;
    color:${theme.secondary};

    border-bottom: 1px solid ${theme.grey_light};
    padding: 10px 0;
    margin: 20px 0;
`;

export const Images = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom:20px;
`;

export const Image = styled.img`
    max-height: 400px;
    cursor: pointer;
    flex: 1;
`;

export const SubTitle = styled.h2`
    color:${theme.primary};
    font-size: 1em;
    margin-bottom: 16px !important;
`;

export const Filter = styled.div`
    font-weight: bold;
    font-size: 1.2rem;

    span {
        font-weight: normal;

        &:not(:first-of-type) {
            margin-left: 25px;
        }
    }
`;

export const Info = styled.div`
    display:flex;
    flex-direction: row;
    align-items: center;
`;

export const BottomNav = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    .ant-btn {
        height: 40px;
    }

    .anticon-arrow-left {
        margin-right: 30px;
        display: inline-block;
    }

    .anticon-arrow-right {
        margin-left: 30px !important;
        display: inline-block;
    }
`;

export const InfoBlock = styled.div`
    border: 1px solid ${theme.grey_light};
    border-radius: 5px;

    width: 400px;
    flex-shrink: 0;
    margin-right: 20px;

    .loading {
        padding: 15px;
        opacity: 0.6;

        .anticon {
            margin-right: 15px;
        }
    }

    .confirm-btn {
        width: 100%;
        margin-top: 10px;
    }

    .block {
        padding: 15px 30px 15px 30px;
        border-top: 1px solid ${theme.grey_light};

        &:last-of-type {
            padding-bottom: 30px;
        }

        &:first-of-type {
            padding-top: 30px;
            border-top: none;
        }
    }


    ${SubTitle} {
        font-weight: 500 !important;
    }
`;

export const Date = styled.div`
    color:${theme.text} !important;
    padding-right: 10px !important;

    span:first-of-type{
        font-weight:500;
        margin-right: 5px;
        ::after{
            content: ":";
        }
    }
`;

export const ApplianceSuggestions = styled.div`
    display: flex;
    flex-direction: column;

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin: 10px 0 0 0;
    }
    margin-bottom: 5px;
`;

export const Operator = styled.div`
    color:${theme.text} !important;
    span:first-of-type{
        font-weight:500;
        margin-right: 5px;
        ::after{
            content: ":";
        }
    }
`;
