import { Action } from "@redux/actions/action";
import { ActionTypes } from "@redux/actions/types";

export interface SetPreviousPathActionOptions {
    path: string | undefined;
}

export const setPreviousPath = (data: SetPreviousPathActionOptions): SetPreviousPathAction => {
    return {
        type: ActionTypes.SetPreviousPath,
        ...data
    };
};

export type SetPreviousPathAction = Action & SetPreviousPathActionOptions;
export const isSetPreviousPathAction = (action: Action): action is SetPreviousPathAction => action.type === ActionTypes.SetPreviousPath;
