
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"transport"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Transport"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"transportNumber"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"processingFraction"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"status"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"estimatedArrivalDate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"shipmentType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"percentageEnteredManually"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"percentageApproved"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"site"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":183}};
    doc.loc.source = {"body":"fragment transport on Transport {\n  id\n  transportNumber\n  processingFraction\n  status\n  estimatedArrivalDate\n  shipmentType\n  percentageEnteredManually\n  percentageApproved\n  site\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
