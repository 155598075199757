import styled from "styled-components";

export const ErrorStyle = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 20px;

    .icon {
        color: red;
        font-size: 80px;
        margin-bottom: 40px;
    }

    pre {
        opacity: 0.4;
        margin-top: 80px;
        font-size: 14px;
    }
`;
