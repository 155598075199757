import { Reducer } from "redux";

import { isSetSelectedSamplingIdsAction } from "@redux/actions/setSelectedSamplingIds";
import { isSetPreviousPathAction } from "@redux/actions/setPreviousPath";
import { isSetLanguageAction } from "@redux/actions/setLanguage";
import { Action } from "@redux/actions/action";

const INITIAL_STATE: MainReducerState = {
    selectedSamplingIds: [],
    selectedLanguage: localStorage.getItem("language") || "nl"
};

export interface MainReducerState {

    previousPath?: string;
    selectedSamplingIds: number[];
    selectedLanguage: string;
}

export const mainReducer: Reducer<MainReducerState> = (state: MainReducerState = INITIAL_STATE, action: Action) => {

    if (isSetSelectedSamplingIdsAction(action)) {
        return {
            ...state,
            selectedSamplingIds: action.ids
        };
    }

    if (isSetLanguageAction(action)) {
        localStorage.setItem("language", action.language);

        return {
            ...state,
            selectedLanguage: action.language
        };
    }

    if (isSetPreviousPathAction(action)) {
        return {
            ...state,
            previousPath: action.path
        };
    }

    return state;
};
