import { useGetProfile } from "@graphql/hocs/hooks/useGetProfile";
import * as React from "react";
import { createContext, FunctionComponent, useContext, useState } from "react";
import { ApolloError } from "apollo-client";

export enum Role {
    REVIEWER = "REVIEWER",
    ADMIN = "ADMIN"
}

export interface PermissionContext {
    roles: Set<Role>;
    rolesLoaded: boolean;
    rolesError?: ApolloError;
    setRoles(roles: Set<Role>): void;
    hasRoles(requiredRoles: string[]): boolean;
}

const PermissionContext = createContext<PermissionContext>({
    roles: new Set(),
    rolesLoaded: false,
    setRoles: () => { },
    hasRoles: () => false
});

export const PermissionProvider: FunctionComponent = ({ children }) => {
    const [rolesLoaded, setRolesLoaded] = useState(false);
    const [roles, setRoles] = useState<Set<Role>>(new Set());
    const { profile, profileError } = useGetProfile();

    React.useEffect(
        () => {
            if (profile) {
                const newRoles = new Set<Role>([Role.REVIEWER]);
                if (profile.groups.some(g => g === "d9cf6534-b253-4626-9176-762ff234678c")) {
                    newRoles.add(Role.ADMIN);
                }
                setRoles(newRoles);
            } else {
                setRoles(new Set());
            }

            if (profile || profileError) {
                setRolesLoaded(true);
            }

        },
        [profile, profileError, setRolesLoaded]
    );

    const hasRoles = (requiredRoles: Role[]): boolean => requiredRoles.every(p => roles.has(p));

    return <PermissionContext.Provider
        value={{
            roles,
            setRoles,
            hasRoles,
            rolesLoaded,
            rolesError: profileError
        }}
    >{children}</PermissionContext.Provider>;
};

export const usePermissions = (): PermissionContext => useContext(PermissionContext);

export function withPermissions(Component) {
    return (props) => {
        return (
            <PermissionContext.Consumer>
                {state => <Component {...props} {...state} />}
            </PermissionContext.Consumer>
        );
    };
}
