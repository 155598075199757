import { GraphQL } from "@graphql/graphQL";
import { GetOperatorsQueryVariables, Operator, Query } from "@models/graphql";
import { ApolloError } from "apollo-client";
import { graphql, OperationOption } from "@apollo/react-hoc";

export interface GetOperatorsQueryResult {
    operators: Operator[];
    operatorsCount: number;
    operatorsLoading: boolean;
    operatorsError?: ApolloError;
}

export const createGetOperatorsQuery = <TProps = {}>(options?: OperationOption<TProps, Query, GetOperatorsQueryVariables, GetOperatorsQueryResult>) =>
    graphql<TProps, Query, GetOperatorsQueryVariables, GetOperatorsQueryResult>(GraphQL.getOperators, {
        props(optionProps) {
            const { data } = optionProps;
            if (!data) {
                throw new Error("No data");
            }

            if (data.error) {
                console.error(data.error);
            }

            return {
                operators: data && data.operators ? data.operators.items : [],
                operatorsCount: data && data.operators ? data.operators.count : 0,
                operatorsLoading: data.loading,
                operatorsError: data.error
            };
        },
        ...options
    });
