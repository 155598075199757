import styled from "styled-components";

export const PeriodApplianceFormStyle = styled.div`

    .ant-form {
        display: flex;
        align-items: flex-end;
    }

    .ant-select {
        width: 250px !important;
        margin-right: 15px;
    }

    .ant-btn {
        margin-bottom: 28px;
    }
`;
