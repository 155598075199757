import { getAppliances } from "@graphql/hocs/hooks/getAppliances";
import { GetAppliancesQuery, SortOrder } from "@models/graphql";
import { arrayEquals } from "@utils/arrayEquals";
import { getText } from "@utils/getText";
import { Select, Spin } from "antd";
import { SelectProps } from "antd/lib/select";
import Maybe from "graphql/tsutils/Maybe";
import * as React from "react";
import { FunctionComponent } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "redux";
import { useDebounce } from "use-debounce";
import { StoreState } from "@redux/reducers/root";
import { connect } from "react-redux";
import { useGetApplianceLists } from "@graphql/hocs/hooks/useGetApplianceLists";
import { useGetPeriodAppliances } from "@graphql/hocs/hooks/useGetPeriodAppliances";

import * as moment from "moment";

export interface ApplianceSelectProps {
    showActiveAt?: string;
    value?: Maybe<number[] | number>;
    initialAppliance?: any;
    notInApplianceListId?: number;
    filterOutApplianceIds?: number[];
    selectOptions?: SelectProps;
    style?: React.CSSProperties;
    loading?: boolean;
    disabled?: boolean;
    ref?: any;
    onChange?(val: number[] | number | undefined | null): void;
}

export const ApplianceSelectComponent: FunctionComponent<ApplianceSelectProps> = (props: ApplianceSelectProps & InjectedIntlProps & WithReduxStateProps) => {
    const [searchValue, setSearchValue] = React.useState("");

    const { intl, selectOptions, style, language, notInApplianceListId, filterOutApplianceIds, value, loading, disabled, initialAppliance } = props;

    const { appliances: allAppliances, appliancesLoading } = getAppliances({
        variables: {
            filter: {
                notInApplianceListId
            }
        },
        skip: Boolean(props.showActiveAt)
    });

    const { applianceLists, applianceListsLoading } = useGetApplianceLists({
        skip: !Boolean(props.showActiveAt)
    });

    const validApplianceList = React.useMemo(
        () => {
            if (applianceLists) {
                return applianceLists.find(al => moment(props.showActiveAt).isBetween(moment(al.activeFrom), moment(al.activeTo)));
            }
        },
        [applianceLists]
    );

    const { periodAppliances, periodAppliancesLoading } = useGetPeriodAppliances({
        variables: {
            filter: {
                applianceListId: validApplianceList && validApplianceList.id
            }
        },
        skip: !Boolean(props.showActiveAt) || applianceListsLoading || !validApplianceList
    });

    const appliances = React.useMemo(
        () => {
            if (props.showActiveAt) {
                return periodAppliances.map(pa => pa.appliance);
            }

            return allAppliances;
        },
        [appliancesLoading, periodAppliancesLoading]
    );

    const handleChange = (val: Maybe<number | number[]>) => {
        if (props.onChange) {
            props.onChange(val);
        }
    };

    return (
        <Select
            loading={appliancesLoading || loading || applianceListsLoading || periodAppliancesLoading}
            showSearch
            style={style}
            filterOption={false}
            value={value as any}
            onSearch={setSearchValue}
            onChange={handleChange as any}
            placeholder={intl.formatMessage({ id: "applianceSelect.placeholde" })}
            optionLabelProp="children"
            disabled={disabled}
            allowClear={false}
            {...selectOptions}
        >
            {
                appliances && appliances.length > 0
                    ? appliances
                        .filter(app => {
                            const match = app.name.some(name => name.language === language && name.value.toLowerCase().includes(searchValue.toLowerCase()));

                            if (!filterOutApplianceIds) {
                                return match;
                            }

                            return filterOutApplianceIds.some(id => app.id !== id) && match;
                        })
                        .sort((a) => {
                            if (!value) {
                                return 1;
                            }

                            if (typeof value === "number") {
                                if (a.id === value) {
                                    return -1;
                                }
                            } else {
                                if (value.some(v => v === a.id)) {
                                    return -1;
                                }
                            }

                            return 1;
                        })
                        .slice(0, 15)
                        .map((a) => {
                            return <Select.Option key={a.id} value={a.id}>{getText(a.name, language)}</Select.Option>;
                        })
                    : initialAppliance && <Select.Option key={initialAppliance.id} value={initialAppliance.id}>{getText(initialAppliance.name, language)}</Select.Option>
            }

            {appliancesLoading && <Select.Option value=""><Spin /></Select.Option>}
        </Select>

    );
};

export const mapStateToProps = (state: StoreState) => ({
    language: state.main.selectedLanguage
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

const withRedux = connect(mapStateToProps);

export const ApplianceSelect = compose<React.FunctionComponent<ApplianceSelectProps>>(
    injectIntl,
    withRedux
)(ApplianceSelectComponent);
