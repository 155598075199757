import { GraphQL } from "@graphql/graphQL";
import { GetShipmentsQuery, GetShipmentsQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const getShipments = (opts?: QueryHookOptions<GetShipmentsQuery, GetShipmentsQueryVariables>) => {
    const { data, loading, error } = useQuery<GetShipmentsQuery, GetShipmentsQueryVariables>(GraphQL.getShipments, opts);

    return {
        shipments: data && data.shipments ? data.shipments.items : [],
        shipmentsLoading: loading,
        shipmentsError: error
    };
};
