import { getAppliances } from "@graphql/hocs/hooks/getAppliances";
import { GetAppliancesQuery, SortOrder } from "@models/graphql";
import { getText } from "@utils/getText";
import { parseError } from "@utils/parseError";
import { Alert, Button, Icon, Input, Table } from "antd";
import { ColumnProps, PaginationConfig } from "antd/lib/table";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "redux";
import { useDebounce } from "use-debounce";
import { AppliancesTableStyle, Buttons } from "./appliancesTableStyle";
import { StoreState } from "@redux/reducers/root";
import { connect } from "react-redux";

export interface AppliancesTableProps {

}

export const AppliancesTableComponent: FunctionComponent<AppliancesTableProps> = (props: AppliancesTableProps & InjectedIntlProps & RouteComponentProps & WithReduxStateProps) => {
    const { intl, history, language } = props;

    const [pageSize, setPageSize] = React.useState(10);
    const [searchValue, setSearchValue] = React.useState("");
    const [debounceSearchValue] = useDebounce(searchValue, 400);
    const [paging, setPaging] = React.useState({ limit: 10, offset: 0 });

    const { appliances, appliancesCount, appliancesLoading, appliancesError } = getAppliances({
        variables: {
            paging,
            sort: [
                {
                    field: "translation.name",
                    order: SortOrder.Desc
                }
            ],
            search: {
                language,
                value: debounceSearchValue
            }
        }
    });

    const columns: ColumnProps<GetAppliancesQuery["appliances"]["items"][0]>[] = [
        {
            key: "titleNl",
            title: intl.formatMessage({ id: "appliances.titleNl" }),
            render(_, record) {
                return getText(record.name, "nl");
            }
        },
        {
            key: "titleFr",
            title: intl.formatMessage({ id: "appliances.titleFr" }),
            render(_, record) {
                return getText(record.name, "fr");
            }
        },
        {
            key: "editdelete",
            title: "",
            render(_, record) {

                return <Buttons>
                    <Button onClick={() => history.push(`/appliances/update/${record.id}`)} shape="circle"><Icon type="edit" /></Button>
                </Buttons>;
            }
        }
    ];

    const onTableChange = (pagination: PaginationConfig) => {
        if (pagination.current && pagination.pageSize) {
            setPaging({
                limit: pagination.pageSize,
                offset: (pagination.current - 1) * pagination.pageSize
            });

            setPageSize(pagination.pageSize);
        }
    };

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        setPaging({
            limit: pageSize,
            offset: 0
        });
    };

    const createAppliance = () => {
        history.push("/appliances/create");
    };

    return (
        <AppliancesTableStyle>
            <div className="bar">
                <Input.Search
                    value={searchValue}
                    onChange={onSearch}
                    placeholder={intl.formatMessage({ id: "appliances.search" })}
                />
                <Button onClick={createAppliance} type="primary" >
                    + &nbsp; <FormattedMessage id="appliances.create" />
                </Button>
            </div>

            {appliancesError && <Alert style={{ marginBottom: 15 }} type="error" message={parseError(appliancesError, intl)} />}
            <Table
                columns={columns}
                dataSource={appliances}
                loading={appliancesLoading}
                rowKey="id"
                pagination={{
                    pageSize,
                    total: appliancesCount,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    current: paging.offset / pageSize + 1
                }}
                onChange={onTableChange}
            />
        </AppliancesTableStyle>
    );
};

export const mapStateToProps = (state: StoreState) => ({
    language: state.main.selectedLanguage
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

const withRedux = connect(mapStateToProps);

export const AppliancesTable = compose(
    injectIntl,
    withRouter,
    withRedux
)(AppliancesTableComponent);
