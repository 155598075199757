import styled from "styled-components";

export const ApplianceListFormStyle = styled.div`
    .ant-form {
        display: flex;
        align-items: flex-end;
    }

    .ant-btn {
        margin-bottom: 28px;
    }

    .ant-form-item {
        margin-right: 15px;
    }
`;
