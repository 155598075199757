import {
    Button,
    Checkbox,
    Icon,
    Radio,
    Select,
    Spin,
    Timeline,
    message,
    Popconfirm
} from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import RadioGroup from "antd/lib/radio/group";
import Maybe from "graphql/tsutils/Maybe";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { isApplianceHistory, isBrandHistory, isSizeHistory, isApprovedHistory } from "@utils/samplingHistory";
import { parseError } from "@utils/parseError";
import { getText } from "@utils/getText";
import {
    ApplianceSuggestions,
    BottomNav,
    Date,
    Filter,
    Header,
    Info,
    InfoBlock,
    MissingSampling,
    Nav,
    Operator,
    SamplingDetailStyle,
    SubTitle,
    Title,
    Weight
} from "@pages/samplingDetail/samplingDetailStyle";
import { SamplingDetailProps } from "@pages/samplingDetail/samplingDetailContainer";
import {
    ApplianceFragment,
    Size,
    UpdateSamplingInput,
    GetSamplesQuery,
    GetSamplesQueryVariables,
    SamplingsFilterInput
} from "@models/graphql";
import { useGetBrands } from "@graphql/hocs/hooks/useGetBrands";
import { useGetSamplings } from "@graphql/hocs/hooks/useGetSamplings";
import { ThumbnailLightboxContainer } from "@components/thumbnailLightbox/thumbnailLightboxContainer";
import { ApplianceSelect } from "@components/applianceSelect/applianceSelect";

import { Probability } from "./probability/probability";
import { useUpdateSamplings } from "@graphql/hocs/hooks/useUpdateSampling";
import { useDeleteSamplings } from "@graphql/hocs/hooks/useDeleteSampling";
import { NormalizedCache } from "apollo-cache-inmemory";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { User } from "oidc-client";
import { Permission } from "@components/permission/permission";
import {
    usePermissions,
    Role
} from "@components/permission/permissionProvider";
import { GraphQL } from "@graphql/graphQL";
import { useGetSampleGalleryInfos } from "@graphql/hocs/hooks/useGetSampleGalleryInfos";
import { useNavigate } from "@utils/routerHook";
import { calculatedStateFilter } from "@components/samplings/samplingsTable/useSamplingsTableFilter";

// tslint:disable-next-line: cyclomatic-complexity
export const SamplingDetail: React.FunctionComponent<SamplingDetailProps> = ({
    filter,
    sampleId,
    intl,
    language,
    previousPath
}) => {
    const [selectedCategoryId, setSelectedCategoryId] = React.useState<
        number | null
    >(null);
    const [selectedBrandId, setSelectedBrandId] = React.useState<number | null>(
        null
    );
    const [
        selectedScaleType,
        setSelectedScaleType
    ] = React.useState<Size | null>(null);
    const [samplingId, setSamplingId] = React.useState(sampleId);
    const navigateTo = useNavigate({ replace: true });

    const minimumMatchingPercentage = filter.get("minimumMatchingPercentage");
    const maximumMatchingPercentage = filter.get("maximumMatchingPercentage");

    const queryVariables: GetSamplesQueryVariables = {
        filter: {
            ids: [sampleId],
            applianceIds: filter.get("applianceIds"),
            shipmentIds: filter.get("shipmentId")
                ? [filter.get("shipmentId") as number]
                : null,
            transportIds: filter.get("transportId")
                ? [filter.get("transportId") as number]
                : null,
            manualEntry:
                filter.get("assignedBy") === "system"
                    ? false
                    : filter.get("assignedBy") === "both"
                    ? null
                    : true,
            minimumWeight: filter.get("minimumWeight")
                ? filter.get("minimumWeight")
                : null,
            maximumWeight: filter.get("maximumWeight")
                ? filter.get("maximumWeight")
                : null,
            minimumMatchingPercentage: minimumMatchingPercentage
                ? minimumMatchingPercentage / 100
                : null,
            maximumMatchingPercentage: maximumMatchingPercentage
                ? maximumMatchingPercentage / 100
                : null,
            applianceCategoryIds: filter.get("applianceCategoryIds") || null,
            sizes: filter.get("size") ? [filter.get("size") as any] : null,
            brandIds: filter.get("brandIds") || null,
            ...calculatedStateFilter(filter.get("approved"))
        },
        sort: [
            {
                field: filter.get("sortField"),
                order: filter.get("sortOrder")
            }
        ]
    };

    const { updateSamplings, isLoading } = useUpdateSamplings({
        async update(cache) {
            const samplingsData = cache.readQuery<GetSamplesQuery>({
                query: GraphQL.getSamples,
                variables: queryVariables
            });

            if (!samplingsData) {
                return;
            }

            cache.writeQuery<GetSamplesQuery>({
                data: samplingsData,
                query: GraphQL.getSamples
            });
        }
    });
    const { deleteSamplings, isLoading: deleteLoading } = useDeleteSamplings({
        async update(cache) {
            const data: NormalizedCache = (cache as any).data;

            Object.keys((data as any).data).forEach(
                key => key.match(/^Sampling/) && data.delete(key)
            );
            Object.keys((data as any).data).forEach(
                key => key.match(/^\$ROOT_QUERY\.samplings/) && data.delete(key)
            );
        }
    });

    const { samplings, samplingsError, samplingsLoading } = useGetSamplings({
        variables: queryVariables
    });
    const samplingDetail = useGetSamplings({
        variables: { filter: { ids: [sampleId] } },
        skip: samplingsLoading || samplings.length > 0
    });

    const skipSampleGallery = () => {
        const newFilter: SamplingsFilterInput = { ...queryVariables.filter };
        delete newFilter.ids;
        return Object.values(newFilter).every(
            e => e === null || e === undefined
        );
    };

    const firstDayOfTheYear = moment().startOf("year");
    const fromDate = filter.get("fromTimeSampled");
    const { sampleGalleryInfos } = useGetSampleGalleryInfos({
        variables: {
            ...queryVariables,
            filter: {
                ...queryVariables.filter,
                fromTimeSampled: firstDayOfTheYear.isSame(fromDate)
                    ? null
                    : filter.get("fromTimeSampled").toISOString(),
                toTimeSampled:
                    filter.get("toTimeSampled") !== undefined
                        ? moment(filter.get("toTimeSampled")).toISOString()
                        : null
            }
        },
        skip: skipSampleGallery()
    });

    const { oidcUser }: { oidcUser: User } = useReactOidc();
    const { brands } = useGetBrands();
    const { hasRoles } = usePermissions();
    const sampling = samplings[0] ? samplings[0] : samplingDetail.samplings[0];
    const galleryInfo =
        sampleGalleryInfos &&
        sampleGalleryInfos[0] &&
        sampleGalleryInfos[0].galleryInfo;

    React.useEffect(() => {
        if (sampling) {
            if (sampling.appliance) {
                setSelectedCategoryId(sampling.appliance.id);
            } else {
                setSelectedCategoryId(null);
            }

            if (sampling.brand) {
                setSelectedBrandId(sampling.brand.id);
            } else {
                setSelectedBrandId(null);
            }

            setSelectedScaleType(sampling.size);
        }
    }, [sampling]);

    React.useEffect(() => {
        if (!sampling) {
            return;
        }
        if (samplingId !== sampling.id) {
            setSamplingId(sampling.id);
        }
    }, [sampling, galleryInfo, samplingId]);

    const canChange = React.useMemo(() => {
        if (!sampling || hasRoles([Role.ADMIN]) || !sampling.manualEntry) {
            return {
                canChangeSize: true,
                canChangeBrand: true,
                canChangeAppliance: true
            };
        }

        let canChangeSize = true;
        let canChangeBrand = true;
        let canChangeAppliance = true;

        sampling.history.forEach(s => {
            if (s.user && s.user.id !== oidcUser.profile.oid) {
                if (isApplianceHistory(s)) {
                    canChangeAppliance = false;
                }

                if (isSizeHistory(s)) {
                    canChangeSize = false;
                }

                if (isBrandHistory(s)) {
                    canChangeBrand = false;
                }
            }
        });

        return {
            canChangeSize,
            canChangeBrand,
            canChangeAppliance
        };
    }, [sampling, oidcUser]);

    if (samplingsLoading) {
        return <Spin size="large" style={{ marginTop: 50 }} />;
    }

    if (!sampling && samplingsError) {
        return <FormattedMessage id={parseError(samplingsError, intl)} />;
    }

    if (!sampling) {
        return (
            <SamplingDetailStyle>
                <MissingSampling>
                    <img
                        src={require("@assets/images/not-found.svg")}
                        alt="not found"
                    />
                    <FormattedMessage id="sampling.notFound" />
                </MissingSampling>
            </SamplingDetailStyle>
        );
    }

    const { applianceSuggestions, appliance } = sampling;

    const onBack = () => {
        navigateTo(previousPath || "/samplings");
    };

    const navigateToSample = (sampleId?: number | null) => {
        if (sampleId) {
            navigateTo(`/samplings/${sampleId}`);
        }
    };

    const navigate = (type: "previous" | "next") => {
        if (!galleryInfo) {
            return;
        }

        if (type === "next") {
            navigateToSample(galleryInfo.nextId);
        }

        if (type === "previous") {
            navigateToSample(galleryInfo.previousId);
        }
    };

    const handleUpdate = async (input: UpdateSamplingInput) => {
        try {
            const response = await updateSamplings({
                ids: [sampling.id],
                input
            });

            if (response) {
                message.success(
                    intl.formatMessage({
                        id: "updateSampling.succesfullyUpdated"
                    })
                );
            } else {
                message.error(intl.formatMessage({ id: "error.unknown" }));
            }
        } catch (err) {
            message.error(intl.formatMessage({ id: "error.unknown" }));
        }
    };

    const handleCategoryChange = (
        value: number[] | number | undefined | null
    ) => {
        if (!value) {
            setSelectedCategoryId(null);

            return;
        }

        if (typeof value === "number") {
            setSelectedCategoryId(value);
            handleUpdate({
                approved: true,
                applianceId: value
            });
        }
    };

    const handleScaleTypeChane = (e: RadioChangeEvent) => {
        setSelectedScaleType(e.target.value);
        handleUpdate({
            size: e.target.value
        });
    };

    const handleBrandChange = (val: number) => {
        setSelectedBrandId(val);
        handleUpdate({
            brandId: val
        });
    };

    const handleConfirmAppliance = () => {
        if (!sampling.appliance) {
            return;
        }

        handleUpdate({
            approved: true
        });
    };

    const handleDelete = async () => {
        try {
            await deleteSamplings({
                ids: [sampling.id]
            });

            message.success(
                intl.formatMessage({ id: "deleteSampling.succes" })
            );
            navigateTo("/samplings");
        } catch (err) {
            message.error(intl.formatMessage({ id: "error.unknown" }));
        }
    };

    const renderSelect = () => {
        let selectValue: Maybe<ApplianceFragment> = appliance;

        if (
            appliance &&
            applianceSuggestions.some(app => app.appliance.id === appliance.id)
        ) {
            selectValue = undefined;
        }

        if (!sampling) {
            // prevents rendering the select before the sample is loaded
            return;
        }

        return (
            <ApplianceSelect
                style={{ width: 305 }}
                showActiveAt={sampling.timeSampled}
                onChange={handleCategoryChange}
                value={selectValue ? selectValue.id : undefined}
                initialAppliance={sampling.appliance}
                disabled={!canChange.canChangeAppliance}
                selectOptions={{
                    placeholder: intl.formatMessage({
                        id: "transport.details.categorie.placeholder"
                    }),
                    allowClear: false
                }}
            />
        );
    };

    const checkFilter = (): boolean => {
        const localFilter = localStorage.getItem("/samplings");
        const splitFilter: string[] = localFilter
            ? localFilter.slice(1).split("&")
            : [];
        const filterObject: { [value: string]: string }[] = splitFilter.map(
            value => {
                const split = value.split("=");
                return { [split[0]]: split[1] };
            }
        );
        if (Array.isArray(filterObject)) {
            return !filterObject.some(value => {
                if (Object.keys(value)[0] === "fromTimeSampled") {
                    const fromDate = moment(filter.get("fromTimeSampled"));
                    return fromDate.date() !== 1 && fromDate.month() !== 1;
                }
                return !["page", "viewMode"].includes(Object.keys(value)[0]);
            });
        }
        return true;
    };

    const renderNavigation = (): JSX.Element | null => {
        if (!galleryInfo || checkFilter()) {
            return null;
        }

        let currentPlace: string | JSX.Element = `${
            galleryInfo.currentPlace
        } / ${galleryInfo.totalItems}`;

        if (galleryInfo.currentPlace === 0 && galleryInfo.totalItems === 0) {
            currentPlace = <FormattedMessage id="samplingOutsideFilter" />;
        }

        return (
            <BottomNav>
                <Button
                    type="default"
                    disabled={!galleryInfo.previousId}
                    onClick={() => navigate("previous")}
                >
                    <Icon type="arrow-left" />
                    <FormattedMessage id="previous" />
                </Button>
                {currentPlace}
                <Button
                    type="default"
                    disabled={!galleryInfo.nextId}
                    onClick={() => navigate("next")}
                >
                    <FormattedMessage id="next" />
                    <Icon type="arrow-right" />
                </Button>
            </BottomNav>
        );
    };

    let customCheckboxValue = false;

    if (
        !applianceSuggestions.some(
            app => app.appliance.id === selectedCategoryId
        )
    ) {
        customCheckboxValue = true;
    }

    return (
        <SamplingDetailStyle>
            <Nav onClick={onBack}>
                <Icon type="arrow-left" />
                <Title>
                    <FormattedMessage id="back" />
                </Title>
            </Nav>
            <Header>
                <Filter>
                    <FormattedMessage id="transport" />:{" "}
                    {sampling.transport.transportNumber}
                    <FormattedMessage id="shipment" />:{" "}
                    {sampling.shipment.shipmentNumber}
                    {sampling.appliance && (
                        <>
                            <FormattedMessage id="fraction" />:{" "}
                            {sampling.appliance.processingFraction}
                        </>
                    )}
                </Filter>
                <Info>
                    <Date>
                        <FormattedMessage id="date" />
                        {moment(sampling.timeSampled).format(
                            "DD/MM/YYYY HH:mm"
                        )}
                    </Date>
                    <Operator>
                        <FormattedMessage id="operator" />
                        {`${sampling.operator.firstName} ${
                            sampling.operator.lastName
                        }`}
                    </Operator>
                </Info>
            </Header>

            {/* {localStorage.getItem("/samplings/:sampleId") && renderNavigation()} */}
            {renderNavigation()}
            <main>
                <InfoBlock>
                    {isLoading && (
                        <div className="loading">
                            <Icon type="loading" />
                            <FormattedMessage id="updateSaving" />
                        </div>
                    )}
                    <div className="block">
                        <SubTitle>
                            <FormattedMessage id="transport.details.categorie" />
                        </SubTitle>
                        <ApplianceSuggestions>
                            {sampling.applianceSuggestions
                                .sort((a, b) =>
                                    a.probability > b.probability ? -1 : 1
                                )
                                .map(app => (
                                    <Checkbox
                                        key={app.appliance.id}
                                        checked={
                                            selectedCategoryId ===
                                            app.appliance.id
                                        }
                                        onChange={e =>
                                            handleCategoryChange(
                                                app.appliance.id
                                            )
                                        }
                                        disabled={!canChange.canChangeAppliance}
                                    >
                                        <Probability value={app.probability} />
                                        {getText(app.appliance.name, language)}
                                    </Checkbox>
                                ))}
                        </ApplianceSuggestions>
                        <Checkbox
                            disabled={!canChange.canChangeAppliance}
                            key="custom"
                            checked={
                                customCheckboxValue && !!sampling.appliance
                            }
                            onChange={() => handleCategoryChange(null)}
                        >
                            {renderSelect()}
                        </Checkbox>
                        {!(
                            sampling.manualEntry ||
                            sampling.systemApproved ||
                            sampling.approved
                        ) &&
                            sampling.appliance && (
                                <Button
                                    loading={isLoading}
                                    onClick={handleConfirmAppliance}
                                    className="confirm-btn"
                                >
                                    <FormattedMessage id="confirmAppliance" />
                                </Button>
                            )}
                    </div>
                    <div className="block">
                        <SubTitle>
                            <FormattedMessage id="transport.details.size" />
                        </SubTitle>
                        <RadioGroup
                            disabled={!canChange.canChangeSize}
                            onChange={handleScaleTypeChane}
                            value={selectedScaleType}
                        >
                            <Radio value={Size.Small}>
                                <FormattedMessage id="transport.details.small" />
                            </Radio>
                            <Radio value={Size.Big}>
                                <FormattedMessage id="transport.details.big" />
                            </Radio>
                        </RadioGroup>
                    </div>
                    <div className="block">
                        <SubTitle>
                            <FormattedMessage id="sampling.details.brand" />
                        </SubTitle>
                        <Select
                            value={selectedBrandId || undefined}
                            onChange={handleBrandChange}
                            placeholder={intl.formatMessage({
                                id: "sampling.details.brand"
                            })}
                            showSearch
                            disabled={!canChange.canChangeBrand}
                            filterOption={(v, el) =>
                                (el.props.children as string)
                                    .toLowerCase()
                                    .includes(v.toLowerCase().trim())
                            }
                        >
                            {brands.map(b => (
                                <Select.Option key={b.id} value={b.id}>
                                    {b.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div className="block">
                        <SubTitle>
                            <FormattedMessage id="weight" />
                        </SubTitle>
                        <Weight>{sampling.weight}Kg</Weight>
                    </div>
                    <Permission>
                        <div className="block">
                            <SubTitle>
                                <FormattedMessage id="actions" />
                            </SubTitle>
                            <Popconfirm
                                onConfirm={handleDelete}
                                title={intl.formatMessage({
                                    id: "deleteSampling.confirm"
                                })}
                            >
                                <Button loading={deleteLoading} type="danger">
                                    <Icon type="delete" />
                                    <FormattedMessage id="deleteSampling" />
                                </Button>
                            </Popconfirm>
                        </div>
                    </Permission>
                </InfoBlock>

                <section>
                    <ThumbnailLightboxContainer images={sampling.images} />
                    <div className="history">
                        <FormattedMessage id="sampleHistory" tagName="h1" />
                        <Timeline>
                            {sampling.history.map((h, index) => {
                                const user = h.user ? h.user.name : "SYSTEM";

                                if (isBrandHistory(h)) {
                                    return (
                                        <Timeline.Item
                                            key={index}
                                            color="green"
                                        >
                                            <span className="timestamp">
                                                {moment(h.timestamp).format(
                                                    "DD MMM YYYY  HH:mm:ss "
                                                )}
                                            </span>
                                            —&nbsp;
                                            <FormattedMessage id="brandChange" />{" "}
                                            <b>{h.newBrand.name}</b>{" "}
                                            <FormattedMessage id="by" />{" "}
                                            <em>{user}</em>
                                        </Timeline.Item>
                                    );
                                }

                                if (isSizeHistory(h)) {
                                    return (
                                        <Timeline.Item key={index}>
                                            <span className="timestamp">
                                                {moment(h.timestamp).format(
                                                    "DD MMM YYYY  HH:mm:ss "
                                                )}
                                            </span>
                                            —&nbsp;
                                            <FormattedMessage id="sizeChanged" />{" "}
                                            <FormattedMessage
                                                tagName="b"
                                                id={`size.${h.newSize}`}
                                            />{" "}
                                            <FormattedMessage id="by" />{" "}
                                            <em>{user}</em>
                                        </Timeline.Item>
                                    );
                                }

                                if (isApplianceHistory(h)) {
                                    return (
                                        <Timeline.Item
                                            key={index}
                                            color={"orange"}
                                        >
                                            <span className="timestamp">
                                                {moment(h.timestamp).format(
                                                    "DD MMM YYYY  HH:mm:ss "
                                                )}
                                            </span>
                                            —&nbsp;
                                            <FormattedMessage id="applianceChanged" />{" "}
                                            <b>
                                                {getText(
                                                    h.newAppliance.name,
                                                    language
                                                ).toLowerCase()}
                                            </b>{" "}
                                            <FormattedMessage id="by" />{" "}
                                            <em>{user}</em>
                                        </Timeline.Item>
                                    );
                                }

                                if (isApprovedHistory(h)) {
                                    return <Timeline.Item key={index} >
                                        <span className="timestamp">
                                            {moment(h.timestamp).format("DD MMM YYYY  HH:mm:ss ")}
                                        </span>
                                        —&nbsp;
                                        <FormattedMessage id="approvedChanged" /> <FormattedMessage id="by" /> <em>{user}</em>
                                    </Timeline.Item>;
                                }
                            })}
                        </Timeline>
                    </div>
                </section>
            </main>
        </SamplingDetailStyle>
    );
};
