import * as React from "react";
import { hot } from "react-hot-loader";
import { Redirect, Route, Switch } from "react-router";

import { Transports } from "@pages/transports/transports";
import { SamplingDetailWrapper } from "@pages/samplingDetail/samplingDetailWrapper";
import { OperatorsContainer } from "@pages/operators/operatorsContainer";
import { MainContainer } from "@components/main/mainContainer";
import { JwtInfo } from "@components/jwtInfo/jwtInfo";
import { AppliancesPage } from "@components/appliances/appliancesPage/appliancesPage";
import { usePermissions } from "@components/permission/permissionProvider";
import { LoadingPage } from "@components/loadingPage/loadingPage";
import { Error } from "@components/error/error";
import { Samplings } from "@pages/samplings/samplings";

export interface AppProps {

}

const AppComponent: React.StatelessComponent<AppProps> = (props: AppProps) => {
    const { rolesLoaded, rolesError } = usePermissions();

    if (!rolesLoaded) {
        return <LoadingPage />;
    }

    if (rolesError) {
        return <div style={{ marginTop: 100 }}>
            <Error originalError={rolesError} errorMessage="Oeps, er is een probleem met het ophalen van je rechten." />
        </div>;
    }

    return (
        <Switch>
            <Route exact path="/jwt" component={JwtInfo} />
            <Route path="*" component={(routeProps) => {
                return (
                    <MainContainer {...routeProps} >
                        <Switch>
                            <Redirect from="/" to="/transports" exact />
                            <Route exact path="/transports" component={Transports} />

                            <Route exact path="/samplings/:sampleId" component={SamplingDetailWrapper} />
                            <Route exact path="/samplings" component={Samplings} />

                            <Redirect exact from="/operators" to="/operators/p/1" />
                            <Route exact path="/operators/p/:page" component={OperatorsContainer} />

                            <Route path="/appliances" component={AppliancesPage} />
                        </Switch>
                    </MainContainer>
                );
            }} />
        </Switch>
    );
};

export const App = hot(module)(AppComponent);
