import styled from "styled-components";

export const ProbabilityStyle = styled.span`
    font-weight: bold;
    display: inline-block;
    width: 60px;
`;

export const HighProbability = styled(ProbabilityStyle)`
    color: #3DD28E;
`;

export const MediumProbability = styled(ProbabilityStyle)`
    color: #FFC23F;
`;

export const LowProbability = styled(ProbabilityStyle)`
    color: #DE7474;
`;
