import { SamplingFilterContainer } from "@components/samplings/samplingFilter/samplingFilterContainer";
import { SelectedDevicesContainer } from "@components/samplings/selectedDevices/selectedDevicesContainer";
import { SamplingsStyle, ViewIcon, ViewModeStyle, Wrapper } from "@pages/samplings/samplingsStyle";
import * as React from "react";
import { SamplingsDataWrapper } from "@components/samplings/samplingsDataWrapper/samplingsDataWrapper";
import { useSamplingsTableFilter } from "@components/samplings/samplingsTable/useSamplingsTableFilter";

export enum ViewMode {
    List = "list",
    Grid = "grid"
}

export const Samplings: React.FunctionComponent = () => {
    const filter = useSamplingsTableFilter();
    const viewMode: ViewMode = filter.get("viewMode") === "grid" ? ViewMode.Grid : ViewMode.List;

    return (
        <SamplingsStyle>
            <aside>
                <SamplingFilterContainer />
            </aside>
            <main>
                <SelectedDevicesContainer />
                <Wrapper>
                    <ViewModeStyle>
                        <ViewIcon
                            onClick={() => filter.set("viewMode", ViewMode.List)}
                            active={viewMode === ViewMode.List ? "true" : "false"}
                            type="bars"
                        />
                        |
                        <ViewIcon
                            onClick={() => filter.set("viewMode", ViewMode.Grid)}
                            // Workaround for styled components error Received `true` for a non-boolean attribute `active`.
                            // tslint:disable-next-line:use-simple-attributes
                            active={viewMode === ViewMode.Grid ? "true" : "false"}
                            type="appstore"
                        />
                    </ViewModeStyle>
                    <SamplingsDataWrapper viewMode={viewMode} />
                </Wrapper>
            </main>
        </SamplingsStyle>
    );
};
