import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { useState } from "react";

import { DeleteSamplingsMutation, DeleteSamplingsMutationVariables } from "@models/graphql";
import { GraphQL } from "@graphql/graphQL";

const defaultDeleteSamplingOptions: MutationHookOptions<DeleteSamplingsMutation, DeleteSamplingsMutationVariables> = {

};

export const useDeleteSamplings = (options?: MutationHookOptions<DeleteSamplingsMutation, DeleteSamplingsMutationVariables>) => {

    const [hookState, setHookState] = useState<{
        isLoading: boolean;
        data?: DeleteSamplingsMutation;
        error?: string;
    }>({
        isLoading: false
    });

    const [mutation] = useMutation<DeleteSamplingsMutation, DeleteSamplingsMutationVariables>(GraphQL.deleteSamplings);

    const deleteSamplings = async (variables: DeleteSamplingsMutationVariables) => {
        setHookState({
            isLoading: true
        });

        try {
            const response = await mutation({
                ...defaultDeleteSamplingOptions,
                ...options,
                variables
            });

            setHookState({
                data: response.data,
                isLoading: false
            });

            return response;
        } catch (error) {

            setHookState({
                error,
                isLoading: false
            });
        }
    };

    return {
        isLoading: hookState.isLoading,
        error: hookState.error,
        data: hookState.data,
        deleteSamplings
    };
};
