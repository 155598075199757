import { SamplingsGridProps } from "@components/samplings/samplingsGrid/samplingsGridContainer";
import {
    DeleteIcon,
    GridWrapper,
    IdAndAction,
    Matching,
    SampleCard,
    SampleCardSelected,
    SampleCheckBox,
    SampleImage,
    SamplingsGridStyle,
    Transport
} from "@components/samplings/samplingsGrid/samplingsGridStyle";
import { GetSamplingListQuery, Image } from "@models/graphql";
import { Probability } from "@pages/samplingDetail/probability/probability";
import { parseError } from "@utils/parseError";
import { message, Pagination, Popconfirm, Spin } from "antd";
import CheckboxGroup from "antd/lib/checkbox/Group";
import { autobind } from "core-decorators";
import "lightbox-react/style.css";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ManualStyle } from "../samplingsTable/samplingsTableStyle";
import { SamplingApplianceSelect } from "@components/samplings/samplingsTable/samplingApplianceSelect/samplingApplianceSelect";
import { isApplianceHistory } from "@utils/samplingHistory";
import { Role } from "@components/permission/permissionProvider";

export interface SamplingsGridState {
    lightBoxIsOpen: boolean;
    selectedImages: Image[];
}

@autobind
export class SamplingsGrid extends React.Component<
    SamplingsGridProps,
    SamplingsGridState
> {
    public readonly state: SamplingsGridState = {
        lightBoxIsOpen: false,
        selectedImages: []
    };

    public render() {
        const {
            samplings,
            selectedSamplingIds,
            samplingsCount,
            filter
        } = this.props;

        if (samplings === null) {
            return null;
        }

        return (
            <SamplingsGridStyle>
                <Pagination
                    showSizeChanger
                    showQuickJumper
                    pageSize={filter.get("pageSize")}
                    pageSizeOptions={["10", "20", "50"]}
                    onShowSizeChange={this.onPaginationChange}
                    onChange={this.onPaginationChange}
                    total={samplingsCount}
                />
                <CheckboxGroup
                    style={{ width: "100%" }}
                    defaultValue={selectedSamplingIds}
                    onChange={e => this.selectCard(e)}
                >
                    {this.renderSamplings()}
                </CheckboxGroup>
            </SamplingsGridStyle>
        );
    }

    private onPaginationChange(
        page: number | undefined,
        pageSize: number | undefined
    ) {
        if (page) this.props.filter.set("page", page);
        if (pageSize) this.props.filter.set("pageSize", pageSize);
    }

    private renderSamplings() {
        const { samplings, samplingsLoading } = this.props;

        if (samplings === null) {
            return null;
        }

        const component = (
            <GridWrapper>
                {samplings.map(sampling => this.renderSample(sampling))}
            </GridWrapper>
        );

        if (samplingsLoading) {
            return <Spin>{component}</Spin>;
        }

        return component;
    }

    private renderSample(s: GetSamplingListQuery["samplings"]["items"][0]) {
        const { oidcUser, hasRoles } = this.props;

        const Card = this.props.selectedSamplingIds.includes(s.id)
            ? SampleCardSelected
            : SampleCard;

        const isAdmin = hasRoles([Role.ADMIN]);

        const disabled = () => {
            if (isAdmin) {
                return false;
            }

            if (!s.manualEntry) {
                return false;
            }

            return s.history.some(hist => {
                if (!isApplianceHistory(hist)) {
                    return false;
                }

                if (hist.user && hist.user.id !== oidcUser.profile.oid) {
                    return true;
                }

                return false;
            });
        };

        return (
            <Card key={s.id}>
                <SampleImage
                    src={s.images[0] && s.images[0].thumbnailUrl}
                    onClick={() => this.onSamplingClick(s)}
                />

                <SampleCheckBox disabled={disabled()} value={s.id} />

                {this.renderSelect(s)}
                {s.manualEntry ? (
                    <ManualStyle>
                        <FormattedMessage id="sampling.manual" />
                    </ManualStyle>
                ) : (
                    <Matching>
                        <Probability value={s.probability} />
                    </Matching>
                )}
                <IdAndAction>
                    <Transport>{s.transport.transportNumber}</Transport>
                    <Popconfirm
                        onConfirm={() => this.deleteSample(s.id)}
                        title={this.props.intl.formatMessage({
                            id: "deleteSampling.confirm"
                        })}
                    >
                        <DeleteIcon type="delete" />
                    </Popconfirm>
                </IdAndAction>
            </Card>
        );
    }

    private onSamplingClick(
        sampling: GetSamplingListQuery["samplings"]["items"][0]
    ) {
        const { history, setPreviousPath } = this.props;

        setPreviousPath({
            path: window.location.pathname + window.location.search
        });
        history.push(`/samplings/${sampling.id}`);
    }

    private renderSelect(
        record: GetSamplingListQuery["samplings"]["items"][0]
    ) {
        return <SamplingApplianceSelect sampling={record} />;
    }

    private selectCard(e) {
        this.props.setSelectedSamplingIds({ ids: e });
    }

    private async deleteSample(id: number) {
        const { intl, deleteSamplings } = this.props;
        try {
            await deleteSamplings({
                ids: [id]
            });
            message.info(intl.formatMessage({ id: "deleteSampling.succes" }));
        } catch (err) {
            console.log(err);

            message.error(parseError(err, intl));
        }
    }
}
