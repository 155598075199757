
import { OperatorsTable } from "@components/operators/operatorsTable/operatorsTable";
import { createDeleteOperatorMutation, DeleteOperatorMutationResult } from "@graphql/hocs/deleteOperator";
import { createGetOperatorsQuery, GetOperatorsQueryResult } from "@graphql/hocs/getOperators";
import { Operator, SortOrder } from "@models/graphql";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "redux";
import { pagingAndSort, PagingAndSortComponentProps } from "@utils/withPagingAndSort";

export interface OperatorsTableContainerProps {
    currentPage: number;
    changeMode(mode: string, operator: Operator): void;
}

const withGetOperators = createGetOperatorsQuery<OperatorsTableContainerProps & PagingAndSortComponentProps>({
    options(ownProps) {
        return {
            variables: {
                paging: {
                    limit: ownProps.paging.limit,
                    offset: (ownProps.currentPage - 1) * ownProps.pageSize
                }
            }
        };
    }
});
const withDeleteOperator = createDeleteOperatorMutation({
    options: {
        refetchQueries: ["getOperators"]
    }
});

const withPagingAndSort = pagingAndSort({
    pageSize: 10,
    url: "/transports/p/{page}"
});

export const OperatorsTableContainer = compose<React.ComponentClass<OperatorsTableContainerProps>>(
    withPagingAndSort,
    withDeleteOperator,
    withGetOperators,
    injectIntl
)(OperatorsTable);

export type OperatorsTableProps =
    DeleteOperatorMutationResult &
    InjectedIntlProps &
    OperatorsTableContainerProps &
    GetOperatorsQueryResult &
    PagingAndSortComponentProps;
