
import { Main } from "@components/main/main";

import * as React from "react";

import { compose } from "redux";
import { RouteComponentProps } from "react-router";

export interface MainContainerProps {

}

export const MainContainer = compose<React.ComponentClass<MainContainerProps>>(

)(Main);

export type MainProps =
    RouteComponentProps &
    MainContainerProps;
