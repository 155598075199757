// tslint:disable
declare const SENTRY_DSN: string;
declare const BUILD_NUMBER: string;
declare const SSO_URL: string;

const _SENTRY_DSN = SENTRY_DSN;
const _BUILD_NUMBER = BUILD_NUMBER;
const _SSO_URL = SSO_URL;

export { _SENTRY_DSN as SENTRY_DSN };
export { _BUILD_NUMBER as BUILD_NUMBER };
export { _SSO_URL as SSO_URL };

