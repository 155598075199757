import { useDuplicateApplianceList } from "@graphql/hocs/hooks/useDuplicateApplianceList";
import { parseError } from "@utils/parseError";
import { Alert, DatePicker, Form, Input, message, Modal } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { NormalizedCache } from "apollo-cache-inmemory";
import Maybe from "graphql/tsutils/Maybe";
import * as moment from "moment";
import * as React from "react";
import { FunctionComponent } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "redux";
import { DuplicateApplianceListModalStyle } from "./duplicateApplianceListModalStyle";

export interface DuplicateApplianceListModalProps {
    applianceListId: Maybe<number>;
    onDone(): void;
}

export const DuplicateApplianceListModalComponent: FunctionComponent<DuplicateApplianceListModalProps> = (props: DuplicateApplianceListModalProps & FormComponentProps & InjectedIntlProps & RouteComponentProps) => {
    const { form, intl, applianceListId, history, onDone } = props;

    const client = useApolloClient();

    const { duplicateApplianceList, isLoading, error } = useDuplicateApplianceList({

        async update(cache) {
            const data: NormalizedCache = (cache as any).data;

            Object.keys((data as any).data).forEach(key => key.match(/^ApplianceList/) && data.delete(key));
            Object.keys((data as any).data).forEach(key => key.match(/^\$ROOT_QUERY\.applianceLists/) && data.delete(key));

            await client.reFetchObservableQueries();
        }
    });

    const saveApplianceList = () => {
        form.validateFields(async (errors, values) => {
            if (!errors) {
                if (!applianceListId) {
                    return;
                }

                const { data } = await duplicateApplianceList({
                    input: {
                        activeFrom: values.activeFrom.toISOString(),
                        activeTo: values.activeTo.toISOString(),
                        title: values.name
                    },
                    id: applianceListId
                });

                if (data && data.duplicateApplianceList) {
                    message.info(intl.formatMessage({ id: "applianceListForm.duplicated" }));
                    history.push(`/appliances/validity/update/${data.duplicateApplianceList.id}`);
                }
            }
        });
    };

    const activeToValidator = (_, value: moment.Moment | undefined, callback: (error?: string) => void) => {
        const fromValue: moment.Moment | undefined = form.getFieldValue("activeFrom");

        if (fromValue && value && fromValue.isSameOrAfter(value, "d")) {
            callback(intl.formatMessage({ id: "applianceListForm.error.beforeFrom" }));
        } else {
            callback();
        }
    };

    const exit = () => {
        form.resetFields();
        onDone();
    };

    return (
        <Modal confirmLoading={isLoading} onOk={saveApplianceList} onCancel={exit} visible={!!applianceListId} title={intl.formatMessage({ id: "duplicateAppliance.title" })}>

            <DuplicateApplianceListModalStyle>
                {error && <Alert style={{ marginTop: 15 }} type="error" message={parseError(error, intl)} />}
                <Form>
                    <Form.Item label={intl.formatMessage({ id: "applianceListForm.name" })}>
                        {form.getFieldDecorator("name", {
                            rules: [{ required: true, message: intl.formatMessage({ id: "requiredField" }) }]

                        })(
                            <Input placeholder={intl.formatMessage({ id: "applianceListForm.name" })} style={{ width: "100%" }} />
                        )}
                    </Form.Item>
                    <Form.Item label={intl.formatMessage({ id: "applianceListForm.activeFrom" })}>
                        {form.getFieldDecorator("activeFrom", {
                            rules: [{ required: true, message: intl.formatMessage({ id: "requiredField" }) }]

                        })(
                            <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                        )}
                    </Form.Item>
                    <Form.Item label={intl.formatMessage({ id: "applianceListForm.activeTo" })}>
                        {form.getFieldDecorator("activeTo", {
                            rules: [
                                { required: true, message: intl.formatMessage({ id: "requiredField" }) },
                                { validator: activeToValidator }
                            ]
                        })(
                            <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                        )}
                    </Form.Item>

                </Form>
            </DuplicateApplianceListModalStyle>
        </Modal>
    );
};

export const DuplicateApplianceListModal = compose<React.FunctionComponent<DuplicateApplianceListModalProps>>(
    Form.create(),
    injectIntl,
    withRouter
)(DuplicateApplianceListModalComponent);
