import { GraphQL } from "@graphql/graphQL";
import { GetTransportsQuery, GetTransportsQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetTransports = (opts?: QueryHookOptions<GetTransportsQuery, GetTransportsQueryVariables>) => {
    const { data, loading, error } = useQuery<GetTransportsQuery, GetTransportsQueryVariables>(GraphQL.getTransports, opts);

    return {
        transports: data && data.transports ? data.transports.items : [],
        transportsLoading: loading,
        transportsError: error,
        transportsCount: data && data.transports ? data.transports.count : 0
    };
};
