export type ParsedURLSearchParams<SearchParams extends {}> = {
    set: <Name extends keyof SearchParams>(
        name: Name,
        value: SearchParams[Name]
    ) => void;
    get: <Name extends keyof SearchParams>(
        name: Name
    ) => SearchParams[Name] | null;
    delete: <Name extends keyof SearchParams>(name: Name) => void;
};

const MATCH_ISO_STRING = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;

const isDate = (date: unknown): date is Date =>
    /**
     * "date instanceof Date" fails when objects are passed across frame boundaries.
     * https://stackoverflow.com/a/643827/8327458
     */
    Object.prototype.toString.call(date) === "[object Date]";

const isArray = <Value>(arr: Value | Value[]): arr is Value[] =>
    Array.isArray(arr);

const isNumber = (number: unknown): number is number =>
    typeof number === "number";

const isISOString = (isoString: string): boolean =>
    MATCH_ISO_STRING.test(isoString) &&
    new Date(isoString).toISOString() === isoString;

const isArrayString = (string: string): boolean => string.includes(",");

const isNumberString = (string: string): boolean => !isNaN(Number(string));

const parseArray = <Value>(string: string): Value[] =>
    (string
        .split(",")
        .map(item =>
            isNumberString(item) ? parseInt(item, 10) : item
        ) as unknown) as Value[];

const parse = <Value>(value: string): Value => {
    if (isISOString(value)) return (new Date(value) as unknown) as Value;
    if (isArrayString(value)) return (parseArray(value) as unknown) as Value;
    if (isNumberString(value)) return (parseInt(value, 10) as unknown) as Value;
    return (value as unknown) as Value;
};

const stringify = <Value>(value: Value): string => {
    if (isDate(value)) return value.toISOString();
    if (isArray(value)) return value.toString();
    if (isNumber(value)) return value.toString();
    return (value as unknown) as string;
};

export const parseURLSearchParams = <SearchParams extends {}>(
    searchParams: Pick<URLSearchParams, "get" | "set" | "delete">
): ParsedURLSearchParams<SearchParams> => {
    const get: ParsedURLSearchParams<SearchParams>["get"] = name => {
        const value = searchParams.get(name as string);

        return value !== null ? parse(value) : null;
    };

    const set: ParsedURLSearchParams<SearchParams>["set"] = (name, value) => {
        searchParams.set(name as string, stringify(value));
    };

    const _delete: ParsedURLSearchParams<SearchParams>["delete"] = name => {
        searchParams.delete(name as string);
    };

    return {
        get,
        set,
        delete: _delete
    };
};
