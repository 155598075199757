import { TranslationInput } from "@models/graphql";

import { AuthService } from "./authService";

export function getText(translations: TranslationInput[] | null, chosenLanguage: string): string {

    if (!translations) {
        return "";
    }

    let value = "";

    // if (!Array.isArray(translations)) {
    //     if (chosenLanguage) {
    //         value = translations[chosenLanguage];
    //     }

    //     if (!value) {
    //         value = translations.en || translations.nl || translations.fr || translations[Object.keys(translations)[0]];
    //     }

    //     return value;
    // }

    let translation: TranslationInput | undefined;

    if (chosenLanguage) {
        translation = translations.find(t => t.language === chosenLanguage);

        return translation ? translation.value || "" : "";
    }

    translation = translations.find(t => t.language === AuthService.language);

    if (translation && translation.value) {
        value = translation.value;
    }

    if (!translation && translations[0]) {
        value = translations[0].value || "";
    }

    return value;
}
