import { ApplianceSelect } from "@components/applianceSelect/applianceSelect";
import {
    FilterSubTitle,
    FilterTitle,
    SamplingFilterStyle
} from "@components/samplings/samplingFilter/samplingFilterStyle";
import { getShipmentNumbers } from "@graphql/hocs/hooks/getShipmentNumbers";
import { getTransportNumbers } from "@graphql/hocs/hooks/getTransportNumbers";
import { useGetApplianceCategories } from "@graphql/hocs/hooks/useGetApplianceCategories";
import { useGetBrands } from "@graphql/hocs/hooks/useGetBrands";
import { useGetApplianceLists } from "@graphql/hocs/hooks/useGetApplianceLists";
import { debounce } from "@utils/debounce";
import { DatePicker, InputNumber, Select } from "antd";
import Maybe from "graphql/tsutils/Maybe";
import * as moment from "moment";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import {
    StateType,
    useSamplingsTableFilter
} from "../samplingsTable/useSamplingsTableFilter";

import { SamplingFilterProps } from "./samplingFilterContainer";

export const SamplingFilter = (props: SamplingFilterProps) => {
    const { intl } = props;
    const { get, set } = useSamplingsTableFilter();

    const [transportSearch, setTransportSearch] = React.useState("");
    const transportQuery = React.useMemo(() => {
        const value = get("transportId");
        if (value && transportSearch === "") return { id: value };
        return { transportNumber: transportSearch };
    }, [transportSearch, get("transportId")]);
    const { transports, transportsLoading } = getTransportNumbers({
        variables: {
            filter: {
                ...transportQuery
            },
            paging: {
                offset: 0,
                limit: 10
            }
        }
    });

    const [shipmentSearch, setShipmentSearch] = React.useState("");
    const { shipments, shipmentsLoading } = getShipmentNumbers({
        variables: {
            filter: {
                shipmentNumber: shipmentSearch,
                transportId: get("transportId")
            },
        },
        skip: !get("transportId")
    });

    const { applianceLists } = useGetApplianceLists();
    const findApplianceListItem = applianceLists.find(value =>
        moment().isBetween(moment(value.activeFrom), moment(value.activeTo))
    );
    const { applianceCategories } = useGetApplianceCategories({
        variables: {
            filter: {
                applianceListId: findApplianceListItem
                    ? findApplianceListItem.id
                    : undefined
            }
        }
    });
    const { brands, brandsLoading } = useGetBrands();

    const onApplianceSelectChange = (val: Maybe<number[] | number>) => {
        set("applianceIds", typeof val === "number" ? [val] : val || undefined);
        set("page", 1);
    };

    const onManualEntryChange = (val: string) => {
        set("assignedBy", val as any);
        set("page", 1);
    };

    const onTransportChange = (val: number) => {
        if (!val) setTransportSearch("");
        set("transportId", val);
        set("shipmentId", undefined);
        set("page", 1);
    };

    const onShipmentChange = (val: number) => {
        set("shipmentId", val);
        set("page", 1);
    };

    const handleApplianceCategoryChange = (val: null | number[]) => {
        set("applianceCategoryIds", val || undefined);
        set("page", 1);
    };

    const onBrandChange = (value: number[] | null) => {
        set("brandIds", value || undefined);
        set("page", 1);
    };

    const onChangeState = (val: string) => {
        set("approved", val as StateType);
        set("page", 1);
    };

    const onChangeSize = (val: string) => {
        set("size", val as any);
        set("page", 1);
    };

    const onFromChange = (date: moment.Moment) => {
        set("fromTimeSampled", date.toDate());
        set("page", 1);
    };

    const onToChange = (date: moment.Moment) => {
        set("toTimeSampled", date ? date.toDate() : undefined);
        set("page", 1);
    };

    const onChangeMinimumWeight = debounce((weight: number) => {
        set("minimumWeight", weight ? weight : undefined);
        set("page", 1);
    }, 400) as (val) => void;

    const onChangeMaximumWeight = debounce((weight: number) => {
        set("maximumWeight", weight ? weight : undefined);
        set("page", 1);
    }, 400) as (val) => void;

    const onMinMatchingPercentageChange = debounce(
        (val: number | undefined) => {
            set("minimumMatchingPercentage", val ? val : undefined);
            set("page", 1);
        },
        400
    ) as (val) => void;

    const onMaxMatchingPercentageChange = debounce(
        (val: number | undefined) => {
            set("maximumMatchingPercentage", val ? val : undefined);
            set("page", 1);
        },
        400
    ) as (val) => void;

    return (
        <SamplingFilterStyle>
            <FilterTitle>
                <FormattedMessage id="samplings.title" />
            </FilterTitle>
            <FilterSubTitle>
                <FormattedMessage id="sampling.filter.period" />
            </FilterSubTitle>
            <DatePicker
                allowClear={false}
                format="DD/MM/YYYY"
                onChange={onFromChange}
                value={moment(get("fromTimeSampled"))}
            />
            <DatePicker
                allowClear={true}
                format="DD/MM/YYYY"
                onChange={onToChange}
                value={
                    get("toTimeSampled") !== undefined
                        ? moment(get("toTimeSampled"))
                        : undefined
                }
            />
            <FilterSubTitle>
                <FormattedMessage id="sampling.filter.assigned" />
            </FilterSubTitle>
            <Select
                onChange={onManualEntryChange}
                value={get("assignedBy")}
                placeholder={intl.formatMessage({
                    id: "sampling.filter.assigned"
                })}
            >
                <Select.Option value={"both"}>
                    <FormattedMessage id="sampling.filter.both" />
                </Select.Option>
                <Select.Option value={"manual"}>
                    <FormattedMessage id="sampling.filter.manual" />
                </Select.Option>
                <Select.Option value={"system"}>
                    <FormattedMessage id="sampling.filter.system" />
                </Select.Option>
            </Select>
            <FilterSubTitle>
                <FormattedMessage id="sampling.filter.transportNumber" />
            </FilterSubTitle>
            <Select
                showSearch
                onSearch={debounce(setTransportSearch, 300)}
                filterOption={false}
                loading={transportsLoading}
                allowClear
                value={transportsLoading ? undefined : get("transportId")}
                placeholder={intl.formatMessage({
                    id: "sampling.filter.transportnumber"
                })}
                onChange={onTransportChange}
            >
                {transports.map(transport => (
                    <Select.Option key={transport.id} value={transport.id}>
                        {transport.transportNumber}
                    </Select.Option>
                ))}
            </Select>
            <Select
                showSearch
                disabled={!get("transportId")}
                onSearch={debounce(setShipmentSearch, 300)}
                filterOption={false}
                allowClear
                loading={shipmentsLoading}
                value={get("shipmentId")}
                placeholder={intl.formatMessage({
                    id: "sampling.filter.number"
                })}
                onChange={onShipmentChange}
            >
                {shipments &&
                    shipments.map(shipment => (
                        <Select.Option key={shipment.id} value={shipment.id}>
                            {shipment.shipmentNumber}
                        </Select.Option>
                    ))}
            </Select>
            <FilterSubTitle>
                <FormattedMessage id="sampling.filter.devices" />
            </FilterSubTitle>
            <ApplianceSelect
                onChange={onApplianceSelectChange}
                value={get("applianceIds") as any}
                selectOptions={{
                    mode: "multiple",
                    allowClear: true
                }}
            />
            <FilterSubTitle>
                <FormattedMessage id="sampling.filter.applianceCategory" />
            </FilterSubTitle>
            <Select
                showSearch
                filterOption={(v, el) =>
                    (el.props.children as string)
                        .toLowerCase()
                        .includes(v.toLowerCase().trim())
                }
                value={get("applianceCategoryIds")}
                onChange={handleApplianceCategoryChange}
                placeholder={intl.formatMessage({
                    id: "sampling.filter.applianceCategory"
                })}
                mode="multiple"
            >
                {applianceCategories.map(cat => (
                    <Select.Option key={cat.id} value={cat.id}>
                        {cat.name}
                    </Select.Option>
                ))}
            </Select>
            <FilterSubTitle>
                <FormattedMessage id="sampling.filter.brand" />
            </FilterSubTitle>
            <Select
                filterOption={(v, el) =>
                    (el.props.children as string)
                        .toLowerCase()
                        .includes(v.toLowerCase().trim())
                }
                loading={brandsLoading}
                allowClear
                value={get("brandIds")}
                placeholder={intl.formatMessage({
                    id: "sampling.filter.brand"
                })}
                onChange={onBrandChange}
                mode="multiple"
            >
                {brands &&
                    brands.map(brand => (
                        <Select.Option key={brand.id} value={brand.id}>
                            {brand.name}
                        </Select.Option>
                    ))}
            </Select>
            <FilterSubTitle>
                <FormattedMessage id="sampling.filter.size" />
            </FilterSubTitle>
            <Select
                onChange={onChangeSize}
                value={get("size")}
                allowClear
                placeholder={intl.formatMessage({ id: "sampling.filter.size" })}
            >
                <Select.Option value={"SMALL"}>
                    <FormattedMessage id="sampling.filter.SMALL" />
                </Select.Option>
                <Select.Option value={"BIG"}>
                    <FormattedMessage id="sampling.filter.BIG" />
                </Select.Option>
            </Select>
            <FilterSubTitle>
                <FormattedMessage id="sampling.filter.state" />
            </FilterSubTitle>
            <Select
                onChange={onChangeState}
                value={get("approved")}
                allowClear
                placeholder={intl.formatMessage({
                    id: "sampling.filter.state"
                })}
            >
                <Select.Option value={"approved"}>
                    <FormattedMessage id="samplings.table.approved" />
                </Select.Option>
                <Select.Option value={"notApproved"}>
                    <FormattedMessage id="samplings.table.notApproved" />
                </Select.Option>
                <Select.Option value={"corrected"}>
                    <FormattedMessage id="samplings.table.corrected" />
                </Select.Option>
                <Select.Option value={"automaticApproved"}>
                    <FormattedMessage id="samplings.table.automaticApproved" />
                </Select.Option>
            </Select>
            <FilterSubTitle>
                <FormattedMessage id="sampling.filter.minimumWeight" />
            </FilterSubTitle>
            <InputNumber
                style={{ width: "100%", marginBottom: "8px" }}
                placeholder={intl.formatMessage({
                    id: "sampling.filter.minimumWeight"
                })}
                defaultValue={get("minimumWeight")}
                onChange={value => onChangeMinimumWeight(value)}
            />
            <FilterSubTitle>
                <FormattedMessage id="sampling.filter.maximumWeight" />
            </FilterSubTitle>
            <InputNumber
                style={{ width: "100%", marginBottom: "16px" }}
                placeholder={intl.formatMessage({
                    id: "sampling.filter.maximumWeight"
                })}
                defaultValue={get("maximumWeight")}
                onChange={value => onChangeMaximumWeight(value)}
            />
            <FilterSubTitle>
                <FormattedMessage id="sampling.filter.minMatchingPercentage" />
            </FilterSubTitle>
            <InputNumber
                style={{ width: "100%", marginBottom: "16px" }}
                placeholder={intl.formatMessage({
                    id: "sampling.filter.minMatchingPercentage"
                })}
                defaultValue={get("minimumMatchingPercentage")}
                onChange={value => onMinMatchingPercentageChange(value)}
            />
            <FilterSubTitle>
                <FormattedMessage id="sampling.filter.maxMatchingPercentage" />
            </FilterSubTitle>
            <InputNumber
                style={{ width: "100%", marginBottom: "16px" }}
                placeholder={intl.formatMessage({
                    id: "sampling.filter.maxMatchingPercentage"
                })}
                defaultValue={get("maximumMatchingPercentage")}
                onChange={value => onMaxMatchingPercentageChange(value)}
            />
        </SamplingFilterStyle>
    );
};
