import * as React from "react";
import { FormattedNumber } from "react-intl";
import { HighProbability, LowProbability, MediumProbability } from "./probabilityStyle";

interface ProbabilityProps {
    value?: number | null;
    style?: React.CSSProperties;
}

export const Probability = (props: ProbabilityProps) => {
    const { value, style } = props;

    if (!value) {
        return null;
    }

    if (value > 0.85) {
        return <HighProbability style={style}><FormattedNumber value={value} style="percent" maximumFractionDigits={2} /></HighProbability>;
    } else if (value > 0.7) {
        return <MediumProbability style={style}><FormattedNumber value={value} style="percent" maximumFractionDigits={2} /></MediumProbability>;
    } else {
        return <LowProbability style={style}><FormattedNumber value={value} style="percent" maximumFractionDigits={2} /></LowProbability>;
    }
};
