import * as decode from "jwt-decode";
import * as moment from "moment";

interface TokenPayload {
    sub: string;
    exp: number;
    iat: number;
}

export class AuthService {
    private static callbacks: { [topic: string]: Function[] } = {};

    public static LOGIN(token: string) {
        this.token = token;
    }

    public static LOGOUT() {
        localStorage.removeItem("token");
        localStorage.removeItem("hideChecklist");
    }

    public static get HIDE_CHECKLIST(): boolean {
        const hide = localStorage.getItem("hideChecklist");

        if (hide) {
            return JSON.parse(hide);
        }

        return false;
    }

    public static set HIDE_CHECKLIST(hide: boolean) {
        localStorage.setItem("hideChecklist", JSON.stringify(hide));
    }

    public static get roles() {
        return decode<TokenPayload>(this.token).roles;
    }

    public static get id() {
        return decode<TokenPayload>(this.token).sub;
    }

    public static get token(): string {
        return localStorage.getItem("token") as string;
    }

    public static set token(token: string) {
        localStorage.setItem("token", token);
    }

    public static IS_TOKEN_EXPIRED(token): boolean {
        const date = this.tokenExpirationDate;

        if (!date) {
            return true;
        }

        return moment().isAfter(date);
    }

    public static get tokenExpirationDate(): moment.Moment | null {

        try {
            const decoded = decode<TokenPayload>(this.token);

            if (!decoded.exp) {
                return null;
            }

            return moment.unix(decoded.exp);
        } catch {
            console.warn("Invalid token present in localstorage");

            return null;
        }

    }

    public static get loggedIn(): boolean {
        // Checks if there is a saved token and it's still valid
        const isValid = !!this.token && !this.IS_TOKEN_EXPIRED(this.token);
        if (!isValid) {
            localStorage.removeItem("token");
        }

        return isValid;
    }

    public static set anonymousToken(token: string | null) {
        if (token) {
            localStorage.setItem("anonymousToken", token);
        } else {
            localStorage.removeItem("anonymousToken");
        }
    }

    public static get anonymousToken(): string | null {
        return localStorage.getItem("anonymousToken");
    }

    public static set language(language: string | null) {
        if (language) {
            localStorage.setItem("language", language);
        } else {
            localStorage.removeItem("language");
        }
    }

    public static get language(): string | null {
        return localStorage.getItem("language");
    }

    public static set redirectRoute(route: string | null) {
        if (route) {
            localStorage.setItem("redirectRoute", route);
        } else {
            localStorage.removeItem("redirectRoute");
        }
    }

    public static get redirectRoute(): string | null {
        return localStorage.getItem("redirectRoute");
    }
}
