import { GraphQL } from "@graphql/graphQL";
import { GetApplianceListsQuery, GetApplianceListsQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetApplianceLists = (options?: QueryHookOptions<GetApplianceListsQuery, GetApplianceListsQueryVariables>) => {
    const { data, error, loading } = useQuery<GetApplianceListsQuery, GetApplianceListsQueryVariables>(GraphQL.getApplianceLists, options);

    return {
        applianceLists: data && data.applianceLists ? data.applianceLists.items : [],
        applianceListsCount: data && data.applianceLists ? data.applianceLists.count : 0,
        applianceListsLoading: loading,
        applianceListsError: error
    };
};
