import { GraphQL } from "@graphql/graphQL";
import { GetSamplingListQuery, GetSamplingListQueryVariables } from "@models/graphql";
import { ApolloError } from "apollo-client";
import { graphql, OperationOption } from "@apollo/react-hoc";

export interface GetSamplingListQueryResult {
    samplings: GetSamplingListQuery["samplings"]["items"];
    samplingsCount: number;
    samplingsLoading: boolean;
    samplingsError?: ApolloError;
}

export const createGetSamplingListQuery = <TProps = {}>(options?: OperationOption<TProps, GetSamplingListQuery, GetSamplingListQueryVariables, GetSamplingListQueryResult>) =>
    graphql<TProps, GetSamplingListQuery, GetSamplingListQueryVariables, GetSamplingListQueryResult>(GraphQL.getSamplingList, {
        props(optionProps) {
            const { data } = optionProps;

            if (!data) {
                throw new Error("No data");
            }

            if (data.error) {
                console.error(data.error);
            }

            return {
                samplings: data && data.samplings ? data.samplings.items : [],
                samplingsCount: data && data.samplings ? data.samplings.count : 0,
                samplingsLoading: data.loading,
                samplingsError: data.error
            };
        },
        ...options
    });
