import * as React from "react";
import { useContext } from "react";
import { InjectedIntl, injectIntl, FormattedMessage, MessageValue } from "react-intl";

// tslint:disable-next-line: no-object-literal-type-assertion
export const IntlContext = React.createContext<InjectedIntl>({} as InjectedIntl);

// turn the old context into the new context
export const InjectIntlContext = injectIntl(({ intl, children }) =>
    <IntlContext.Provider value={intl}>
        {children}
    </IntlContext.Provider>);

// the format message hook
export const useFormatMessage = (): (messageDescriptor: FormattedMessage.MessageDescriptor, values?: { [key: string]: MessageValue }) => string => {
    const intl = useContext(IntlContext);

    return intl.formatMessage;
};
