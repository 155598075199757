import { GraphQL } from "@graphql/graphQL";
import { GetApplianceListQuery, GetApplianceListQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const useGetApplianceList = (options?: QueryHookOptions<GetApplianceListQuery, GetApplianceListQueryVariables>) => {
    const { data, error, loading } = useQuery<GetApplianceListQuery, GetApplianceListQueryVariables>(GraphQL.getApplianceList, options);

    return {
        applianceList: data && data.applianceList ? data.applianceList : null,
        applianceListLoading: loading,
        applianceListError: error
    };
};
