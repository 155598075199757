import { SortOrder } from "@models/graphql";
import { PaginationConfig, SorterResult } from "antd/lib/table";
import * as React from "react";
import { compose } from "redux";
import { withRouter, RouteComponentProps } from "react-router";

export interface Paging {
    offset: number;
    limit: number;
}

export interface Sort {
    field: string;
    order: SortOrder;
}

export type PagingAndSortComponentProps = {
    onTableChange(pagination: PaginationConfig, filters: Record<any, string[]>, sorter: SorterResult<any>): void;
    onPaginationChange(page: number, pageSize?: number): void;
} & PagingAndSortState;

export interface PagingAndSortState {
    pageSize: number;
    paging: Paging;
    sort: Sort[] | null;
}

export interface SetupOptions {
    pageSize: number;
    sort?: Sort[];
    url?: string;
}

export const pagingAndSort = (options: SetupOptions) => (Component: React.ComponentClass<PagingAndSortComponentProps>) => {

    class WithPagingAndSort extends React.Component<RouteComponentProps, PagingAndSortState> {
        constructor(props) {
            super(props);

            this.state = {
                paging: {
                    limit: options.pageSize,
                    offset: 0
                },
                sort: options.sort || [],
                pageSize: options.pageSize
            };

            this.onTableChange = this.onTableChange.bind(this);
            this.onPaginationChange = this.onPaginationChange.bind(this);
        }

        public render() {
            return <Component {...this.props} {...this.state} onTableChange={this.onTableChange} onPaginationChange={this.onPaginationChange} />;
        }

        private onTableChange(pagination: PaginationConfig, filters: Record<any, string[]>, sorter: SorterResult<any>) {

            const { history } = this.props;
            let sort: Sort[] | null = null;

            if (sorter.columnKey) {
                sort = [{
                    field: sorter.columnKey,
                    order: sorter.order === "ascend" ? SortOrder.Asc : SortOrder.Desc
                }];
            }

            const pageSize = pagination.pageSize || 10;

            if (options.url) {
                history.replace(options.url.replace("{page}", String((pagination.current || 1))));
            }

            this.setState({
                paging: {
                    limit: pageSize,
                    offset: ((pagination.current || 1) - 1) * pageSize
                },
                sort,
                pageSize
            });
        }

        private onPaginationChange(page: number, pageSize?: number) {
            const newPageSize = pageSize ? pageSize : this.state.pageSize;
            this.setState({
                paging: {
                    limit: newPageSize,
                    offset: ((page || 1) - 1) * newPageSize
                },
                pageSize: newPageSize
            });
        }

    }

    return compose<React.ComponentClass<PagingAndSortComponentProps>>(
        withRouter
    )(WithPagingAndSort);
};
