
import { SelectedDevices } from "@components/samplings/selectedDevices/selectedDevices";
import { DeleteSamplingsMutationResult } from "@graphql/hocs/deleteSamplings";
import { UpdateSamplingsMutationResult } from "@graphql/hocs/updateSamplings";
import { setSelectedSamplingIds, SetSelectedSamplingIdsActionOptions } from "@redux/actions/setSelectedSamplingIds";
import { StoreState } from "@redux/reducers/root";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect, Dispatch } from "react-redux";
import { compose } from "redux";

export interface SelectedDevicesContainerProps {

}

export const mapStateToProps = (state: StoreState) => ({
    selectedSamplings: state.main.selectedSamplingIds
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<{ type: string }>) => ({
    setSelectedSamplingIds(data: SetSelectedSamplingIdsActionOptions) { dispatch(setSelectedSamplingIds(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const SelectedDevicesContainer = compose<React.ComponentClass<SelectedDevicesContainerProps>>(
    injectIntl,
    withRedux
)(SelectedDevices);

export type SelectedDevicesProps =
    SelectedDevicesContainerProps &
    WithReduxStateProps &
    WithReduxDispatchProps &
    DeleteSamplingsMutationResult &
    UpdateSamplingsMutationResult &
    InjectedIntlProps;
