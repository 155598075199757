import { combineReducers } from "redux";

import { mainReducer, MainReducerState } from "@redux/reducers/mainReducer";

export interface StoreState {
    main: MainReducerState;
}

export const rootReducer = combineReducers<StoreState>({
    main: mainReducer
});
