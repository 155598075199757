import { useReactOidc } from "@axa-fr/react-oidc-context";
import { message } from "antd";
import { User } from "oidc-client";
import { FunctionComponent } from "react";
import * as React from "react";

import { isApplianceHistory } from "@utils/samplingHistory";
import { parseError } from "@utils/parseError";
import { useFormatMessage } from "@utils/intlHook";
import { GetSamplingListQuery } from "@models/graphql";
import { useUpdateSamplings } from "@graphql/hocs/hooks/useUpdateSampling";
import { ApplianceSelect } from "@components/applianceSelect/applianceSelect";
import { usePermissions, Role } from "@components/permission/permissionProvider";

export interface SamplingApplianceSelectProps {
    sampling: {
        id: number;
        manualEntry: boolean;
        timeSampled: GetSamplingListQuery["samplings"]["items"][0]["timeSampled"];
        history: GetSamplingListQuery["samplings"]["items"][0]["history"];
        appliance: GetSamplingListQuery["samplings"]["items"][0]["appliance"];
    };
}

export const SamplingApplianceSelect: FunctionComponent<SamplingApplianceSelectProps> = ({ sampling }) => {
    const formatMessage = useFormatMessage();
    const [applianceId, setApplianceId] = React.useState(sampling.appliance ? sampling.appliance.id : null);

    const { updateSamplings, isLoading } = useUpdateSamplings({
        refetchQueries: ["getSamples"]
    });

    const { oidcUser }: { oidcUser: User } = useReactOidc();
    const { hasRoles } = usePermissions();

    const canChange = React.useMemo(
        () => {
            if (hasRoles([Role.ADMIN])) {
                return true;
            }

            if (!sampling.manualEntry) {
                return true;
            }

            return !sampling.history.some(s => {
                if (!isApplianceHistory(s)) {
                    return false;
                }

                if (s.user && s.user.id !== oidcUser.profile.oid) {
                    return true;
                }

                return false;
            });
        },
        [sampling, oidcUser]
    );

    const handleChange = async (val: number) => {
        if (val && typeof val === "number") {
            try {
                setApplianceId(val);
                await updateSamplings({
                    ids: [sampling.id],
                    input: {
                        applianceId: val
                    }
                });

                message.success(formatMessage({ id: "sampling.updatedApplianceSuccessful" }));
            } catch (err) {
                message.error(parseError(err, formatMessage));

                setApplianceId(sampling.appliance ? sampling.appliance.id : null);
            }
        }
    };

    return (
        <ApplianceSelect
            style={{ width: 240 }}
            showActiveAt={sampling.timeSampled}
            loading={isLoading}
            disabled={!canChange}
            value={applianceId}
            initialAppliance={sampling.appliance}
            onChange={handleChange}
        />
    );

};
