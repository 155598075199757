import { GraphQL } from "@graphql/graphQL";
import { DeleteApplianceCategoryMutation, DeleteApplianceCategoryMutationVariables } from "@models/graphql";
import { ApolloError } from "apollo-client";
import { useState } from "react";
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";

const defaultDeleteApplianceCategoryOptions: MutationHookOptions<DeleteApplianceCategoryMutation, DeleteApplianceCategoryMutationVariables> = {

};

export const useDeleteApplianceCategory = (options?: MutationHookOptions<DeleteApplianceCategoryMutation, DeleteApplianceCategoryMutationVariables>) => {
    const [hookState, setHookState] = useState<{
        isLoading: boolean;
        data?: DeleteApplianceCategoryMutation;
        error?: ApolloError;
    }>({
        isLoading: false
    });

    const [mutation] = useMutation<DeleteApplianceCategoryMutation, DeleteApplianceCategoryMutationVariables>(GraphQL.deleteApplianceCategory);

    const deleteApplianceCategory = async (variables: DeleteApplianceCategoryMutationVariables) => {
        setHookState({
            isLoading: true
        });

        try {
            const response = await mutation({
                ...defaultDeleteApplianceCategoryOptions,
                ...options,
                variables
            });

            setHookState({
                data: response.data,
                isLoading: false
            });

            return {
                data: response.data,
                error: response.errors
            };
        } catch (error) {
            setHookState({
                error,
                isLoading: false
            });

            return {
                error
            };
        }
    };

    return {
        isLoading: hookState.isLoading,
        error: hookState.error,
        data: hookState.data,
        deleteApplianceCategory
    };
};
