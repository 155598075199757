import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

import {
    GetSampleGalleryInfosQueryVariables,
    GetSampleGalleryInfosQuery
} from "@models/graphql";
import { GraphQL } from "@graphql/graphQL";

export const useGetSampleGalleryInfos = (
    opts?: QueryHookOptions<
        GetSampleGalleryInfosQuery,
        GetSampleGalleryInfosQueryVariables
    >
) => {
    const { data, loading, error } = useQuery<
        GetSampleGalleryInfosQuery,
        GetSampleGalleryInfosQueryVariables
    >(GraphQL.getSampleGalleryInfos, opts);

    return {
        sampleGalleryInfos: data && data.samplings ? data.samplings.items : [],
        sampleGalleryInfosLoading: loading,
        sampleGalleryInfosError: error
    };
};
