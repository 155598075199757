
import { TransportForm } from "@components/transports/transportForm/transportForm";
import { NonNullableURLSearchParams } from "@utils/nonNullableURLSearchParams";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "redux";
import { TransportsTableFilter } from "../transportTable/useTransportsTableFilter";

export interface TransportFormContainerProps {
    filter: NonNullableURLSearchParams<TransportsTableFilter>;
}

export const TransportFormContainer = compose<React.ComponentClass<TransportFormContainerProps>>(
    injectIntl
)(TransportForm);

export type TransportFormProps =
    InjectedIntlProps &
    TransportFormContainerProps;
