import { GraphQL } from "@graphql/graphQL";
import { GetPercentageEnteredManuallyQuery, GetPercentageEnteredManuallyQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const getPercentageEnteredManually = (opts?: QueryHookOptions<GetPercentageEnteredManuallyQueryVariables>) => {
    const { data, loading, error, refetch } = useQuery<GetPercentageEnteredManuallyQuery, GetPercentageEnteredManuallyQueryVariables>(GraphQL.getPercentageEnteredManually, opts);

    return {
        percentageEnteredManually: data && data.percentageEnteredManually,
        percentageEnteredManuallyLoading: loading,
        percentageEnteredManuallyError: error,
        refetch
    };
};
