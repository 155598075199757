
import { OperatorForm } from "@components/operators/operatorForm/operatorForm";
import { createCreateOperatorMutation, CreateOperatorMutationResult } from "@graphql/hocs/createOperator";
import { createUpdateOperatorMutation, UpdateOperatorMutationResult } from "@graphql/hocs/updateOperator";
import { Operator } from "@models/graphql";
import { Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { compose } from "redux";

export interface OperatorFormContainerProps {
    mode: string;
    operator?: Operator;
    changeMode(mode: string, operator?: Operator): void;
}

const withForm = Form.create();
const withCreateOperator = createCreateOperatorMutation({
    options: {
        refetchQueries: ["getOperators"],
        awaitRefetchQueries: true
    }
});

const withUpdateOperator = createUpdateOperatorMutation({
    options: {
        refetchQueries: ["getOperators"],
        awaitRefetchQueries: true
    }
});

export const OperatorFormContainer = compose<React.ComponentClass<OperatorFormContainerProps>>(
    withForm,
    withCreateOperator,
    withUpdateOperator,
    injectIntl
)(OperatorForm);

export type OperatorFormProps =
    FormComponentProps &
    CreateOperatorMutationResult &
    UpdateOperatorMutationResult &
    InjectedIntlProps &
    OperatorFormContainerProps;
