import styled from "styled-components";

export const OperatorFormStyle = styled.div`
    width: 60%;
    form {
        display: flex;

    }

    form > input {
        width: 80px;
        margin-right: 10px;
    }

    .ant-select {
        width: 150px;
    }

    form > div {
        margin-right: 10px;
    }

    form > button {
        padding: 0px 50px;
        margin-top: 4px;
    }
`;
