import { ApplianceSelect } from "@components/applianceSelect/applianceSelect";
import { useCreateAppliance } from "@graphql/hocs/hooks/useCreateAppliance";
import { useGetAppliance } from "@graphql/hocs/hooks/useGetAppliance";
import { useUpdateAppliance } from "@graphql/hocs/hooks/useUpdateAppliance";
import { getText } from "@utils/getText";
import { parseError } from "@utils/parseError";
import { Alert, Button, Col, Form, Icon, Input, message, Row, Spin } from "antd";
import { FormComponentProps } from "antd/lib/form";
import * as React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import { ApplianceFormStyle } from "./applianceFormStyle";

export interface ApplianceFormProps {

}

export const ApplianceFormComponent: FunctionComponent<ApplianceFormProps> = (props: ApplianceFormProps & InjectedIntlProps & FormComponentProps & RouteComponentProps<{ id?: string }>) => {
    const { form, intl, history, match } = props;
    const { createAppliance, isLoading: isCreateLoading, error: createError } = useCreateAppliance();
    const { updateAppliance, isLoading: isUpdateLoading, error: updateError } = useUpdateAppliance();

    let editId: number | undefined;

    if (match.params.id) {
        editId = parseInt(match.params.id, 10);
    }

    const { appliance, applianceLoading, applianceError } = useGetAppliance({
        variables: {
            // tslint:disable-next-line: no-non-null-assertion
            id: editId!
        },
        skip: !editId
    });

    const submit = () => {
        form.validateFields(async (errors, values) => {
            if (!errors) {

                if (editId) {
                    const { data } = await updateAppliance({
                        id: editId,
                        input: {
                            name: [
                                { language: "nl", value: values.name.nl },
                                { language: "fr", value: values.name.fr }
                            ],
                            description: [
                                { language: "nl", value: values.description.nl || "" },
                                { language: "fr", value: values.description.fr || "" }
                            ],
                            previousApplianceId: values.previousAppliance ? values.previousAppliance : null

                        }
                    });

                    if (data && data.updateAppliance) {
                        history.push("/appliances");
                        message.info(intl.formatMessage({ id: "applianceForm.updated" }));
                    }
                } else {
                    const { data } = await createAppliance({
                        input: {
                            name: [
                                { language: "nl", value: values.name.nl },
                                { language: "fr", value: values.name.fr }
                            ],
                            description: [
                                { language: "nl", value: values.description.nl || "" },
                                { language: "fr", value: values.description.fr || "" }
                            ],
                            previousApplianceId: values.previousAppliance ? values.previousAppliance : null
                        }
                    });

                    if (data && data.createAppliance) {
                        history.push("/appliances");
                        message.info(intl.formatMessage({ id: "applianceForm.created" }));
                    }
                }
            }
        });
    };

    return (
        <ApplianceFormStyle>
            <Spin spinning={applianceLoading}>

                <div className="bar">
                    <Link to="/appliances"> <Icon type="arrow-left" /> <FormattedMessage id="back" /></Link>
                    <Button loading={isCreateLoading || isUpdateLoading} onClick={submit} type="primary" ><FormattedMessage id="saveChanges" /></Button>
                </div>

                {applianceError && <Alert style={{ marginTop: 15 }} type="error" message={parseError(applianceError, intl)} />}
                {updateError && <Alert style={{ marginTop: 15 }} type="error" message={parseError(updateError, intl)} />}
                {createError && <Alert style={{ marginTop: 15 }} type="error" message={parseError(createError, intl)} />}
                <Form>
                    <Row gutter={25}>
                        <Col span={12}>
                            <Form.Item label={intl.formatMessage({ id: "applianceForm.nameNl" })}>
                                {form.getFieldDecorator("name.nl", {
                                    rules: [{ required: true, message: intl.formatMessage({ id: "requiredField" }) }],
                                    initialValue: appliance && getText(appliance.name, "nl")
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                            <Form.Item label={intl.formatMessage({ id: "applianceForm.descriptionNl" })}>
                                {form.getFieldDecorator("description.nl", {
                                    initialValue: appliance && getText(appliance.description, "nl")
                                })(
                                    <Input.TextArea rows={4} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={intl.formatMessage({ id: "applianceForm.nameFr" })}>
                                {form.getFieldDecorator("name.fr", {
                                    rules: [{ required: true, message: intl.formatMessage({ id: "requiredField" }) }],
                                    initialValue: appliance && getText(appliance.name, "fr")
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                            <Form.Item label={intl.formatMessage({ id: "applianceForm.descriptionFr" })}>
                                {form.getFieldDecorator("description.fr", {
                                    initialValue: appliance && getText(appliance.description, "fr")
                                })(
                                    <Input.TextArea rows={4} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label={intl.formatMessage({ id: "applianceForm.previousAppliance" })}>
                                {form.getFieldDecorator("previousAppliance", {
                                    initialValue: appliance && appliance.previousAppliance
                                })(
                                    <ApplianceSelect
                                        filterOutApplianceIds={editId ? [editId] : undefined}
                                        selectOptions={{ allowClear: true }}

                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </ApplianceFormStyle>
    );
};

export const ApplianceForm = compose(
    injectIntl,
    withRouter,
    Form.create()
)(ApplianceFormComponent);
