import { GraphQL } from "@graphql/graphQL";
import { GetPercentageApprovedQuery, GetPercentageApprovedQueryVariables } from "@models/graphql";
import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

export const getPercentageApproved = (opts?: QueryHookOptions<GetPercentageApprovedQueryVariables>) => {
    const { data, loading, error, refetch } = useQuery<GetPercentageApprovedQuery, GetPercentageApprovedQueryVariables>(GraphQL.getPercentageApproved, opts);

    return {
        percentageApproved: data && data.percentageApproved,
        percentageApprovedLoading: loading,
        percentageApprovedError: error,
        refetch
    };
};
