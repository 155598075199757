import * as React from "react";
import { autobind } from "core-decorators";
import { MainProps } from "@components/main/mainContainer";
import { MainLayout, MainHeader, MainLayoutContent } from "@components/main/mainStyle";
import { Layout } from "antd";
import { HeaderContainer } from "@components/header/headerContainer";

export interface MainState {

}

@autobind
export class Main extends React.Component<MainProps, MainState> {

    public render() {

        const { children, location } = this.props;

        return (
            <MainLayout>
                <MainHeader>
                    <HeaderContainer currentPath={location.pathname} />
                </MainHeader>
                <MainLayoutContent>
                    {children}
                </MainLayoutContent>
            </MainLayout>

        );
    }
}
