import styled from "styled-components";

export const JwtInfoStyle = styled.div`
    width: 100vw;
    pre {
        display: block;
        font-family: monospace;
        overflow:auto;
        padding: 25px;
    }
`;
