import * as React from "react";
import { FunctionComponent} from "react";
import { LoadingPageStyle } from "./loadingPageStyle";

export interface LoadingPageProps {

}

export const LoadingPage: FunctionComponent<LoadingPageProps> = (props: LoadingPageProps) => {

    return (
        <LoadingPageStyle>

        </LoadingPageStyle>
    );
};
