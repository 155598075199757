import { QueryHookOptions, useQuery } from "@apollo/react-hooks";
import { GraphQL } from "@graphql/graphQL";
import {
    GetIrConfigurationsQuery,
    GetIrConfigurationsQueryVariables
} from "@models/graphql";

export const useGetIrConfigurations = (
    options?: QueryHookOptions<
        GetIrConfigurationsQuery,
        GetIrConfigurationsQueryVariables
    >
) => {
    const { data, loading, error } = useQuery<
        GetIrConfigurationsQuery,
        GetIrConfigurationsQueryVariables
    >(GraphQL.getIrConfigurations, options);

    return {
        irConfigurations: data ? data.irConfigurations : null,
        irConfigurationsLoading: loading,
        irConfigurationsError: error
    };
};
