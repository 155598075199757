import * as React from "react";
import { FunctionComponent } from "react";
import { JwtInfoStyle } from "./jwtInfoStyle";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { User } from "oidc-client";
export interface JwtInfoProps {

}

export const JwtInfo: FunctionComponent<JwtInfoProps> = (props: JwtInfoProps) => {
    const { oidcUser }: { oidcUser: User } = useReactOidc();

    return (
        <JwtInfoStyle>
            <pre>
                {JSON.stringify(oidcUser, null, 4)}

            </pre>
        </JwtInfoStyle>
    );
};
