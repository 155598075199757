import { GraphQL } from "@graphql/graphQL";
import { DeleteOperatorMutationVariables, Mutation, MutationResult } from "@models/graphql";
import { graphql, OperationOption } from "@apollo/react-hoc";

export interface DeleteOperatorMutationResult {
    // TODO: Add correct response return type
    deleteOperator(variables: DeleteOperatorMutationVariables): Promise<MutationResult>;
}

export const createDeleteOperatorMutation = <TProps = {}>(options?: OperationOption<TProps, Mutation, DeleteOperatorMutationVariables, DeleteOperatorMutationResult>) =>
    graphql<TProps, Mutation, DeleteOperatorMutationVariables, DeleteOperatorMutationResult>(GraphQL.deleteOperator, {
        props({ mutate }): DeleteOperatorMutationResult {
            if (!mutate) {
                throw new Error("No mutate found");
            }

            return {
                // TODO: Add correct response return type
                async deleteOperator(variables: DeleteOperatorMutationVariables): Promise<MutationResult> {
                    try {
                        const result = await mutate({
                            variables
                        });

                        if (!result || !result.data) {
                            throw new Error("Result was empty");
                        }

                        // TODO: Add correct response
                        return result.data.deleteOperator;
                    } catch (error) {
                        throw error;
                    }
                }
            };
        },
        ...options
    });
