import { SizeSelect } from "@components/sizeSelect/sizeSelect";
import { useUpdateSamplings } from "@graphql/hocs/hooks/useUpdateSampling";
import { GetSamplingListQuery, Size } from "@models/graphql";
import { useFormatMessage } from "@utils/intlHook";
import { parseError } from "@utils/parseError";
import { message } from "antd";
import { FunctionComponent, useMemo } from "react";
import * as React from "react";
import { Role, usePermissions } from "@components/permission/permissionProvider";
import { isSizeHistory } from "@utils/samplingHistory";
import { User } from "oidc-client";
import { useReactOidc } from "@axa-fr/react-oidc-context";

export interface SamplingSizeSelectProps {
	sampling: {
		id: number;
		manualEntry: boolean;
		history: GetSamplingListQuery['samplings']['items'][number]['history']
		size: GetSamplingListQuery['samplings']['items'][number]['size'];
	};
}

export const SamplingSizeSelect: FunctionComponent<SamplingSizeSelectProps> = ({ sampling }) => {
	const [selectedSize, setSelectedSize] = React.useState<Size>(sampling.size);
	const formatMessage = useFormatMessage();
	const { hasRoles } = usePermissions();
	const { oidcUser }: { oidcUser: User } = useReactOidc();
	const { updateSamplings, isLoading } = useUpdateSamplings({
		refetchQueries: ["getSamples"]
	});

	const isCurrentUser = (user: GetSamplingListQuery['samplings']['items'][number]['history'][number]['user']): boolean => (
		user !== null && user.id !== oidcUser.profile.oid
	);

	const isSizeEditedByOtherUser = (historyObject: GetSamplingListQuery['samplings']['items'][number]['history'][number]): boolean => (
		isSizeHistory(historyObject) && !isCurrentUser(historyObject.user)
	);

	const isEditable: boolean = useMemo(() => {
		const isAdmin = hasRoles([Role.ADMIN]);
		const isManualEntry = sampling.manualEntry;
		const isSizeEditedByOtherUserBefore = !sampling.history.some(isSizeEditedByOtherUser);

		return isAdmin || !isManualEntry || !isSizeEditedByOtherUserBefore
	}, [sampling, oidcUser]);

	const onChange = async (size: Size) => {
		setSelectedSize(size);
		try {
			await updateSamplings({
				ids: [sampling.id],
				input: { size }
			});
			
			message.success(formatMessage({ id: "sampling.updateSizeSuccessful" }))
		} catch (error) {
			message.error(parseError(error, formatMessage));
		}
	}
	
	return (
		<SizeSelect
			loading={isLoading}
			disabled={!isEditable}
			value={selectedSize}
			onChange={onChange}
		/>
	)
}