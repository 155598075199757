import styled from "styled-components";

export const SpinnerStyle = styled.div`
    display:flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    padding: 2rem;

    span {
        margin-top: 10px;
    }
`;
