import { ApplianceCategorySelect } from "@components/applianceCategorySelect/applianceCategorySelect";
import { useGetPeriodAppliances } from "@graphql/hocs/hooks/useGetPeriodAppliances";
import { GetPeriodAppliancesQuery, GetApplianceCategoriesQuery } from "@models/graphql";
import { getText } from "@utils/getText";
import { parseError } from "@utils/parseError";
import { Alert, Input, Table, message } from "antd";
import { ColumnProps, PaginationConfig } from "antd/lib/table";
import * as React from "react";
import { FunctionComponent } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "redux";
import { useDebounce } from "use-debounce";
import { PeriodApplianceForm } from "./periodApplianceForm/periodApplianceForm";
import { PeriodApplianceTableStyle } from "./periodApplianceTableStyle";
import { useUpdatePeriodAppliance } from "@graphql/hocs/hooks/useUpdatePeriodAppliance";
import { StoreState } from "@redux/reducers/root";
import { connect } from "react-redux";

export interface PeriodApplianceTableProps {
    applianceListId?: number;
}

export const PeriodApplianceTableComponent: FunctionComponent<PeriodApplianceTableProps> = (props: PeriodApplianceTableProps & InjectedIntlProps & RouteComponentProps & WithReduxStateProps) => {
    const { language, intl, applianceListId } = props;

    const [pageSize, setPageSize] = React.useState(10);
    const [searchValue, setSearchValue] = React.useState("");
    const [debounceSearchValue] = useDebounce(searchValue, 400);
    const [paging, setPaging] = React.useState({ limit: 10, offset: 0 });

    const { updatePeriodAppliance } = useUpdatePeriodAppliance();

    const { periodAppliances, periodAppliancesCount, periodAppliancesError, periodAppliancesLoading } = useGetPeriodAppliances({
        variables: {
            paging,
            search: {
                language,
                value: debounceSearchValue
            },
            filter: {
                applianceListId
            }
        },
        skip: !applianceListId
    });

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        setPaging({
            limit: pageSize,
            offset: 0
        });
    };

    const onTableChange = (pagination: PaginationConfig) => {
        if (pagination.current && pagination.pageSize) {
            setPaging({
                limit: pagination.pageSize,
                offset: (pagination.current - 1) * pagination.pageSize
            });

            setPageSize(pagination.pageSize);
        }
    };

    const onPeriodApplianceHange = async (app: GetPeriodAppliancesQuery["periodAppliances"]["items"][0], appCat: GetApplianceCategoriesQuery["applianceCategories"]["items"][0]) => {
        if (!applianceListId) {
            return;
        }

        const { data, error } = await updatePeriodAppliance({
            id: app.id,
            input: {
                applianceCategoryId: appCat.id,
                applianceId: app.appliance.id,
                applianceListId: applianceListId
            }
        });

        if (data && data.updatePeriodAppliance) {
            message.success(intl.formatMessage({ id: "applianceForm.updated" }));
        }

        if (error) {
            message.error(parseError(error, intl));
        }
    };

    const columns: ColumnProps<GetPeriodAppliancesQuery["periodAppliances"]["items"][0]>[] = [
        {
            key: "titleNl",
            title: intl.formatMessage({ id: "appliances.titleNl" }),
            render(_, record) {
                return getText(record.appliance.name, "nl");
            }
        },
        {
            key: "titleFr",
            title: intl.formatMessage({ id: "appliances.titleFr" }),
            render(_, record) {
                return getText(record.appliance.name, "fr");
            }
        },
        {
            key: "applianceCategory",
            title: intl.formatMessage({ id: "periodAppliance.category" }),
            width: 300,
            render(_, record) {
                return <ApplianceCategorySelect
                    style={{ width: 300 }}
                    applianceListId={applianceListId}
                    onChange={(app) => onPeriodApplianceHange(record, app)}
                    value={record.applianceCategory}
                />;
            }
        }
    ];

    return (
        <PeriodApplianceTableStyle>
            <div className="subbar">
                <Input.Search
                    style={{ width: 300 }}
                    value={searchValue}
                    onChange={onSearch}
                    placeholder={intl.formatMessage({ id: "appliances.search" })}
                />

                <PeriodApplianceForm applianceListId={applianceListId} />
            </div>

            {periodAppliancesError && <Alert style={{ marginBottom: 15 }} type="error" message={parseError(periodAppliancesError, intl)} />}
            <Table
                columns={columns}
                dataSource={periodAppliances}
                loading={periodAppliancesLoading}
                rowKey="id"
                pagination={{
                    pageSize,
                    total: periodAppliancesCount,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    current: paging.offset / pageSize + 1
                }}
                onChange={onTableChange}
            />
        </PeriodApplianceTableStyle>
    );
};

export const mapStateToProps = (state: StoreState) => ({
    language: state.main.selectedLanguage
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

const withRedux = connect(mapStateToProps);

export const PeriodApplianceTable = compose<React.FunctionComponent<PeriodApplianceTableProps>>(
    injectIntl,
    withRouter,
    withRedux
)(PeriodApplianceTableComponent);
