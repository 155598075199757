import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "redux";

import { StoreState } from "@redux/reducers/root";
import { SamplingDetail } from "@pages/samplingDetail/samplingDetail";
import { SamplingsTableFilter } from "@components/samplings/samplingsTable/useSamplingsTableFilter";
import { NonNullableURLSearchParams } from "@utils/nonNullableURLSearchParams";

export interface SamplingDetailContainerProps {
    sampleId: number;
    filter: NonNullableURLSearchParams<SamplingsTableFilter>;
}

export const mapStateToProps = (state: StoreState) => ({
    language: state.main.selectedLanguage,
    previousPath: state.main.previousPath
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

const withRedux = connect(mapStateToProps);

export const SamplingDetailContainer = compose<React.ComponentClass<SamplingDetailContainerProps>>(
    withRedux,
    injectIntl,
    withRouter
)(SamplingDetail);

export type SamplingDetailProps =
    InjectedIntlProps &
    SamplingDetailContainerProps &
    RouteComponentProps &
    WithReduxStateProps;
