import { OperatorsTableProps } from "@components/operators/operatorsTable/operatorsTableContainer";
import { Actions, OperatorsTableStyle } from "@components/operators/operatorsTable/operatorsTableStyle";
import { Operator } from "@models/graphql";
import { parseError } from "@utils/parseError";
import { Icon, message, Popconfirm, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import { autobind } from "core-decorators";
import * as React from "react";

export interface OperatorsTableState {
}

@autobind
export class OperatorsTable extends React.Component<OperatorsTableProps, OperatorsTableState> {

    public columns: ColumnProps<Operator>[] = [
        {
            title: this.props.intl.formatMessage({ id: "operators.table.firstname" }),
            dataIndex: "firstName"
        },
        {
            title: this.props.intl.formatMessage({ id: "operators.table.lastname" }),
            dataIndex: "lastName"
        },
        {
            title: this.props.intl.formatMessage({ id: "operators.table.site" }),
            dataIndex: "site"
        },
        {
            title: this.props.intl.formatMessage({ id: "operators.table.stationName" }),
            dataIndex: "stationName"
        },
        {
            title: this.props.intl.formatMessage({ id: "operators.table.language" }),
            dataIndex: "language"

        },
        {
            title: this.props.intl.formatMessage({ id: "operators.table.actions" }),
            dataIndex: "actions",
            render: (a, r) => this.renderActions(r)
        }
    ];

    public render() {

        const { operators, operatorsLoading, pageSize, currentPage, operatorsCount } = this.props;

        return (
            <OperatorsTableStyle>
                <Table
                    loading={operatorsLoading}
                    rowKey="id"
                    dataSource={operators}
                    columns={this.columns}
                    pagination={
                        {
                            pageSize,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            position: "both",
                            hideOnSinglePage: false,
                            total: operatorsCount,
                            current: currentPage
                        }
                    }
                />
            </OperatorsTableStyle>
        );
    }

    private renderActions(record: Operator) {
        const { intl } = this.props;

        return (
            <Actions>
                <Icon onClick={() => this.props.changeMode("modify", record)} type="edit" />
                <Popconfirm onConfirm={() => this.deleteOperator(record.id)} title={intl.formatMessage({ id: "deleteOperator.confirm" })}>
                    <Icon type="delete" />
                </Popconfirm>
            </Actions>
        );
    }

    private async deleteOperator(id: number) {
        const { intl, deleteOperator } = this.props;
        try {
            await deleteOperator({
                id
            });
            message.info(intl.formatMessage({ id: "deleteOperator.succesfullyUpdated" }));
        } catch (err) {
            console.log(err);

            message.error(parseError(err, intl));
        }
    }
}
