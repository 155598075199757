import * as React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect, Dispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "redux";

import { StoreState } from "@redux/reducers/root";
import { Action } from "@redux/actions/action";
import { SamplingFilter } from "@components/samplings/samplingFilter/samplingFilter";

export interface SamplingFilterContainerProps {
}

export const mapStateToProps = (state: StoreState, ownProps: SamplingFilterContainerProps) => ({

});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const SamplingFilterContainer = compose<React.ComponentClass<SamplingFilterContainerProps>>(
    withRedux,
    injectIntl,
    withRouter
)(SamplingFilter);

export type SamplingFilterProps =
    InjectedIntlProps &
    SamplingFilterContainerProps &
    WithReduxStateProps &
    WithReduxDispatchProps &
    RouteComponentProps;
