import * as React from "react";
import { FunctionComponent } from "react";
import { ReactNode } from "react-redux";

import { Role, usePermissions } from "@components/permission/permissionProvider";

export interface PermissionProps {
    fallback?: ReactNode;
}

export const Permission: FunctionComponent<PermissionProps> = ({ children, fallback }) => {
    const { roles } = usePermissions();

    if (!roles.has(Role.ADMIN)) {
        if (fallback) {
            return fallback;
        }

        return null;
    }

    return children as any;
};
