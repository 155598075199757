import { theme } from "@style/theme";
import styled from "styled-components";

export const ApplianceSettingsStyle = styled.div`
    .ant-form {
        display: flex;
        align-items: flex-end;
    }

    .ant-btn {
        margin-bottom: 28px;
    }

    .ant-form-item {
        margin-right: 15px;
    }
`;

export const SettingsHeader = styled.h1`
    margin: 0px;
    color: ${theme.primary};
`;
