import { DatePicker, Input, Select } from "antd";
import * as React from "react";

import { debounce } from "@utils/debounce";
import {
    Fraction,
    TransportFormStyle
} from "@components/transports/transportForm/transportFormStyle";
import { TransportFormProps } from "@components/transports/transportForm/transportFormContainer";
import { FilterSubTitle } from "@components/samplings/samplingFilter/samplingFilterStyle";
import { FormattedMessage } from "react-intl";
import * as moment from "moment";
import {
    TransportHeader,
    TransportTitle
} from "@pages/transports/transportsStyle";
import { TransportStatus } from "@models/graphql";

export const TransportForm: React.FunctionComponent<TransportFormProps> = ({
    intl,
    filter
}) => {
    const onSearch = (term: string) => {
        filter.set("transportNumber", term);
        filter.set("page", 1);
    };

    const onFractionChange = (fraction: string) => {
        filter.set("processingGroup", fraction || "");
        filter.set("page", 1);
    };

    const onFromChange = (date: moment.Moment) => {
        const newDate = date ? date.toDate() : undefined;
        filter.set("fromEstimatedArrivalDate", newDate);
        filter.set("page", 1);
    };

    const onToChange = (date: moment.Moment) => {
        const newDate = date ? date.toDate() : undefined;
        filter.set("toEstimatedArrivalDate", newDate);
        filter.set("page", 1);
    };

    const onStatusChange = (values: string[] | null) => {
        filter.set("statusses", (values as TransportStatus[]) || undefined);
        filter.set("page", 1);
    };

    const onSiteChange = (value: string | undefined) => {
        filter.set("site", value);
        filter.set("page", 1);
    };

    const debouncedSearch = debounce(onSearch, 400) as (val) => void;

    return (
        <TransportFormStyle>
            <TransportHeader>
                <TransportTitle>
                    <FormattedMessage id="transports" />
                </TransportTitle>
            </TransportHeader>

            <FilterSubTitle>
                <FormattedMessage id="transports.number" />
            </FilterSubTitle>
            <Input.Search
                key="search"
                onChange={e => debouncedSearch(e.target.value)}
                placeholder={intl.formatMessage({ id: "transports.number" })}
                defaultValue={filter.get("transportNumber")}
            />
            <FilterSubTitle>
                <FormattedMessage id="transports.processingfraction" />
            </FilterSubTitle>
            <Fraction
                onChange={onFractionChange}
                placeholder={intl.formatMessage({
                    id: "transports.processingfraction"
                })}
                defaultValue={filter.get("processingGroup")}
                allowClear={true}
            >
                <Select.Option value="bw">BW</Select.Option>
                <Select.Option value="cf">CF</Select.Option>
                <Select.Option value="tvm">TVM</Select.Option>
                <Select.Option value="oth">OTH</Select.Option>
            </Fraction>
            <FilterSubTitle>
                <FormattedMessage id="transports.expectedDate" />
            </FilterSubTitle>
            <DatePicker
                allowClear={true}
                format="DD/MM/YYYY"
                onChange={onFromChange}
                value={
                    filter.get("fromEstimatedArrivalDate") !== undefined
                        ? moment(filter.get("fromEstimatedArrivalDate"))
                        : undefined
                }
            />
            <DatePicker
                allowClear={true}
                format="DD/MM/YYYY"
                onChange={onToChange}
                value={
                    filter.get("toEstimatedArrivalDate") !== undefined
                        ? moment(filter.get("toEstimatedArrivalDate"))
                        : undefined
                }
            />
            <FilterSubTitle>
                <FormattedMessage id="transports.state" />
            </FilterSubTitle>
            <Select
                onChange={onStatusChange}
                placeholder={intl.formatMessage({ id: "transports.state" })}
                defaultValue={filter.get("statusses")}
                mode="multiple"
            >
                <Select.Option value="OPEN">
                    {intl.formatMessage({ id: "transports.open" })}
                </Select.Option>
                <Select.Option value="PROCESSING">
                    {intl.formatMessage({ id: "transports.processing" })}
                </Select.Option>
                <Select.Option value="DONE">
                    {intl.formatMessage({ id: "transports.done" })}
                </Select.Option>
            </Select>
            <FilterSubTitle>
                <FormattedMessage id="transports.site" />
            </FilterSubTitle>
            <Select
                onChange={onSiteChange}
                placeholder={intl.formatMessage({ id: "transports.site" })}
                defaultValue={filter.get("site")}
                allowClear={true}
            >
                <Select.Option value="Mirto">Mirto</Select.Option>
                <Select.Option value="Hautes Ardennes">
                    Hautes Ardennes
                </Select.Option>
            </Select>
        </TransportFormStyle>
    );
};
