import { Header } from "@components/header/header";
import { logout } from "@redux/actions/authActions/logout";
import { StoreState } from "@redux/reducers/root";
import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";
import { setLanguage, SetLanguageActionOptions } from "@redux/actions/setLanguage";
import { withOidcUser } from "@axa-fr/react-oidc-context";
import { User } from "oidc-client";

export interface HeaderContainerProps {
    currentPath: string;
}

export const mapStateToProps = (state: StoreState, ownProps: HeaderContainerProps) => ({
    selectedLanguage: state.main.selectedLanguage
});

export type WithReduxStateProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<{ type: string }>) => ({
    logout() { dispatch(logout()); },
    setLanguage(data: SetLanguageActionOptions) { dispatch(setLanguage(data)); }
});

export type WithReduxDispatchProps = ReturnType<typeof mapDispatchToProps>;

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const HeaderContainer = compose<React.ComponentClass<HeaderContainerProps>>(
    withRedux,
    withOidcUser
)(Header);

export type HeaderProps =
    HeaderContainerProps &
    WithReduxDispatchProps &
    WithReduxStateProps &
    { oidcUser: User }

    ;
