import { theme } from "@style/theme";
import styled from "styled-components";

export const ApplianceFormStyle = styled.div`
    a {
        span {
            color: ${theme.primary};
            padding-left: 15px;
        }
    }


    .bar {
        display: flex;
        justify-content: space-between;
    }
`;
