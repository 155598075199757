import { TransportFormContainer } from "@components/transports/transportForm/transportFormContainer";
import {
    TransportTable,
    TransportTableItem
} from "@components/transports/transportTable/transportTable";
import { useTransportsTableFilter } from "@components/transports/transportTable/useTransportsTableFilter";
import { getPercentageApproved } from "@graphql/hocs/hooks/getPercentageApproved";
import { getPercentageEnteredManually } from "@graphql/hocs/hooks/getPercentageEnteredManually";
import { TransportFilterInput } from "@models/graphql";
import {
    Percentage,
    TransportCorrectionPercentage,
    TransportHeader,
    TransportsStyle
} from "@pages/transports/transportsStyle";
import * as React from "react";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "redux";

export interface TransportsState {
    data: TransportTableItem[] | null;
    filteredData: TransportTableItem[] | null;
    filter: TransportFilterInput;
}

export const TransportsComponent: React.FunctionComponent<
    RouteComponentProps<{ page: string }>
> = (props: RouteComponentProps<{ page: string }>) => {
    const filter = useTransportsTableFilter();
    const { percentageEnteredManually } = getPercentageEnteredManually();
    const { percentageApproved } = getPercentageApproved();

    return (
        <TransportsStyle>
            <aside>
                <TransportFormContainer filter={filter} />
            </aside>
            <main>
                <TransportHeader>
                    <div />

                    <TransportCorrectionPercentage>
                        <FormattedMessage id="transports.average" />
                        <Percentage>
                            <FormattedNumber
                                maximumFractionDigits={2}
                                value={
                                    percentageEnteredManually
                                        ? percentageEnteredManually * 100
                                        : 0
                                }
                            />
                            %
                        </Percentage>
                    </TransportCorrectionPercentage>
                    <TransportCorrectionPercentage>
                        <FormattedMessage id="transports.averageApproved" />
                        <Percentage>
                            <FormattedNumber
                                maximumFractionDigits={2}
                                value={
                                    percentageApproved
                                        ? percentageApproved * 100
                                        : 0
                                }
                            />
                            %
                        </Percentage>
                    </TransportCorrectionPercentage>
                </TransportHeader>
                <TransportTable filter={filter} />
            </main>
        </TransportsStyle>
    );
};

export const Transports = compose<React.FunctionComponent>(
    injectIntl,
    withRouter
)(TransportsComponent);
