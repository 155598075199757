import styled from "styled-components";
import { Select, AutoComplete } from "antd";

export const TransportFormStyle = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 24px;

    .ant-input-search {
        margin-bottom: 16px;
    }

    .ant-select {
        margin-bottom: 16px;
    }

    .ant-calendar-picker {
        margin-bottom: 12px;
    }
`;

export const Fraction = styled(Select)``;

export const SearchAutocomplete = styled(AutoComplete)`
    width: 90% !important;
    margin-right: 10px !important;
`;
