/* tslint:disable */

type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: number;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Date: string;
    DateTime: string;
    Upload: File | FileList | Blob;
    JSON: any;
    Time: any;
};

export type Appliance = {
    id: Scalars["Int"];
    name: Array<Translation>;
    description: Array<Translation>;
    processingFraction: Scalars["String"];
    processingCategory: Scalars["String"];
    previousAppliance?: Maybe<Appliance>;
};

export type ApplianceCategoriesQueryResult = {
    items: Array<ApplianceCategory>;
    count: Scalars["Int"];
};

export type ApplianceCategory = {
    id: Scalars["Int"];
    name: Scalars["String"];
};

export type ApplianceCategoryFilterInput = {
    applianceListId?: Maybe<Scalars["Int"]>;
};

export type ApplianceCategoryInput = {
    name: Scalars["String"];
    applianceListId: Scalars["Int"];
};

export type ApplianceHistory = {
    user?: Maybe<User>;
    timestamp: Scalars["DateTime"];
    type: HistoryType;
    oldAppliance?: Maybe<Appliance>;
    newAppliance: Appliance;
};

export type ApplianceInput = {
    name: Array<TranslationInput>;
    description: Array<TranslationInput>;
    previousApplianceId?: Maybe<Scalars["Int"]>;
};

export type ApplianceList = {
    id: Scalars["Int"];
    name: Scalars["String"];
    activeFrom: Scalars["Date"];
    activeTo: Scalars["Date"];
};

export type ApplianceListInput = {
    title: Scalars["String"];
    activeFrom: Scalars["Date"];
    activeTo: Scalars["Date"];
};

export type ApplianceListsQueryResult = {
    items: Array<ApplianceList>;
    count: Scalars["Int"];
};

export type ApplianceSearchInput = {
    language: Scalars["String"];
    value: Scalars["String"];
};

export type AppliancesListsFilterInput = {
    name?: Maybe<Scalars["String"]>;
};

export type AppliancesQueryResult = {
    items: Array<Appliance>;
    count: Scalars["Int"];
};

export type AppliancestFilterInput = {
    notInApplianceListId?: Maybe<Scalars["Int"]>;
};

export type ApplianceSuggestion = {
    appliance: Appliance;
    probability: Scalars["Float"];
};

export type ApprovedHistory = {
    user?: Maybe<User>;
    timestamp: Scalars["DateTime"];
    type: HistoryType;
    oldApproved?: Maybe<Scalars["Boolean"]>;
    newApproved: Scalars["Boolean"];
};

export type Brand = {
    id: Scalars["Int"];
    name: Scalars["String"];
};

export type BrandHistory = {
    user?: Maybe<User>;
    timestamp: Scalars["DateTime"];
    type: HistoryType;
    oldBrand?: Maybe<Brand>;
    newBrand: Brand;
};

export type Camera = {
    id: Scalars["Int"];
    ip: Scalars["String"];
    orientation: Orientation;
    scaleType: ScaleType;
    position: Scalars["String"];
    site: Scalars["String"];
    deviceId: Scalars["String"];
};

export type CameraFilterInput = {
    id?: Maybe<Scalars["Int"]>;
    site?: Maybe<Scalars["String"]>;
    deviceId?: Maybe<Scalars["String"]>;
};

export type CreateIrConfigurationInput = {
    minProbabilityForApproval: Scalars["Float"];
};

export type CreateOperatorInput = {
    firstName: Scalars["String"];
    lastName: Scalars["String"];
    site: Scalars["String"];
    language: Scalars["String"];
    stationName?: Maybe<Scalars["String"]>;
    stationLocation: Scalars["String"];
};

export type CreateSamplingImageInput = {
    cameraId: Scalars["Int"];
    image: Scalars["Upload"];
};

export type CreateSamplingInput = {
    manualEntry: Scalars["Boolean"];
    weight: Scalars["Float"];
    operatorId: Scalars["Int"];
    shipmentId: Scalars["Int"];
    transportId: Scalars["Int"];
    scaleType: ScaleType;
    timeSampled: Scalars["DateTime"];
    images: Array<CreateSamplingImageInput>;
    site: Scalars["String"];
};

export type GalleryInfo = {
    nextId?: Maybe<Scalars["Int"]>;
    previousId?: Maybe<Scalars["Int"]>;
    currentPlace: Scalars["Int"];
    totalItems: Scalars["Int"];
};

export enum HistoryType {
    Appliance = "APPLIANCE",
    Size = "SIZE",
    Brand = "BRAND",
    Approved = "APPROVED"
}

export type Image = {
    cameraName: Scalars["String"];
    url: Scalars["String"];
    thumbnailUrl: Scalars["String"];
};

export type IrConfiguration = {
    id: Scalars["Int"];
    minProbabilityForApproval: Scalars["Float"];
};

export type IrConfigurationFilterInput = {
    ids?: Maybe<Array<Scalars["Int"]>>;
};

export type IrConfigurationsQueryResult = {
    items: Array<IrConfiguration>;
    count: Scalars["Int"];
};

export type Mutation = {
    createAppliance: Appliance;
    updateAppliance: Appliance;
    createApplianceCategory: ApplianceCategory;
    updateApplianceCategory: ApplianceCategory;
    deleteApplianceCategory: MutationResult;
    createApplianceList: ApplianceList;
    updateApplianceList: ApplianceList;
    deleteApplianceList: MutationResult;
    duplicateApplianceList: ApplianceList;
    createIrConfiguration: IrConfiguration;
    updateIrConfiguration: IrConfiguration;
    deleteIrConfiguration: MutationResult;
    createOperator: Operator;
    updateOperator: Operator;
    deleteOperator: MutationResult;
    createPeriodAppliance: PeriodAppliance;
    updatePeriodAppliance: PeriodAppliance;
    createSampling: MutationResult;
    updateSamplings: Array<Sampling>;
    deleteSamplings: MutationResult;
    updateShipmentStatus: Shipment;
    updateMovedToShipmentNumber: Shipment;
};

export type MutationCreateApplianceArgs = {
    input: ApplianceInput;
};

export type MutationUpdateApplianceArgs = {
    id: Scalars["Int"];
    input: ApplianceInput;
};

export type MutationCreateApplianceCategoryArgs = {
    input: ApplianceCategoryInput;
};

export type MutationUpdateApplianceCategoryArgs = {
    id: Scalars["Int"];
    input: ApplianceCategoryInput;
};

export type MutationDeleteApplianceCategoryArgs = {
    id: Scalars["Int"];
};

export type MutationCreateApplianceListArgs = {
    input?: Maybe<ApplianceListInput>;
};

export type MutationUpdateApplianceListArgs = {
    id: Scalars["Int"];
    input?: Maybe<ApplianceListInput>;
};

export type MutationDeleteApplianceListArgs = {
    id: Scalars["Int"];
};

export type MutationDuplicateApplianceListArgs = {
    id: Scalars["Int"];
    input: ApplianceListInput;
};

export type MutationCreateIrConfigurationArgs = {
    input?: Maybe<CreateIrConfigurationInput>;
};

export type MutationUpdateIrConfigurationArgs = {
    id: Scalars["Int"];
    input?: Maybe<UpdateIrConfigurationInput>;
};

export type MutationDeleteIrConfigurationArgs = {
    id: Scalars["Int"];
};

export type MutationCreateOperatorArgs = {
    input?: Maybe<CreateOperatorInput>;
};

export type MutationUpdateOperatorArgs = {
    id: Scalars["Int"];
    input?: Maybe<UpdateOperatorInput>;
};

export type MutationDeleteOperatorArgs = {
    id: Scalars["Int"];
};

export type MutationCreatePeriodApplianceArgs = {
    input: PeriodApplianceInput;
};

export type MutationUpdatePeriodApplianceArgs = {
    id: Scalars["Int"];
    input: PeriodApplianceInput;
};

export type MutationCreateSamplingArgs = {
    input: CreateSamplingInput;
};

export type MutationUpdateSamplingsArgs = {
    ids: Array<Scalars["Int"]>;
    input?: Maybe<UpdateSamplingInput>;
};

export type MutationDeleteSamplingsArgs = {
    ids: Array<Scalars["Int"]>;
};

export type MutationUpdateShipmentStatusArgs = {
    id: Scalars["Int"];
    shipmentStatus: ShipmentStatus;
};

export type MutationUpdateMovedToShipmentNumberArgs = {
    id: Scalars["Int"];
    shipmentNumber: Scalars["String"];
};

export type MutationResult = {
    success: Scalars["Boolean"];
};

export type Operator = {
    id: Scalars["Int"];
    firstName: Scalars["String"];
    lastName: Scalars["String"];
    site: Scalars["String"];
    stationName?: Maybe<Scalars["String"]>;
    stationLocation: Scalars["String"];
    language: Scalars["String"];
};

export type OperatorFilterInput = {
    id?: Maybe<Scalars["Int"]>;
    site?: Maybe<Scalars["String"]>;
};

export type OperatorsQueryResult = {
    items: Array<Operator>;
    count: Scalars["Int"];
};

export enum Orientation {
    Landscape = "LANDSCAPE",
    Portrait = "PORTRAIT"
}

export type PagingInput = {
    offset: Scalars["Int"];
    limit: Scalars["Int"];
};

export type PeriodAppliance = {
    id: Scalars["Int"];
    appliance: Appliance;
    applianceCategory: ApplianceCategory;
};

export type PeriodApplianceInput = {
    applianceId: Scalars["Int"];
    applianceCategoryId: Scalars["Int"];
    applianceListId: Scalars["Int"];
};

export type PeriodApplianceSearchInput = {
    language: Scalars["String"];
    value: Scalars["String"];
};

export type PeriodAppliancesQueryResult = {
    items: Array<PeriodAppliance>;
    count: Scalars["Int"];
};

export type PeriodAppliancestFilterInput = {
    applianceListId?: Maybe<Scalars["Int"]>;
};

export type Profile = {
    groups: Array<Scalars["String"]>;
};

export type Query = {
    appliances: AppliancesQueryResult;
    appliance: Appliance;
    applianceCategories: ApplianceCategoriesQueryResult;
    applianceLists: ApplianceListsQueryResult;
    applianceList: ApplianceList;
    brands: Array<Brand>;
    cameras: Array<Camera>;
    irConfigurations: IrConfigurationsQueryResult;
    operators: OperatorsQueryResult;
    periodAppliances: PeriodAppliancesQueryResult;
    samplings: SamplingsQueryResult;
    percentageEnteredManually: Scalars["Float"];
    percentageApproved: Scalars["Float"];
    profile: Profile;
    shipments: ShipmentsQueryResult;
    transports: TransportsQueryResult;
};

export type QueryAppliancesArgs = {
    sort?: Maybe<Array<SortInput>>;
    paging?: Maybe<PagingInput>;
    filter?: Maybe<AppliancestFilterInput>;
    search?: Maybe<ApplianceSearchInput>;
};

export type QueryApplianceArgs = {
    id: Scalars["Int"];
};

export type QueryApplianceCategoriesArgs = {
    sort?: Maybe<Array<SortInput>>;
    paging?: Maybe<PagingInput>;
    search?: Maybe<Scalars["String"]>;
    filter?: Maybe<ApplianceCategoryFilterInput>;
};

export type QueryApplianceListsArgs = {
    sort?: Maybe<Array<SortInput>>;
    paging?: Maybe<PagingInput>;
    filter?: Maybe<AppliancesListsFilterInput>;
};

export type QueryApplianceListArgs = {
    id: Scalars["Int"];
};

export type QueryCamerasArgs = {
    site?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<SortInput>>;
    paging?: Maybe<PagingInput>;
    filter?: Maybe<CameraFilterInput>;
};

export type QueryIrConfigurationsArgs = {
    sort?: Maybe<Array<SortInput>>;
    paging?: Maybe<PagingInput>;
    filter?: Maybe<IrConfigurationFilterInput>;
};

export type QueryOperatorsArgs = {
    sort?: Maybe<Array<SortInput>>;
    paging?: Maybe<PagingInput>;
    filter?: Maybe<OperatorFilterInput>;
};

export type QueryPeriodAppliancesArgs = {
    sort?: Maybe<Array<SortInput>>;
    paging?: Maybe<PagingInput>;
    filter?: Maybe<PeriodAppliancestFilterInput>;
    search?: Maybe<PeriodApplianceSearchInput>;
};

export type QuerySamplingsArgs = {
    sort?: Maybe<Array<SortInput>>;
    paging?: Maybe<PagingInput>;
    filter?: Maybe<SamplingsFilterInput>;
};

export type QueryShipmentsArgs = {
    sort?: Maybe<Array<SortInput>>;
    paging?: Maybe<PagingInput>;
    filter?: Maybe<ShipmentFilterInput>;
};

export type QueryTransportsArgs = {
    sort?: Maybe<Array<SortInput>>;
    paging?: Maybe<PagingInput>;
    filter?: Maybe<TransportFilterInput>;
};

export type Sampling = {
    id: Scalars["Int"];
    timeSampled: Scalars["DateTime"];
    appliance?: Maybe<Appliance>;
    applianceSuggestions: Array<ApplianceSuggestion>;
    manualEntry: Scalars["Boolean"];
    approved: Scalars["Boolean"];
    systemApproved: Scalars["Boolean"];
    probability?: Maybe<Scalars["Float"]>;
    operator: Operator;
    scaleType: ScaleType;
    weight: Scalars["Float"];
    transport: Transport;
    shipment: Shipment;
    size: Size;
    images: Array<Image>;
    galleryInfo: GalleryInfo;
    brand?: Maybe<Brand>;
    history: Array<SamplingHistory>;
};

export type SamplingGalleryInfoArgs = {
    filter?: Maybe<SamplingsFilterInput>;
    sort?: Maybe<Array<SortInput>>;
};

export type SamplingHistory =
    | ApplianceHistory
    | SizeHistory
    | BrandHistory
    | ApprovedHistory;

export type SamplingsFilterInput = {
    transportIds?: Maybe<Array<Scalars["Int"]>>;
    shipmentIds?: Maybe<Array<Scalars["Int"]>>;
    applianceIds?: Maybe<Array<Scalars["Int"]>>;
    manualEntry?: Maybe<Scalars["Boolean"]>;
    approved?: Maybe<Scalars["Boolean"]>;
    systemApproved?: Maybe<Scalars["Boolean"]>;
    applianceCategoryIds?: Maybe<Array<Scalars["Int"]>>;
    ids?: Maybe<Array<Scalars["Int"]>>;
    minimumMatchingPercentage?: Maybe<Scalars["Float"]>;
    maximumMatchingPercentage?: Maybe<Scalars["Float"]>;
    fromTimeSampled?: Maybe<Scalars["DateTime"]>;
    toTimeSampled?: Maybe<Scalars["DateTime"]>;
    sizes?: Maybe<Array<Size>>;
    minimumWeight?: Maybe<Scalars["Float"]>;
    maximumWeight?: Maybe<Scalars["Float"]>;
    brandIds?: Maybe<Array<Scalars["Int"]>>;
};

export type SamplingsQueryResult = {
    items: Array<Sampling>;
    count: Scalars["Int"];
};

export enum ScaleType {
    Heavy = "HEAVY",
    Fine = "FINE"
}

export type Shipment = {
    id: Scalars["Int"];
    shipmentNumber: Scalars["String"];
    type: ShipmentType;
    status: ShipmentStatus;
    movedToShipmentNumber?: Maybe<Scalars["String"]>;
    transport: Transport;
    samplings: Array<Sampling>;
    updatedOn: Scalars["DateTime"];
};

export type ShipmentFilterInput = {
    transportId?: Maybe<Scalars["Int"]>;
    shipmentNumber?: Maybe<Scalars["String"]>;
    hasSamplings?: Maybe<Scalars["Boolean"]>;
    site?: Maybe<Scalars["String"]>;
};

export type ShipmentsQueryResult = {
    items: Array<Shipment>;
    count: Scalars["Int"];
};

export enum ShipmentStatus {
    Open = "OPEN",
    Processing = "PROCESSING",
    Done = "DONE"
}

export enum ShipmentType {
    Box_Pallet = "BOX_PALLET",
    Container = "CONTAINER"
}

export enum Size {
    Big = "BIG",
    Small = "SMALL"
}

export type SizeHistory = {
    user?: Maybe<User>;
    timestamp: Scalars["DateTime"];
    type: HistoryType;
    oldSize?: Maybe<Size>;
    newSize: Size;
};

export type SortInput = {
    field: Scalars["String"];
    order: SortOrder;
};

export enum SortOrder {
    Asc = "ASC",
    Desc = "DESC"
}

export type Subscription = {
    samplingUpdated: Sampling;
    shipmentUpdated: Shipment;
    transportUpdated: Transport;
};

export type SubscriptionSamplingUpdatedArgs = {
    timeSampled: Scalars["DateTime"];
};

export type SubscriptionShipmentUpdatedArgs = {
    filter?: Maybe<ShipmentFilterInput>;
};

export type SubscriptionTransportUpdatedArgs = {
    filter?: Maybe<TransportFilterInput>;
};

export type Translation = {
    language: Scalars["String"];
    value: Scalars["String"];
};

export type TranslationInput = {
    language: Scalars["String"];
    value: Scalars["String"];
};

export type Transport = {
    id: Scalars["Int"];
    transportNumber: Scalars["String"];
    processingFraction: Scalars["String"];
    status: TransportStatus;
    percentageEnteredManually: Scalars["Float"];
    percentageApproved: Scalars["Float"];
    estimatedArrivalDate: Scalars["Date"];
    shipmentType: ShipmentType;
    updatedOn: Scalars["DateTime"];
    site: Scalars["String"];
};

export type TransportFilterInput = {
    id?: Maybe<Scalars["Int"]>;
    transportNumber?: Maybe<Scalars["String"]>;
    processingGroup?: Maybe<Scalars["String"]>;
    hasSamplings?: Maybe<Scalars["Boolean"]>;
    site?: Maybe<Scalars["String"]>;
    fromEstimatedArrivalDate?: Maybe<Scalars["DateTime"]>;
    toEstimatedArrivalDate?: Maybe<Scalars["DateTime"]>;
    statusses?: Maybe<Array<TransportStatus>>;
    shipmentTypes?: Maybe<Array<ShipmentType>>;
};

export type TransportsQueryResult = {
    items: Array<Transport>;
    count: Scalars["Int"];
};

export enum TransportStatus {
    Open = "OPEN",
    Processing = "PROCESSING",
    Done = "DONE"
}

export type UpdateIrConfigurationInput = {
    minProbabilityForApproval?: Maybe<Scalars["Float"]>;
};

export type UpdateOperatorInput = {
    firstName?: Maybe<Scalars["String"]>;
    lastName?: Maybe<Scalars["String"]>;
    site?: Maybe<Scalars["String"]>;
    language?: Maybe<Scalars["String"]>;
    stationName?: Maybe<Scalars["String"]>;
    stationLocation: Scalars["String"];
};

export type UpdateSamplingInput = {
    size?: Maybe<Size>;
    manualEntry?: Maybe<Scalars["Boolean"]>;
    applianceId?: Maybe<Scalars["Int"]>;
    brandId?: Maybe<Scalars["Int"]>;
    approved?: Maybe<Scalars["Boolean"]>;
};

export type User = {
    id: Scalars["String"];
    name: Scalars["String"];
};
export type ApplianceFragment = { __typename?: "Appliance" } & Pick<
    Appliance,
    "id" | "processingFraction"
> & {
        name: Array<
            { __typename?: "Translation" } & Pick<
                Translation,
                "language" | "value"
            >
        >;
    };

export type ApplianceListFragment = { __typename?: "ApplianceList" } & Pick<
    ApplianceList,
    "id" | "name" | "activeFrom" | "activeTo"
>;

export type ImageFragment = { __typename?: "Image" } & Pick<
    Image,
    "cameraName" | "url" | "thumbnailUrl"
>;

export type CreateApplianceMutationVariables = {
    input: ApplianceInput;
};

export type CreateApplianceMutation = { __typename?: "Mutation" } & {
    createAppliance: { __typename?: "Appliance" } & Pick<Appliance, "id"> & {
            name: Array<
                { __typename?: "Translation" } & Pick<
                    Translation,
                    "language" | "value"
                >
            >;
            previousAppliance: Maybe<
                { __typename?: "Appliance" } & Pick<Appliance, "id"> & {
                        name: Array<
                            { __typename?: "Translation" } & Pick<
                                Translation,
                                "language" | "value"
                            >
                        >;
                    }
            >;
        };
};

export type CreateApplianceCategoryMutationVariables = {
    input: ApplianceCategoryInput;
};

export type CreateApplianceCategoryMutation = { __typename?: "Mutation" } & {
    createApplianceCategory: { __typename?: "ApplianceCategory" } & Pick<
        ApplianceCategory,
        "id" | "name"
    >;
};

export type CreateApplianceListMutationVariables = {
    input: ApplianceListInput;
};

export type CreateApplianceListMutation = { __typename?: "Mutation" } & {
    createApplianceList: { __typename?: "ApplianceList" } & Pick<
        ApplianceList,
        "id" | "name" | "activeFrom" | "activeTo"
    >;
};

export type CreateIrConfigurationMutationVariables = {
    input?: Maybe<CreateIrConfigurationInput>;
};

export type CreateIrConfigurationMutation = { __typename?: "Mutation" } & {
    createIrConfiguration: { __typename?: "IrConfiguration" } & Pick<
        IrConfiguration,
        "id" | "minProbabilityForApproval"
    >;
};

export type CreateOperatorMutationVariables = {
    input?: Maybe<CreateOperatorInput>;
};

export type CreateOperatorMutation = { __typename?: "Mutation" } & {
    createOperator: { __typename?: "Operator" } & Pick<Operator, "id">;
};

export type CreatePeriodApplianceMutationVariables = {
    input: PeriodApplianceInput;
};

export type CreatePeriodApplianceMutation = { __typename?: "Mutation" } & {
    createPeriodAppliance: { __typename?: "PeriodAppliance" } & Pick<
        PeriodAppliance,
        "id"
    >;
};

export type DeleteApplianceCategoryMutationVariables = {
    id: Scalars["Int"];
};

export type DeleteApplianceCategoryMutation = { __typename?: "Mutation" } & {
    deleteApplianceCategory: { __typename?: "MutationResult" } & Pick<
        MutationResult,
        "success"
    >;
};

export type DeleteApplianceListMutationVariables = {
    id: Scalars["Int"];
};

export type DeleteApplianceListMutation = { __typename?: "Mutation" } & {
    deleteApplianceList: { __typename?: "MutationResult" } & Pick<
        MutationResult,
        "success"
    >;
};

export type DeleteIrConfigurationMutationVariables = {
    id: Scalars["Int"];
};

export type DeleteIrConfigurationMutation = { __typename?: "Mutation" } & {
    deleteIrConfiguration: { __typename?: "MutationResult" } & Pick<
        MutationResult,
        "success"
    >;
};

export type DeleteOperatorMutationVariables = {
    id: Scalars["Int"];
};

export type DeleteOperatorMutation = { __typename?: "Mutation" } & {
    deleteOperator: { __typename?: "MutationResult" } & Pick<
        MutationResult,
        "success"
    >;
};

export type DeleteSamplingsMutationVariables = {
    ids: Array<Scalars["Int"]>;
};

export type DeleteSamplingsMutation = { __typename?: "Mutation" } & {
    deleteSamplings: { __typename?: "MutationResult" } & Pick<
        MutationResult,
        "success"
    >;
};

export type DuplicateApplianceListMutationVariables = {
    id: Scalars["Int"];
    input: ApplianceListInput;
};

export type DuplicateApplianceListMutation = { __typename?: "Mutation" } & {
    duplicateApplianceList: { __typename?: "ApplianceList" } & Pick<
        ApplianceList,
        "id" | "name" | "activeFrom" | "activeTo"
    >;
};

export type UpdateApplianceMutationVariables = {
    id: Scalars["Int"];
    input: ApplianceInput;
};

export type UpdateApplianceMutation = { __typename?: "Mutation" } & {
    updateAppliance: { __typename?: "Appliance" } & Pick<Appliance, "id"> & {
            name: Array<
                { __typename?: "Translation" } & Pick<
                    Translation,
                    "language" | "value"
                >
            >;
            description: Array<
                { __typename?: "Translation" } & Pick<
                    Translation,
                    "language" | "value"
                >
            >;
            previousAppliance: Maybe<
                { __typename?: "Appliance" } & Pick<Appliance, "id"> & {
                        name: Array<
                            { __typename?: "Translation" } & Pick<
                                Translation,
                                "language" | "value"
                            >
                        >;
                    }
            >;
        };
};

export type UpdateApplianceCategoryMutationVariables = {
    id: Scalars["Int"];
    input: ApplianceCategoryInput;
};

export type UpdateApplianceCategoryMutation = { __typename?: "Mutation" } & {
    updateApplianceCategory: { __typename?: "ApplianceCategory" } & Pick<
        ApplianceCategory,
        "id" | "name"
    >;
};

export type UpdateApplianceListMutationVariables = {
    id: Scalars["Int"];
    input: ApplianceListInput;
};

export type UpdateApplianceListMutation = { __typename?: "Mutation" } & {
    updateApplianceList: { __typename?: "ApplianceList" } & Pick<
        ApplianceList,
        "id" | "name" | "activeFrom" | "activeTo"
    >;
};

export type UpdateIrConfigurationMutationVariables = {
    id: Scalars["Int"];
    input?: Maybe<UpdateIrConfigurationInput>;
};

export type UpdateIrConfigurationMutation = { __typename?: "Mutation" } & {
    updateIrConfiguration: { __typename?: "IrConfiguration" } & Pick<
        IrConfiguration,
        "id" | "minProbabilityForApproval"
    >;
};

export type UpdateOperatorMutationVariables = {
    id: Scalars["Int"];
    input?: Maybe<UpdateOperatorInput>;
};

export type UpdateOperatorMutation = { __typename?: "Mutation" } & {
    updateOperator: { __typename?: "Operator" } & Pick<Operator, "id">;
};

export type UpdatePeriodApplianceMutationVariables = {
    id: Scalars["Int"];
    input: PeriodApplianceInput;
};

export type UpdatePeriodApplianceMutation = { __typename?: "Mutation" } & {
    updatePeriodAppliance: { __typename?: "PeriodAppliance" } & Pick<
        PeriodAppliance,
        "id"
    > & {
            appliance: { __typename?: "Appliance" } & Pick<Appliance, "id"> & {
                    name: Array<
                        { __typename?: "Translation" } & Pick<
                            Translation,
                            "language" | "value"
                        >
                    >;
                    description: Array<
                        { __typename?: "Translation" } & Pick<
                            Translation,
                            "language" | "value"
                        >
                    >;
                };
            applianceCategory: { __typename?: "ApplianceCategory" } & Pick<
                ApplianceCategory,
                "id" | "name"
            >;
        };
};

export type UpdateSamplingsMutationVariables = {
    ids: Array<Scalars["Int"]>;
    input?: Maybe<UpdateSamplingInput>;
};

export type UpdateSamplingsMutation = { __typename?: "Mutation" } & {
    updateSamplings: Array<{ __typename?: "Sampling" } & SamplingFragment>;
};

export type UpdateShipmentStatusMutationVariables = {
    id: Scalars["Int"];
    shipmentStatus: ShipmentStatus;
};

export type UpdateShipmentStatusMutation = { __typename?: "Mutation" } & {
    updateShipmentStatus: { __typename?: "Shipment" } & ShipmentFragment;
};

export type OperatorFragment = { __typename?: "Operator" } & Pick<
    Operator,
    | "id"
    | "firstName"
    | "lastName"
    | "site"
    | "language"
    | "stationName"
    | "stationLocation"
>;

export type GetApplianceQueryVariables = {
    id: Scalars["Int"];
};

export type GetApplianceQuery = { __typename?: "Query" } & {
    appliance: { __typename?: "Appliance" } & Pick<Appliance, "id"> & {
            name: Array<
                { __typename?: "Translation" } & Pick<
                    Translation,
                    "language" | "value"
                >
            >;
            description: Array<
                { __typename?: "Translation" } & Pick<
                    Translation,
                    "language" | "value"
                >
            >;
            previousAppliance: Maybe<
                { __typename?: "Appliance" } & Pick<Appliance, "id"> & {
                        name: Array<
                            { __typename?: "Translation" } & Pick<
                                Translation,
                                "language" | "value"
                            >
                        >;
                    }
            >;
        };
};

export type GetApplianceCategoriesQueryVariables = {
    paging?: Maybe<PagingInput>;
    search?: Maybe<Scalars["String"]>;
    filter?: Maybe<ApplianceCategoryFilterInput>;
};

export type GetApplianceCategoriesQuery = { __typename?: "Query" } & {
    applianceCategories: {
        __typename?: "ApplianceCategoriesQueryResult";
    } & Pick<ApplianceCategoriesQueryResult, "count"> & {
            items: Array<
                { __typename?: "ApplianceCategory" } & Pick<
                    ApplianceCategory,
                    "id" | "name"
                >
            >;
        };
};

export type GetApplianceListQueryVariables = {
    id: Scalars["Int"];
};

export type GetApplianceListQuery = { __typename?: "Query" } & {
    applianceList: { __typename?: "ApplianceList" } & Pick<
        ApplianceList,
        "id" | "name" | "activeFrom" | "activeTo"
    >;
};

export type GetApplianceListsQueryVariables = {
    sort?: Maybe<Array<SortInput>>;
    paging?: Maybe<PagingInput>;
    filter?: Maybe<AppliancesListsFilterInput>;
};

export type GetApplianceListsQuery = { __typename?: "Query" } & {
    applianceLists: { __typename?: "ApplianceListsQueryResult" } & Pick<
        ApplianceListsQueryResult,
        "count"
    > & {
            items: Array<
                { __typename?: "ApplianceList" } & Pick<
                    ApplianceList,
                    "id" | "name" | "activeFrom" | "activeTo"
                >
            >;
        };
};

export type GetAppliancesQueryVariables = {
    sort?: Maybe<Array<SortInput>>;
    paging?: Maybe<PagingInput>;
    filter?: Maybe<AppliancestFilterInput>;
    search?: Maybe<ApplianceSearchInput>;
};

export type GetAppliancesQuery = { __typename?: "Query" } & {
    appliances: { __typename?: "AppliancesQueryResult" } & Pick<
        AppliancesQueryResult,
        "count"
    > & {
            items: Array<
                { __typename?: "Appliance" } & Pick<Appliance, "id"> & {
                        name: Array<
                            { __typename?: "Translation" } & Pick<
                                Translation,
                                "language" | "value"
                            >
                        >;
                    }
            >;
        };
};

export type GetBrandsQueryVariables = {};

export type GetBrandsQuery = { __typename?: "Query" } & {
    brands: Array<{ __typename?: "Brand" } & Pick<Brand, "id" | "name">>;
};

export type GetIrConfigurationsQueryVariables = {};

export type GetIrConfigurationsQuery = { __typename?: "Query" } & {
    irConfigurations: { __typename?: "IrConfigurationsQueryResult" } & Pick<
        IrConfigurationsQueryResult,
        "count"
    > & {
            items: Array<
                { __typename?: "IrConfiguration" } & Pick<
                    IrConfiguration,
                    "id" | "minProbabilityForApproval"
                >
            >;
        };
};

export type GetOperatorsQueryVariables = {
    sort?: Maybe<Array<SortInput>>;
    paging?: Maybe<PagingInput>;
    filter?: Maybe<OperatorFilterInput>;
};

export type GetOperatorsQuery = { __typename?: "Query" } & {
    operators: { __typename?: "OperatorsQueryResult" } & Pick<
        OperatorsQueryResult,
        "count"
    > & { items: Array<{ __typename?: "Operator" } & OperatorFragment> };
};

export type GetPercentageApprovedQueryVariables = {};

export type GetPercentageApprovedQuery = { __typename?: "Query" } & Pick<
    Query,
    "percentageApproved"
>;

export type GetPercentageEnteredManuallyQueryVariables = {};

export type GetPercentageEnteredManuallyQuery = { __typename?: "Query" } & Pick<
    Query,
    "percentageEnteredManually"
>;

export type GetPeriodAppliancesQueryVariables = {
    sort?: Maybe<Array<SortInput>>;
    paging?: Maybe<PagingInput>;
    filter?: Maybe<PeriodAppliancestFilterInput>;
    search?: Maybe<PeriodApplianceSearchInput>;
};

export type GetPeriodAppliancesQuery = { __typename?: "Query" } & {
    periodAppliances: { __typename?: "PeriodAppliancesQueryResult" } & Pick<
        PeriodAppliancesQueryResult,
        "count"
    > & {
            items: Array<
                { __typename?: "PeriodAppliance" } & Pick<
                    PeriodAppliance,
                    "id"
                > & {
                        appliance: { __typename?: "Appliance" } & Pick<
                            Appliance,
                            "id"
                        > & {
                                name: Array<
                                    { __typename?: "Translation" } & Pick<
                                        Translation,
                                        "language" | "value"
                                    >
                                >;
                                description: Array<
                                    { __typename?: "Translation" } & Pick<
                                        Translation,
                                        "language" | "value"
                                    >
                                >;
                            };
                        applianceCategory: {
                            __typename?: "ApplianceCategory";
                        } & Pick<ApplianceCategory, "id" | "name">;
                    }
            >;
        };
};

export type GetProfileQueryVariables = {};

export type GetProfileQuery = { __typename?: "Query" } & {
    profile: { __typename?: "Profile" } & Pick<Profile, "groups">;
};

export type GetSampleGalleryInfosQueryVariables = {
    filter?: Maybe<SamplingsFilterInput>;
    sort?: Maybe<Array<SortInput>>;
};

export type GetSampleGalleryInfosQuery = { __typename?: "Query" } & {
    samplings: { __typename?: "SamplingsQueryResult" } & Pick<
        SamplingsQueryResult,
        "count"
    > & {
            items: Array<
                { __typename?: "Sampling" } & {
                    galleryInfo: { __typename?: "GalleryInfo" } & Pick<
                        GalleryInfo,
                        "nextId" | "previousId" | "currentPlace" | "totalItems"
                    >;
                }
            >;
        };
};

export type GetSamplesQueryVariables = {
    sort?: Maybe<Array<SortInput>>;
    paging?: Maybe<PagingInput>;
    filter?: Maybe<SamplingsFilterInput>;
};

export type GetSamplesQuery = { __typename?: "Query" } & {
    samplings: { __typename?: "SamplingsQueryResult" } & Pick<
        SamplingsQueryResult,
        "count"
    > & { items: Array<{ __typename?: "Sampling" } & SamplingFragment> };
};

export type GetSamplingListQueryVariables = {
    sort?: Maybe<Array<SortInput>>;
    paging?: Maybe<PagingInput>;
    filter?: Maybe<SamplingsFilterInput>;
};

export type GetSamplingListQuery = { __typename?: "Query" } & {
    samplings: { __typename?: "SamplingsQueryResult" } & Pick<
        SamplingsQueryResult,
        "count"
    > & {
            items: Array<
                { __typename?: "Sampling" } & Pick<
                    Sampling,
                    | "id"
                    | "timeSampled"
                    | "probability"
                    | "approved"
                    | "systemApproved"
                    | "manualEntry"
                    | "weight"
                    | "size"
                > & {
                        images: Array<
                            { __typename?: "Image" } & Pick<
                                Image,
                                "thumbnailUrl"
                            >
                        >;
                        transport: { __typename?: "Transport" } & Pick<
                            Transport,
                            "id" | "transportNumber"
                        >;
                        appliance: Maybe<
                            { __typename?: "Appliance" } & Pick<
                                Appliance,
                                "id"
                            > & {
                                    name: Array<
                                        { __typename?: "Translation" } & Pick<
                                            Translation,
                                            "language" | "value"
                                        >
                                    >;
                                }
                        >;
                        history: Array<

                                | ({ __typename?: "ApplianceHistory" } & Pick<
                                      ApplianceHistory,
                                      "type"
                                  > & {
                                          user: Maybe<
                                              { __typename?: "User" } & Pick<
                                                  User,
                                                  "id"
                                              >
                                          >;
                                      })
                                | ({ __typename?: "SizeHistory" } & Pick<
                                      SizeHistory,
                                      "type"
                                  > & {
                                          user: Maybe<
                                              { __typename?: "User" } & Pick<
                                                  User,
                                                  "id"
                                              >
                                          >;
                                      })
                                | ({ __typename?: "BrandHistory" } & Pick<
                                      BrandHistory,
                                      "type"
                                  > & {
                                          user: Maybe<
                                              { __typename?: "User" } & Pick<
                                                  User,
                                                  "id"
                                              >
                                          >;
                                      })
                                | ({ __typename?: "ApprovedHistory" } & Pick<
                                      ApprovedHistory,
                                      "type"
                                  > & {
                                          user: Maybe<
                                              { __typename?: "User" } & Pick<
                                                  User,
                                                  "id"
                                              >
                                          >;
                                      })
                        >;
                    }
            >;
        };
};

export type GetShipmentNumbersQueryVariables = {
    sort?: Maybe<Array<SortInput>>;
    paging?: Maybe<PagingInput>;
    filter?: Maybe<ShipmentFilterInput>;
};

export type GetShipmentNumbersQuery = { __typename?: "Query" } & {
    shipments: { __typename?: "ShipmentsQueryResult" } & {
        items: Array<
            { __typename?: "Shipment" } & Pick<
                Shipment,
                "id" | "shipmentNumber"
            >
        >;
    };
};

export type GetShipmentsQueryVariables = {
    sort?: Maybe<Array<SortInput>>;
    paging?: Maybe<PagingInput>;
    filter?: Maybe<ShipmentFilterInput>;
};

export type GetShipmentsQuery = { __typename?: "Query" } & {
    shipments: { __typename?: "ShipmentsQueryResult" } & Pick<
        ShipmentsQueryResult,
        "count"
    > & { items: Array<{ __typename?: "Shipment" } & ShipmentFragment> };
};

export type GetTransportsNumbersQueryVariables = {
    sort?: Maybe<Array<SortInput>>;
    paging?: Maybe<PagingInput>;
    filter?: Maybe<TransportFilterInput>;
};

export type GetTransportsNumbersQuery = { __typename?: "Query" } & {
    transports: { __typename?: "TransportsQueryResult" } & {
        items: Array<
            { __typename?: "Transport" } & Pick<
                Transport,
                "id" | "transportNumber"
            >
        >;
    };
};

export type GetTransportsQueryVariables = {
    sort?: Maybe<Array<SortInput>>;
    paging?: Maybe<PagingInput>;
    filter?: Maybe<TransportFilterInput>;
};

export type GetTransportsQuery = { __typename?: "Query" } & {
    transports: { __typename?: "TransportsQueryResult" } & Pick<
        TransportsQueryResult,
        "count"
    > & { items: Array<{ __typename?: "Transport" } & TransportFragment> };
};

export type SamplingFragment = { __typename?: "Sampling" } & Pick<
    Sampling,
    | "id"
    | "timeSampled"
    | "manualEntry"
    | "scaleType"
    | "probability"
    | "approved"
    | "systemApproved"
    | "size"
    | "weight"
> & {
        brand: Maybe<{ __typename?: "Brand" } & Pick<Brand, "id" | "name">>;
        appliance: Maybe<{ __typename?: "Appliance" } & ApplianceFragment>;
        applianceSuggestions: Array<
            { __typename?: "ApplianceSuggestion" } & Pick<
                ApplianceSuggestion,
                "probability"
            > & { appliance: { __typename?: "Appliance" } & ApplianceFragment }
        >;
        operator: { __typename?: "Operator" } & OperatorFragment;
        transport: { __typename?: "Transport" } & TransportBasicInfoFragment;
        shipment: { __typename?: "Shipment" } & Pick<
            Shipment,
            "id" | "shipmentNumber" | "type" | "status"
        >;
        images: Array<{ __typename?: "Image" } & ImageFragment>;
        history: Array<

                | ({ __typename?: "ApplianceHistory" } & Pick<
                      ApplianceHistory,
                      "timestamp" | "type"
                  > & {
                          user: Maybe<
                              { __typename?: "User" } & Pick<
                                  User,
                                  "id" | "name"
                              >
                          >;
                          newAppliance: { __typename?: "Appliance" } & Pick<
                              Appliance,
                              "id"
                          > & {
                                  name: Array<
                                      { __typename?: "Translation" } & Pick<
                                          Translation,
                                          "language" | "value"
                                      >
                                  >;
                              };
                      })
                | ({ __typename?: "SizeHistory" } & Pick<
                      SizeHistory,
                      "timestamp" | "type" | "newSize"
                  > & {
                          user: Maybe<
                              { __typename?: "User" } & Pick<
                                  User,
                                  "id" | "name"
                              >
                          >;
                      })
                | ({ __typename?: "BrandHistory" } & Pick<
                      BrandHistory,
                      "timestamp" | "type"
                  > & {
                          user: Maybe<
                              { __typename?: "User" } & Pick<
                                  User,
                                  "id" | "name"
                              >
                          >;
                          newBrand: { __typename?: "Brand" } & Pick<
                              Brand,
                              "id" | "name"
                          >;
                      })
                | ({ __typename?: "ApprovedHistory" } & Pick<
                      ApprovedHistory,
                      "timestamp" | "type" | "newApproved"
                  > & {
                          user: Maybe<
                              { __typename?: "User" } & Pick<
                                  User,
                                  "id" | "name"
                              >
                          >;
                      })
        >;
    };

export type ShipmentFragment = { __typename?: "Shipment" } & Pick<
    Shipment,
    "id" | "shipmentNumber" | "type" | "status"
> & { samplings: Array<{ __typename?: "Sampling" } & Pick<Sampling, "id">> };

export type TransportFragment = { __typename?: "Transport" } & Pick<
    Transport,
    | "id"
    | "transportNumber"
    | "processingFraction"
    | "status"
    | "estimatedArrivalDate"
    | "shipmentType"
    | "percentageEnteredManually"
    | "percentageApproved"
    | "site"
>;

export type TransportBasicInfoFragment = { __typename?: "Transport" } & Pick<
    Transport,
    "id" | "transportNumber"
>;
