import { ApolloError } from "apollo-client/errors/ApolloError";
import { InjectedIntl, FormattedMessage, MessageValue } from "react-intl";

type FormatMessageFn = (messageDescriptor: FormattedMessage.MessageDescriptor, values?: { [key: string]: MessageValue }) => string;

export const parseError = (error: ApolloError, intl: InjectedIntl | FormatMessageFn): string => {
    const match = error.message.match(/error\..*$/);

    let formatMessage = intl as FormatMessageFn;

    if ((intl as InjectedIntl).formatMessage) {
        formatMessage = (intl as InjectedIntl).formatMessage;
    }

    if (match && match.length) {
        return formatMessage({ id: match[0].toLowerCase() });
    }

    return formatMessage({ id: "error.unknown" });
};
