import { autobind } from "core-decorators";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { OperatorHeader, OperatorsStyle, OperatorTitle } from "@pages/operators/operatorsStyle";
import { OperatorsProps } from "@pages/operators/operatorsContainer";
import { Operator } from "@models/graphql";
import { OperatorsTableContainer } from "@components/operators/operatorsTable/operatorsTableContainer";
import { OperatorFormContainer } from "@components/operators/operatorForm/operatorFormContainer";

export interface OperatorsState {
    mode: string;
    operator: Operator | undefined;
}

@autobind
export class Operators extends React.Component<OperatorsProps, OperatorsState> {
    public readonly state: OperatorsState = {
        mode: "create",
        operator: undefined
    };

    public render() {
        const { match } = this.props;

        return (
            <OperatorsStyle>
                <OperatorHeader>
                    <OperatorTitle><FormattedMessage id="operators.management" /></OperatorTitle>
                    <OperatorFormContainer
                        operator={this.state.operator}
                        mode={this.state.mode}
                        changeMode={this.changeMode}
                    />
                </OperatorHeader>
                <OperatorsTableContainer
                    currentPage={parseInt(match.params.page, 10)}
                    changeMode={this.changeMode}
                />
            </OperatorsStyle>

        );
    }

    private changeMode(mode: string, operator?: Operator) {

        this.setState({ mode, operator });
    }
}
