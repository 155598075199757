import { useContext, useMemo, useCallback } from "react";
import { __RouterContext as RouterContext, RouteComponentProps, matchPath } from "react-router";
import { Location } from "history";
import * as queryString from "query-string";

type ParsedQuery = queryString.ParsedQuery;

export const useRouter = <T>(): RouteComponentProps<T> => useContext(RouterContext) as RouteComponentProps<T>;

export const useLocation = (): Location => {
    const { location } = useRouter();

    return location;
};

export const useParams = <T>(): T => {
    const { match } = useRouter<T>();

    return match.params;
};

export const useQuery = <T extends ParsedQuery>(): T => {
    const { search } = useLocation();
    const query = useMemo(
        () => queryString.parse(search),
        [search]
    );

    return query as T;
};

interface UpdateQueryOptions {
    replace: boolean;
}

type UpdateQuery<T> = (patch: Partial<T>) => void;

type Visit = (href: string) => void;

const USE_PUSH = { replace: false };

export const useUpdateQuery = <T extends ParsedQuery>(options: UpdateQueryOptions = USE_PUSH): UpdateQuery<T> => {
    const { history } = useRouter();
    const query = useQuery<T>();
    const { replace } = options;

    return useCallback(
        (patch: Partial<T>): void => {
            const newQuery = { ...query, ...patch };
            const newSearch = queryString.stringify(newQuery);
            if (replace) {
                history.replace({ search: newSearch });
            } else {
                history.push({ search: newSearch });
            }
        },
        [history, query, replace]
    );
};

const isParamPlaceholder = (part: string): boolean => part.startsWith(":");


export const routeToUrl = (route: string, partialUrl: string): string => {
    let urlParts: string[] = [];

    route.split("/").forEach((part, index) => {
        if (isParamPlaceholder(part)) {
            urlParts.push(partialUrl.split("/")[index]);
        } else {
            urlParts.push(part);
        }
    });

    return urlParts.join("/");
}

export const isMatchingRoute = (route: string, url: string): boolean => {
    return !!matchPath(url, { path: route, exact: true });
}

export const getHrefWithFilters = (href: string): string => {
    const persistedRoute = Object.keys(localStorage).find((key) => isMatchingRoute(key, href));

    if (persistedRoute) {
        return `${href}${localStorage.getItem(persistedRoute)}`;
    } else {
        return href;
    }
}

export const useNavigate = (options: UpdateQueryOptions = USE_PUSH): Visit => {
    const { history } = useRouter();
    const { replace } = options;
    
    return useCallback((href: string): void => {
        const newHref = getHrefWithFilters(href);

        if (replace) {
            history.replace(newHref)
        } else {
            history.push(newHref)
        }
    }, [history, replace]);
}

export const useRoute = <SearchParams extends {}>(): string => {
	const location = useLocation();
	const params = useParams<SearchParams>();

	let route = location.pathname;

	Object.keys(params).forEach((key) => {
		route = route.replace(params[key], `:${key}`);
	});

	return route;
}
