import { ThumbnailLightboxProps } from "@components/thumbnailLightbox/thumbnailLightboxContainer";
import { ThumbnailLightboxStyle } from "@components/thumbnailLightbox/thumbnailLightboxStyle";
import { CAMERA_ORDER } from "@utils/cameraOrder";
import { autobind } from "core-decorators";
import * as React from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export interface ThumbnailLightboxState {
    open: boolean;
    selectedImage: number;
}

@autobind
export class ThumbnailLightbox extends React.Component<ThumbnailLightboxProps, ThumbnailLightboxState> {
    public state: ThumbnailLightboxState = {
        open: false,
        selectedImage: 0
    };

    public render() {

        const { images } = this.props;
        const { selectedImage, open } = this.state;

        return (
            <ThumbnailLightboxStyle>
                {open && <Lightbox
                    mainSrc={images[selectedImage].url}
                    nextSrc={images[(selectedImage + 1) % images.length].url}
                    prevSrc={images[(selectedImage + images.length - 1) % images.length].url}
                    onCloseRequest={this.closeLightbox}
                    onMovePrevRequest={() =>
                        this.setState({
                            selectedImage: (selectedImage + images.length - 1) % images.length
                        })
                    }
                    onMoveNextRequest={() =>
                        this.setState({
                            selectedImage: (selectedImage + 1) % images.length
                        })
                    }

                />}

                {images.sort((a, b) => CAMERA_ORDER[a.cameraName] - CAMERA_ORDER[b.cameraName]).map((image, index) =>
                    <div key={index} className="media">
                        <img onClick={() => this.openLightbox(index)} src={image.thumbnailUrl} alt="" role="presentation" />
                    </div>
                )}

            </ThumbnailLightboxStyle>

        );
    }

    private openLightbox(imageIndex: number) {
        this.setState({ open: true, selectedImage: imageIndex });
        if (this.props.openCloseCallback) {
            this.props.openCloseCallback(true);
        }
    }

    private closeLightbox() {
        this.setState({ open: false });
        if (this.props.openCloseCallback) {
            this.props.openCloseCallback(false);
        }
    }
}
