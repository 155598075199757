import { theme } from "@style/theme";
import { Icon } from "antd";
import styled from "styled-components";

export const SamplingsStyle = styled.div`
    margin-top: 45px;
    display: flex;

    aside {
        width: 500px;
    }

    main {
        flex: 1;
    }
`;

export const ViewModeStyle = styled.div`
    align-self: flex-end;
    margin-right: 128px;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ViewIcon = styled(Icon)`
    color: ${(props: { active: string }) => props.active === "true" ? theme.secondary : theme.text};
    font-size: 20px;
    margin: 0 20px;;

    &:last-of-type{
        margin-right: 0;
    }

    :hover{
        cursor: pointer;
    }
`;
