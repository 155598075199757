import { GraphQL } from "@graphql/graphQL";
import { UpdateShipmentStatusMutation, UpdateShipmentStatusMutationVariables } from "@models/graphql";
import { useState } from "react";
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";

const defaultUpdateShipmentStatusOptions: MutationHookOptions<UpdateShipmentStatusMutation, UpdateShipmentStatusMutationVariables> = {

};

export const useUpdateShipmentStatus = (options?: MutationHookOptions<UpdateShipmentStatusMutation, UpdateShipmentStatusMutationVariables>) => {

    const [hookState, setHookState] = useState<{
        isLoading: boolean;
        data?: UpdateShipmentStatusMutation;
        error?: string;
    }>({
        isLoading: false
    });

    const [mutation] = useMutation<UpdateShipmentStatusMutation, UpdateShipmentStatusMutationVariables>(GraphQL.updateShipmentStatus);

    const updateShipmentStatus = async (variables: UpdateShipmentStatusMutationVariables) => {
        setHookState({
            isLoading: true
        });

        try {
            const response = await mutation({
                ...defaultUpdateShipmentStatusOptions,
                ...options,
                variables
            });

            setHookState({
                data: response.data,
                isLoading: false
            });

            return response;
        } catch (error) {

            setHookState({
                error,
                isLoading: false
            });
        }
    };

    return {
        isLoading: hookState.isLoading,
        error: hookState.error,
        data: hookState.data,
        updateShipmentStatus
    };
};
