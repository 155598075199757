import { useApolloClient } from "@apollo/react-hooks";

import { Button, Col, Icon, Popconfirm, Tooltip } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { ShipmentStatus, SortOrder } from "@models/graphql";
import { useUpdateShipmentStatus } from "@graphql/hocs/hooks/useUpdateShipmentStatus";
import {
    TransportTableSubRow,
    TransportTableSubRowComplete,
    UnlockIcon
} from "@components/transports/transportTable/tableRow/tableRowStyle";

import { TableRowProps } from "./tableRowContainer";
import {
    usePermissions,
    Role
} from "@components/permission/permissionProvider";
import { useSamplingsTableFilter } from "@components/samplings/samplingsTable/useSamplingsTableFilter";
import { useNavigate } from "@utils/routerHook";

export interface TableRowState {}

export const TableRow: React.FunctionComponent<TableRowProps> = (
    props: TableRowProps
) => {
    const { hasRoles } = usePermissions();
    const filter = useSamplingsTableFilter("/samplings");
    const navigate = useNavigate();

    const client = useApolloClient();
    const {
        data,
        error,
        isLoading,
        updateShipmentStatus
    } = useUpdateShipmentStatus({
        async update(cache) {
            await client.reFetchObservableQueries();
        }
    });
    const { record, history, intl } = props;

    const SubRow =
        record.status === ShipmentStatus.Done
            ? TransportTableSubRowComplete
            : TransportTableSubRow;

    const getStatus = (status: ShipmentStatus) => {
        switch (status) {
            case ShipmentStatus.Done:
                return intl.formatMessage({ id: "transports.status.done" });
            case ShipmentStatus.Processing:
                return intl.formatMessage({
                    id: "transports.status.processing"
                });
            case ShipmentStatus.Open:
                return intl.formatMessage({ id: "transports.status.open" });
            default:
                return "";
        }
    };

    const updateStatus = async () => {
        const newStatus =
            record.status === ShipmentStatus.Done
                ? ShipmentStatus.Open
                : ShipmentStatus.Done;
        await updateShipmentStatus({
            id: record.id,
            shipmentStatus: newStatus
        });
    };

    const handleViewSamplings = () => {
        filter.set("sortField", "timeSampled");
        filter.set("sortOrder", SortOrder.Desc);
        filter.set("assignedBy", "both");
        filter.set("shipmentId", record.id);
        filter.set("transportId", props.transportId);
        filter.delete("applianceCategoryIds");
        filter.delete("applianceIds");
        filter.delete("maximumMatchingPercentage");
        filter.delete("minimumMatchingPercentage");
        filter.delete("page");
        filter.delete("pageSize");

        navigate(`/samplings/${record.samplings[0].id}`);
    };

    return (
        <SubRow hasSamplings={!!record.samplings.length}>
            <Col span={6}>{record.shipmentNumber}</Col>
            <Col span={14}>
                {!record.samplings.length ? (
                    <Tooltip
                        title={intl.formatMessage({
                            id: "transport.table.noSamplings"
                        })}
                    >
                        <Button
                            icon="eye"
                            disabled={true}
                            style={{ marginRight: 15 }}
                        >
                            <FormattedMessage id="shipment.viewSamplings" />
                        </Button>
                    </Tooltip>
                ) : (
                    <Button
                        onClick={handleViewSamplings}
                        icon="eye"
                        style={{ marginRight: 15 }}
                    >
                        <FormattedMessage id="shipment.viewSamplings" />
                    </Button>
                )}
            </Col>

            <Col span={4} style={{ display: "flex" }}>
                <span
                    className="status"
                    style={{ display: "inline-block", width: 100 }}
                >
                    {getStatus(record.status)}
                </span>
                {hasRoles([Role.ADMIN]) && (
                    <Popconfirm
                        onConfirm={updateStatus}
                        placement="left"
                        title={intl.formatMessage({
                            id:
                                record.status === ShipmentStatus.Done
                                    ? "shipments.unlock.confirm"
                                    : "shipments.lock.confirm"
                        })}
                    >
                        <Button loading={isLoading} style={{ marginLeft: 15 }}>
                            {record.status !== ShipmentStatus.Done ? (
                                <UnlockIcon type={"unlock"} />
                            ) : (
                                <Icon type={"lock"} />
                            )}
                        </Button>
                    </Popconfirm>
                )}
            </Col>
        </SubRow>
    );
};
