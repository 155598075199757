import { TransportStatus } from "@models/graphql";
import styled from "styled-components";

export const TransportTableStyle = styled.div`
    margin-top: 48px;

    .transportStatus {
        &.${TransportStatus.Done} {
            color: #50e2a5;
        }
        &.${TransportStatus.Processing} {
            color: #36c8eb;
        }
        &.${TransportStatus.Open} {
            color: #767676;
        }
    }
`;
