import * as React from "react";

import { SamplingDetailContainer } from "@pages/samplingDetail/samplingDetailContainer";
import { RouteComponentProps } from "react-router";
import { useSamplingsTableFilter } from "@components/samplings/samplingsTable/useSamplingsTableFilter";

export const SamplingDetailWrapper: React.FunctionComponent<RouteComponentProps<{ sampleId: string }>> = (props) => {
    const filter = useSamplingsTableFilter();

    return <SamplingDetailContainer
        filter={filter}
        sampleId={parseInt(props.match.params.sampleId, 10)}
    />;
};
