import { FunctionComponent } from "react";
import * as React from "react";
import Table, {
    ColumnProps,
    PaginationConfig,
    SorterResult,
    TableCurrentDataSource
} from "antd/lib/table";
import { SamplingFragment, SortOrder } from "@models/graphql";
import { useFormatMessage } from "@utils/intlHook";
import { FormattedMessage, FormattedNumber, InjectedIntl } from "react-intl";
import {
    Action,
    DeleteIcon,
    ManualStyle,
    SampleImage,
    SamplingsTableStyle,
    Transport as TransportStyle
} from "@components/samplings/samplingsTable/samplingsTableStyle";
import * as moment from "moment";
import { useNavigate, useRouter } from "@utils/routerHook";
import { SamplingsTableProps } from "./samplingsTableContainer";
import { SamplingApplianceSelect } from "./samplingApplianceSelect/samplingApplianceSelect";
import { SamplingSizeSelect } from "./samplingSizeSelect/samplingSizeSelect";
import { Probability } from "@pages/samplingDetail/probability/probability";
import { Alert, Button, Icon, message, Popconfirm } from "antd";
import { Permission } from "@components/permission/permission";
import { parseError } from "@utils/parseError";
import { useGetSamplingList } from "@graphql/hocs/hooks/useGetSamplingList";
import { useDeleteSamplings } from "@graphql/hocs/hooks/useDeleteSampling";
import { isApplianceHistory } from "@utils/samplingHistory";
import {
    Role,
    usePermissions
} from "@components/permission/permissionProvider";
import { User } from "oidc-client";
import { isSizeHistory } from "@utils/samplingHistory";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { calculatedStateFilter } from "./useSamplingsTableFilter";

interface TransportColumnProps {
    sampling: SamplingFragment;
    setPreviousPath: SamplingsTableProps["setPreviousPath"];
}

const TransportColumn: FunctionComponent<TransportColumnProps> = ({
    sampling,
    setPreviousPath
}) => {
    const navigate = useNavigate();
    const thumbnail = sampling.images[0]
        ? sampling.images[0].thumbnailUrl
        : undefined;

    const onClick = () => {
        setPreviousPath({
            path: window.location.pathname + window.location.search
        });
        navigate(`/samplings/${sampling.id}`);
    };

    return (
        <TransportStyle>
            <SampleImage onClick={onClick} src={thumbnail} />
            {sampling.transport.transportNumber}
        </TransportStyle>
    );
};

interface ProbabilityColumnProps {
    sampling: SamplingFragment;
}

const ProbabilityColumn: FunctionComponent<ProbabilityColumnProps> = ({
    sampling
}) =>
    sampling.manualEntry ? (
        <ManualStyle>
            <FormattedMessage id="sampling.manual" />
        </ManualStyle>
    ) : (
        <Probability value={sampling.probability} />
    );

interface ActionsColumnProps {
    sampling: SamplingFragment;
}

const ActionsColumn: FunctionComponent<ActionsColumnProps> = ({ sampling }) => {
    const navigate = useNavigate();
    const formatMessage = useFormatMessage();
    const { deleteSamplings } = useDeleteSamplings({
        refetchQueries: ["getSamples", "getSmaplingList"]
    });

    const deleteSample = async (id: number) => {
        try {
            await deleteSamplings({ ids: [id] });
            message.info(formatMessage({ id: "deleteSampling.succes" }));
        } catch (err) {
            console.log(err);
            message.error(parseError(err, formatMessage));
        }
    };

    const onView = () => {
        navigate(`/samplings/${sampling.id}`);
    };

    const onDelete = () => {
        deleteSample(sampling.id);
    };

    return (
        <Action>
            <Button onClick={onView} style={{ marginRight: 10 }}>
                <Icon type="eye" />
            </Button>
            <Permission>
                <Popconfirm
                    onConfirm={onDelete}
                    title={formatMessage({ id: "deleteSampling.confirm" })}
                >
                    <Button>
                        <DeleteIcon type="delete" />
                    </Button>
                </Popconfirm>
            </Permission>
        </Action>
    );
};

const statusString = (
    manualEntry: boolean,
    approved: boolean,
    systemApproved: boolean
): string => {
    if (approved && systemApproved) {
        return "samplings.table.automaticApproved";
    }
    if (manualEntry && approved) {
        return "samplings.table.corrected";
    }
    if (approved) {
        return "samplings.table.approved";
    }
    return "samplings.table.notApproved";
};

const getColumns = (
    filter: SamplingsTableProps["filter"],
    formatMessage: InjectedIntl["formatMessage"],
    setPreviousPath: SamplingsTableProps["setPreviousPath"]
): ColumnProps<SamplingFragment>[] => [
    {
        title: formatMessage({ id: "samplings.table.transport" }),
        dataIndex: "transport",
        render: (_, record) => (
            <TransportColumn
                sampling={record}
                setPreviousPath={setPreviousPath}
            />
        ),
        width: 150
    },
    {
        title: formatMessage({ id: "samplings.table.weight" }),
        dataIndex: "weight",
        width: 110,
        sorter: true,
        render: (_, record) => (
            <span>
                <FormattedNumber value={record.weight} /> kg
            </span>
        )
    },
    {
        title: formatMessage({ id: "samplings.table.device" }),
        dataIndex: "device",
        render: (_, record) => <SamplingApplianceSelect sampling={record} />
    },
    {
        title: formatMessage({ id: "transport.details.size" }),
        dataIndex: "size",
        render: (_, record) => <SamplingSizeSelect sampling={record} />
    },
    {
        title: formatMessage({ id: "samplings.table.match" }),
        dataIndex: "probability",
        render: (_, record) => <ProbabilityColumn sampling={record} />,
        width: 200
    },
    {
        title: formatMessage({ id: "samplings.table.state" }),
        dataIndex: "approved",
        render: (_, record) => (
            <FormattedMessage
                id={statusString(
                    record.manualEntry,
                    record.approved,
                    record.systemApproved
                )}
            />
        )
    },
    {
        title: formatMessage({ id: "samplings.table.date" }),
        render: (_, record) =>
            moment(record.timeSampled).format("DD/MM/YY HH:mm"),
        dataIndex: "timeSampled",
        sorter: true,
        defaultSortOrder:
            filter.get("sortField") === "timeSampled"
                ? filter.get("sortOrder") === SortOrder.Asc
                    ? "ascend"
                    : "descend"
                : undefined
    },
    {
        title: formatMessage({ id: "samplings.table.action" }),
        render: (_, record) => <ActionsColumn sampling={record} />,
        width: 120
    }
];

export const SamplingsTable: FunctionComponent<SamplingsTableProps> = ({
    filter,
    setPreviousPath,
    selectedSamplingIds,
    setSelectedSamplingIds
}) => {
    const formatMessage = useFormatMessage();
    const { hasRoles } = usePermissions();
    const { oidcUser }: { oidcUser: User } = useReactOidc();

    const minimumMatchingPercentage = filter.get("minimumMatchingPercentage");
    const maximumMatchingPercentage = filter.get("maximumMatchingPercentage");

    const {
        samplings,
        samplingsCount,
        samplingsError,
        samplingsLoading
    } = useGetSamplingList({
        variables: {
            paging: {
                limit: filter.get("pageSize"),
                offset: (filter.get("page") - 1) * filter.get("pageSize")
            },
            filter: {
                fromTimeSampled: filter.get("fromTimeSampled").toISOString(),
                toTimeSampled:
                    filter.get("toTimeSampled") !== undefined
                        ? moment(filter.get("toTimeSampled")).toISOString()
                        : null,
                applianceIds: filter.get("applianceIds"),
                shipmentIds: filter.get("shipmentId")
                    ? [filter.get("shipmentId") as number]
                    : null,
                transportIds: filter.get("transportId")
                    ? [filter.get("transportId") as number]
                    : null,
                manualEntry:
                    filter.get("assignedBy") === "system"
                        ? false
                        : filter.get("assignedBy") === "both"
                        ? null
                        : true,
                minimumMatchingPercentage: minimumMatchingPercentage
                    ? minimumMatchingPercentage / 100
                    : null,
                maximumMatchingPercentage: maximumMatchingPercentage
                    ? maximumMatchingPercentage / 100
                    : null,
                applianceCategoryIds:
                    filter.get("applianceCategoryIds") || null,
                minimumWeight: filter.get("minimumWeight")
                    ? filter.get("minimumWeight")
                    : null,
                maximumWeight: filter.get("maximumWeight")
                    ? filter.get("maximumWeight")
                    : null,
                sizes: filter.get("size") ? [filter.get("size") as any] : null,
                brandIds: filter.get("brandIds") || null,
                ...calculatedStateFilter(filter.get("approved"))
            },
            sort: [
                {
                    field: filter.get("sortField"),
                    order: filter.get("sortOrder")
                }
            ]
        },
        context: {
            debounceKey: "samplings"
        }
    });

    const toRowKey = (selectedId: number): string => {
        const sampling = samplings.find(({ id }) => id === selectedId);

        return `${selectedId}-${sampling && sampling.size}-${sampling &&
            sampling.appliance &&
            sampling.appliance.id}`;
    };

    const isCurrentUser = (
        user: SamplingFragment["history"][number]["user"]
    ): boolean => user !== null && user.id !== oidcUser.profile.oid;

    const isSizeEditedByOtherUser = (
        historyObject: SamplingFragment["history"][number]
    ): boolean =>
        isSizeHistory(historyObject) && !isCurrentUser(historyObject.user);

    const isApplianceEditedByOtherUser = (
        historyObject: SamplingFragment["history"][number]
    ): boolean =>
        isApplianceHistory(historyObject) && !isCurrentUser(historyObject.user);

    const getCheckboxProps = (sampling: SamplingFragment) => {
        const isAdmin = hasRoles([Role.ADMIN]);
        const isManualEntry = sampling.manualEntry;
        const isApplianceEditedByOtherUserBefore = !sampling.history.some(
            isApplianceEditedByOtherUser
        );
        const isSizeEditedByOtherUserBefore = !sampling.history.some(
            isSizeEditedByOtherUser
        );
        const isEditable =
            isAdmin ||
            !isManualEntry ||
            !(
                isSizeEditedByOtherUserBefore &&
                isApplianceEditedByOtherUserBefore
            );

        return { disabled: !isEditable };
    };

    const onChange = (
        pagination: PaginationConfig,
        filters: Record<keyof SamplingFragment, string[]>,
        sorter: SorterResult<SamplingFragment>,
        extra: TableCurrentDataSource<SamplingFragment>
    ) => {
        if (sorter) {
            if (!sorter.columnKey) {
                filter.delete("sortOrder");
                filter.delete("sortField");
            } else {
                filter.set(
                    "sortOrder",
                    sorter.order === "ascend" ? SortOrder.Asc : SortOrder.Desc
                );
                filter.set("sortField", sorter.columnKey);
            }
        }

        if (pagination.current) filter.set("page", pagination.current);
        if (pagination.pageSize) filter.set("pageSize", pagination.pageSize);
    };

    const onRowSelect = (keys: string[]) => {
        setSelectedSamplingIds({
            ids: keys.map(rowKey => parseInt(rowKey.split("-")[0]), 10)
        });
    };

    return (
        <SamplingsTableStyle>
            {samplingsError && (
                <Alert
                    style={{ marginTop: 15, marginBottom: 15 }}
                    type="error"
                    icon="cross"
                    message="Er ging iets mis tijdens het ophalen van de data"
                />
            )}
            <Table
                loading={samplingsLoading}
                rowKey={({ id, size, appliance }) =>
                    `${id}-${size}-${appliance && appliance.id}`
                }
                columns={getColumns(filter, formatMessage, setPreviousPath)}
                dataSource={[...samplings]}
                onChange={onChange}
                rowSelection={{
                    selectedRowKeys: selectedSamplingIds.map(toRowKey),
                    onChange: onRowSelect,
                    getCheckboxProps
                }}
                pagination={{
                    pageSize: filter.get("pageSize"),
                    pageSizeOptions: ["20", "50", "100"],
                    showSizeChanger: true,
                    showQuickJumper: true,
                    position: "both",
                    hideOnSinglePage: false,
                    total: samplingsCount,
                    current: filter.get("page")
                }}
            />
        </SamplingsTableStyle>
    );
};
