import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

import { GetProfileQuery, GetProfileQueryVariables } from "@models/graphql";
import { GraphQL } from "@graphql/graphQL";

export const useGetProfile = (opts?: QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) => {
    const { data, loading, error } = useQuery<GetProfileQuery, GetProfileQueryVariables>(GraphQL.getProfile, opts);

    return {
        profile: data ? data.profile : null,
        profileLoading: loading,
        profileError: error
    };
};
