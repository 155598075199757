import { useState } from "react";
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import {
    DeleteApplianceCategoryMutationVariables,
    DeleteIrConfigurationMutation,
    DeleteIrConfigurationMutationVariables
} from "@models/graphql";
import { ApolloError } from "apollo-client";
import { GraphQL } from "@graphql/graphQL";

export const useDeleteIrConfiguration = (
    options?: MutationHookOptions<
        DeleteIrConfigurationMutation,
        DeleteApplianceCategoryMutationVariables
    >
) => {
    const [hookState, setHookState] = useState<{
        isLoading: boolean;
        data?: DeleteIrConfigurationMutation;
        error?: ApolloError;
    }>({ isLoading: false });

    const [mutation] = useMutation<
        DeleteIrConfigurationMutation,
        DeleteIrConfigurationMutationVariables
    >(GraphQL.deleteIrConfiguration);

    const deleteIrConfiguration = async (
        variables: DeleteIrConfigurationMutationVariables
    ) => {
        setHookState({ isLoading: true });

        try {
            const response = await mutation({
                ...options,
                variables
            });
            setHookState({ data: response.data, isLoading: false });
            return { data: response.data, error: response.errors };
        } catch (error) {
            setHookState({ error, isLoading: false });
            return { error };
        }
    };

    return {
        isLoading: hookState.isLoading,
        error: hookState.error,
        data: hookState.data,
        deleteIrConfiguration
    };
};
