import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

import { GetSamplingListQuery, GetSamplingListQueryVariables } from "@models/graphql";
import { GraphQL } from "@graphql/graphQL";

export const useGetSamplingList = (opts?: QueryHookOptions<GetSamplingListQuery, GetSamplingListQueryVariables>) => {
    const { data, loading, error, fetchMore } = useQuery<GetSamplingListQuery, GetSamplingListQueryVariables>(GraphQL.getSamplingList, opts);

    const fetch = (offset: number, limit: number) => fetchMore({
        variables: {
            paging: {
                limit,
                offset
            }
        },
        updateQuery: (prev: GetSamplingListQuery, { fetchMoreResult }): GetSamplingListQuery => {
            if (!fetchMoreResult) { return prev; }

            return {
                samplings: {
                    ...prev.samplings,
                    count: fetchMoreResult.samplings.count,
                    items: [...prev.samplings.items, ...fetchMoreResult.samplings.items]
                }
            };
        }
    });

    return {
        samplings: data && data.samplings ? data.samplings.items : [],
        samplingsCount: data && data.samplings.count,
        samplingsLoading: loading,
        samplingsError: error,
        fetchMoreSamplings: fetch
    };
};
